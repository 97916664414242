import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import Logo from "../../Assests/logo.png";
import SidebarComponent from "./Sidebar";
import { Link } from "react-router-dom";
import "./Base.css";
const Base = ({ routes, children }) => {
  let history = useHistory();
  const [sidebar, setSidebar] = useState(false);

  const currentTab = (history, path) => {
    let currentPath = "/" + history.location.pathname.split("/")[1];

    if (currentPath === path) {
      return "active_link";
    } else {
      return "";
    }
  };
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      {/* <div className="main-content container-fluid"> */}
      {/* <div className="row"> */}
      {/* <div className="col-sm-10" style={{ marginTop: "2rem" }}> */}
      <div className="base">{children}</div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Base;

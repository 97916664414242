import React, { useContext, useState } from "react";
import BackNavigation from "../Common/Components/BackNavigation";
import Base from "../Common/Components/Base";
import TkButton from "../Common/Components/TkButton";
import "../styles/setting.css";
import { validatePassword } from "../Utils/Helper";
import SuccessModal from "../Common/Components/StatusModal";
import { base_route } from "../backend";
const ChangePassword = () => {
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    error: false,
    confirmPassword: "",
    changeSuccess: false,
  });
  const [successModal, setSuccessModal] = useState({
    successModal: false,
    status: "",
    successText: "",
  });

  const { oldPassword, newPassword, error, confirmPassword, changeSuccess } =
    values;

  const handleOnChange = (field) => (event) => {
    setValues({ ...values, [field]: event.target.value });
  };

  const { token } = JSON.parse(sessionStorage.getItem("tk_jwt")).token;

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!validatePassword(newPassword)) {
      setSuccessModal({
        ...successModal,
        successModal: true,
        status: "error",
        successText:
          "Password should have minimum eight characters, at least one letter and one number",
      });
    } else {
      return await fetch(`${base_route}/admin/settings/changePassword`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "bearer-token": `${token}`,
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result.message) {
            console.log(result.message);
            setSuccessModal({
              ...successModal,
              successModal: true,
              status: "success",
              successText: result.message,
            });
          } else if (result.error) {
            setValues({ ...values, error: result.error });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Base>
      <div className="ml-3 mt-3">
        <div className="w_85 mx-auto">
          <BackNavigation title={"Change Password"} color={"#000"} />
          <div className="ml-5">
            <div className="password_change ">
              <form className="change_password_form">
                <div className="form-group">
                  <label>Current Password</label>
                  <input
                    type="text"
                    placeholder="current password"
                    type="password"
                    onChange={handleOnChange("oldPassword")}
                  />
                </div>
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    type="text"
                    placeholder="new password"
                    type="password"
                    onChange={handleOnChange("newPassword")}
                  />
                </div>
                <div className="form-group">
                  <label>Confirm New Password</label>
                  <input
                    type="text"
                    placeholder="confirm new password"
                    type="password"
                    onChange={handleOnChange("newPassword")}
                  />
                </div>
                <h5 className="text-center text-danger error">{error}</h5>

                <TkButton
                  name="Save"
                  type="submit"
                  _class="mt-5"
                  style={{ backgroundColor: "#00B23B" }}
                  _onBtnClick={(e) => onSubmit(e)}
                />
              </form>
            </div>
            <SuccessModal
              showModal={successModal.successModal}
              text={successModal.successText}
              status={successModal.status}
              closeModal={() =>
                setSuccessModal({ ...successModal, successModal: false })
              }
            />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Base from "../../Common/Components/Base";
import { base_route } from "../../backend";
import StudentBookingNew from "./cards/StudentBookingNew";
import StudentTrialBooked from "./cards/StudentTrialBooked";
import StudentConverted from "./cards/StudentCoverted";
import StudentNoLaptop from "./cards/StudentNoLaptop";
import StudentTrialDone from "./cards/StudentTrialDone";
import StudentReschedule from "./cards/StudentReschedule";
import { Grid, makeStyles } from "@material-ui/core";
import StudentCancelSlot from "./cards/StudentCancelSlot";
import "./styles/s-booking.css";
import StudentHalfDone from "./cards/StudentHalfDone";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const StudentBooking = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    newbooking: 0,
    nolaptop: 0,
    trailComplete: 0,
    bookedTrail: 0,
    paidUsers: 0,
    rescheduledBookings: 0,
    cancelledSlot: 0,
  });
  const onClick = (query) => {
    history.push({
      pathname: "/dashboard/s-booking/info",
      state: { query: query },
    });
  };

  useEffect(async () => {
    let role;
    let personID;
    setLoading(true);
    if (!JSON.parse(sessionStorage.getItem("tk_jwt"))) role = "";
    else {
      role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
      personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    }
    await fetch(
      `${base_route}/admin/s-booking/statics?role=${role}&personID=${personID}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          console.log("Result", result);
          setData(result);
        } else {
          alert("Error in fetching");
        }
      });
    setLoading(false);
  }, []);

  const getNewBooking = async (UR) => {
    const res = await fetch(URL);
    const data = await res.json();
    // console.log(data);
    // setBooking(data.reverse());
    // setLoading(false);
  };

  const classes = useStyles();
  return (
    <Base>
      {loading ? (
        <div style={{ position: "fixed", top: "50%", left: "50%" }}>
          <CircularProgress size={150} />
        </div>
      ) : (
        <div className={`s-booking mt-2`}>
          <Grid container spacing={3}>
            {data.newbooking != null && (
              <Grid item xs={6}>
                <StudentBookingNew data={data} />
              </Grid>
            )}
            {data.nolaptop != null && (
              <Grid item xs={6}>
                <StudentNoLaptop data={data} />
              </Grid>
            )}
            {data.bookedTrail != null && (
              <Grid item xs={6}>
                <StudentTrialBooked data={data} />
              </Grid>
            )}
            {data.trailComplete != null && (
              <Grid item xs={6}>
                <StudentTrialDone data={data} />
              </Grid>
            )}
            {data.paidUsers != null && (
              <Grid item xs={6}>
                <StudentConverted data={data} />
              </Grid>
            )}
            {data.rescheduledBookings != null && (
              <Grid item xs={6}>
                <StudentReschedule data={data} />
              </Grid>
            )}
            {data.halfDone != null && (
              <Grid item xs={6}>
                <StudentHalfDone data={data} />
              </Grid>
            )}
            {data.cancelledSlot != null && (
              <Grid item xs={6}>
                <StudentCancelSlot data={data} />
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </Base>
    /* <div className="booking_item " onClick={() => onClick("dnp")}>
            DNP
            <h1>0</h1>
          </div>
          <div className="booking_item " onClick={() => onClick("followup")}>
            Followup
            <h1>0</h1>
          </div>
          <div
            className="booking_item "
            onClick={() => onClick("not-interested")}
          >
            Not Interested
            <h1>0</h1>
          </div>

          <div className="booking_item " onClick={() => onClick("reschedule")}>
            Reschedule
            <h1>{data.rescheduledBookings}</h1>
          </div>
          <div className="booking_item " onClick={() => onClick("s-dnp")}>
            S-DNP
            <h1>0</h1>
          </div>
          <div className="booking_item " onClick={() => onClick("s-followup")}>
            S-FollowUp
            <h1>0</h1>
          </div>
          <div className="booking_item " onClick={() => onClick("s-demo-done")}>
            S-Demo Done
            <h1>0</h1>
          </div>
          <div
            className="booking_item "
            onClick={() => onClick("s-not-interested")}
          >
            S-not interested
            <h1>0</h1>
          </div>
        </div>
      </div> 
  </Base> */
  );
};

export default StudentBooking;

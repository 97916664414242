import React, { useEffect, useState, useRef } from "react";
import { base_route } from "../../../backend";
import { searchAlgo, findCountry } from "../../../Utils/Helper.js";
import BackNavigation from "../../../Common/Components/BackNavigation";
import Base from "../../../Common/Components/Base";
import { CircularProgress } from "@material-ui/core";
import { Header } from "./../components/Header";
import { useHistory } from "react-router";
import "./../../../styles/common/table.css";

export const TrialDone = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [isFeedbackClicked, setIsFeedbackClicked] = useState(false);
  const history = useHistory();
  const [clickedName, setClickedName] = useState("");
  const [clickedFeedback, setClickedFeedback] = useState([]);
  const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  useEffect(() => {
    // to show limited records
    setCurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage, students]);
  useEffect(() => {
    fetchTrialDone();
  }, []);

  const fetchTrialDone = async () => {
    setLoading(true);
    let URL;
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    URL = `${base_route}/admin/s-booking/trialDone?role=${role}&personID=${personID}`;
    const response = await fetch(URL, {
      method: "GET",
    });
    let res = await response.json();
    let data = [];
    res.forEach((slot) => {
      let date = stringToDate(slot.slot);
      console.log(date);
      let newSlot = { ...slot, date: date };
      data.push(newSlot);
    });
    data = data.sort((a, b) => b.date - a.date);
    console.log(data);
    setStudents(data);
    setLoading(false);
  };
  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };
  const handleDelete = async (type, ID) => {
    fetch(`${base_route}/admin/s-booking/deleteData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type, ID }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          alert(res.error);
          return;
        } else {
          window.location.reload();
          return;
        }
      });
  };
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const stringToDate = (date) => {
    const month = date.substring(4, 7);
    const day = date.substring(8, 10);
    const year = date.substring(11, 15);
    return new Date(
      months.indexOf(month) + 1 + "-" + parseInt(day) + "-" + year
    );
  };
  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    return new Date(secondsSinceEpoch * 1000).toDateString().substring(4, 15);
  };
  const handleClick = (data, name) => {
    setClickedName(name);
    setClickedFeedback(data);
    setIsFeedbackClicked(true);
    console.log(data);
    history.push({
      pathname: "/dashboard/s-booking/info/studentfeedback",
      state: { name: name, data: data },
    });
  };
  return (
    <Base>
      <div className="s-bookingTrialDoe">
        <BackNavigation />
        <Header
          setSearchFeild={setSearchFeild}
          searchFeild={searchFeild}
          search={search}
          setSearch={setSearch}
          recordsPerPage={recordsPerPage}
          onRecordPerPageClick={onRecordPerPageClick}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          indexOfFirstRecord={indexOfFirstRecord}
          indexOfLastRecord={indexOfLastRecord}
          data={students}
          title={"Trial Done"}
        />
      </div>
      <div className="mainTable">
        <table id="customers">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Trial Date</th>
              <th>Parent's Name</th>
              <th>Student's Name</th>
              <th>Grade</th>
              <th>Country</th>
              <th>Phone No.</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>

          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            currentRecords.map((item, index) => {
              return (
                <tr
                  key={item.uid}
                  className={index === clickedIndex && `selected_row`}
                >
                  <td>{indexOfFirstRecord + index + 1}</td>
                  <td>{item.slot}</td>
                  <td>{item.parentName}</td>
                  <td>{item.studentName}</td>

                  <td>{item.grade}</td>
                  <td>{findCountry(`+${item.parentPhone.countryCode}`)}</td>
                  <td>{item.parentPhone.number}</td>

                  <td>{item.studentEmail}</td>
                  <td>
                    <button
                      className="feedback_buttton btn btn-primary"
                      onClick={() =>
                        handleClick(item.feedback, item.studentName)
                      }
                    >
                      Feedback
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </table>
      </div>
    </Base>
  );
};

import React, { Component } from "react";
import Base from "../../Common/Components/Base";
const TeacherManagerComponent = () => {
  return (
    <Base>
      <div>teacher manager works!!</div>
    </Base>
  );
};

export default TeacherManagerComponent;

import React from "react";
import successTick from "../../Assests/successTick.png";
import errorMark from "../../Assests/errorIcon.png";

const StatusModal = ({ showModal, closeModal, text, className, status }) => {
  return (
    <div className={`modal    ${showModal && "show_modal"} ${className}`}>
      <div className="modal_content success_modal ">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <img
            src={status === "success" ? successTick : errorMark}
            alt="sucess-tick"
            className="sucess_tick_img"
          />
          {status === "success" ? (
            <h4 className="msg_text success_clr">Success!</h4>
          ) : (
            <h4 className="msg_text error_clr">Error!</h4>
          )}
          <h4 className="success_msg text-center">{text}</h4>
        </div>
        {status === "success" ? (
          <button
            className="continue success_btn_clr"
            onClick={() => closeModal()}
          >
            CONTINUE
          </button>
        ) : (
          <button
            className="continue error_btn_clr"
            onClick={() => closeModal()}
          >
            CONTINUE
          </button>
        )}
      </div>
    </div>
  );
};

export default StatusModal;

import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import { base_route } from "../../../backend";
import { searchAlgo, findCountry } from "../../../Utils/Helper.js";
import BackNavigation from "../../../Common/Components/BackNavigation";
import Base from "../../../Common/Components/Base";
import TkDropDown from "../../../Common/Components/DropDown";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import { Header } from "./../components/Header";
import "./../../../styles/common/table.css";

export const HalfDone = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [currentRecords, setCurrentRecords] = useState([]);
  const history = useHistory();
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const [isAddUserClicked, setIsAddUserClicked] = useState(false);
  const [isEditBookingBtnClicked, setIsEditBookingBtnClicked] = useState(false);
  const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
  const [newUser, setNewUser] = useState({
    name: "",
    parentName: "",
    grade: "",
    number: "",
    studentEmail: "",
    bookingDate: "",
    bookingTime: "",
  });
  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  useEffect(() => {
    // to show limited records
    setCurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage, students]);
  useEffect(() => {
    cancelSlots();
  }, []);

  const cancelSlots = async () => {
    setLoading(true);
    let URL;
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    URL = `${base_route}/admin/s-booking/halfDone?role=${role}&personID=${personID}`;
    const response = await fetch(URL, {
      method: "GET",
    });
    const res = await response.json();
    setStudents(res.reverse());
    setLoading(false);
    console.log(res);
  };
  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };
  const handleOnRowClick = (i, student, mode) => {
    history.push({
      pathname: "/dashboard/s-booking/info/student",
      state: { student: student, mode: mode },
    });
    setclickedIndex(i);
  };
  const handleDelete = async (type, ID) => {
    fetch(`${base_route}/admin/s-booking/deleteData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type, ID }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          alert(res.error);
          return;
        } else {
          window.location.reload();
          return;
        }
      });
  };

  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    return new Date(secondsSinceEpoch * 1000).toDateString().substring(4, 15);
  };
  return (
    <Base>
      <div className="s-bookingCancel">
        <BackNavigation />
        <Header
          setIsAddUserClicked={setIsAddUserClicked}
          setSearchFeild={setSearchFeild}
          searchFeild={searchFeild}
          search={search}
          setSearch={setSearch}
          recordsPerPage={recordsPerPage}
          onRecordPerPageClick={onRecordPerPageClick}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          indexOfFirstRecord={indexOfFirstRecord}
          indexOfLastRecord={indexOfLastRecord}
          data={students}
          title={"Half Done"}
        />
      </div>
      <div className="mainTable">
        <table id="customers">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Lead Date</th>
              {role === "ADMIN" ? <th>PersonID</th> : <></>}
              <th>Teacher's Name</th>
              <th>Parent's Name</th>
              <th>Student's Name</th>
              <th>Grade</th>
              <th>Country</th>
              <th>Phone No.</th>
              <th>Trial Date</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            // <div>currentRecords[0].studentEmail</div>
            currentRecords.map((item, index) => {
              return (
                <>
                  <tr
                    key={item.studentEmail}
                    className={index === clickedIndex && `selected_row`}
                  >
                    <td>{indexOfFirstRecord + index + 1}</td>
                    <td>{getRegDate(item._id)}</td>
                    {role === "ADMIN" ? <td>{item.personID}</td> : null}
                    <td>{item.teacherName}</td>
                    <td>{item.parentName}</td>
                    <td onClick={() => handleOnRowClick(index, item, "new")}>
                      {item.studentName}
                    </td>
                    <td>{item.grade}</td>

                    <td>
                      {findCountry(
                        `+${item.contactNo.toString().substring(0, 2)}`
                      )}
                    </td>
                    <td>{item.contactNo.toString().substring(2, 12)}</td>
                    <td>{item.slot}</td>
                    <td>{item.studentEmail}</td>
                    <td>
                      {role === "ADMIN" ? (
                        <button
                          className="delete_buttton"
                          onClick={() => handleDelete("halfDone", item._id)}
                        >
                          Delete
                        </button>
                      ) : null}
                    </td>
                  </tr>
                </>
              );
            })
          )}
        </table>
      </div>
    </Base>
  );
};

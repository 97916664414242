import Roles from "./Roles.js";
import Dashboard from "../components/Dashboard";
import StudentFeedback from "../components/student/StudentFeedback";
import StudentCurriculum from "../components/student/StudentCurriculum";
import StudentManager from "../components/Manager/StudentManager";
import TeacherManager from "../components/Manager/TeacherManager";
import StudentRegistration from "../components/student/StudentRegistration";
import TeacherRegistration from "../components/T-Registration/TeacherRegistration";
import Sales from "../components/Sales";
import Reminder from "../components/Reminder";
import Setting from "../components/Setting";
import Ticket from "../components/Ticket";
import Project from "../components/Project";
import Notification from "../components/Notification";
import User from "../components/User";
import ProfileAccess from "../components/ProfileAccessCom/ProfileAccess";
import UserInfo from "../components/UserInfo";
import ProfileAccessEdit from "../components/ProfileAccessCom/ProfileAccessEdit";
import StudentFreeVideo from "../components/student/StudentFeeVideo";
import TeacherSlot from "../components/T-Slot/TeacherSlot";
import TeacherSlotTime from "../components/T-Slot/TeacherSlotTime";
import StudentBookingInfo from "../components/S-Booking/StudentBookingInfo";
import StudentBooking from "../components/S-Booking/StudentBooking";
import StudentInfo from "../components/student/StudentInfo";
import TeacherCurriculum from "../components/T-Curriculum/TeacherCurriculum";
import ReferEarn from "../components/ReferEarn/ReferEarn";
import ReferEarnIndividual from "../components/ReferEarn/ReferEarnIndividual";
import Coupon from "../components/Coupon";
import TeacherEarning from "../components/T-Earning/TeacherEarning";
import TeacherEarningEdit from "../components/T-Earning/TeacherEarningEdit";
import TeacherLeave from "../components/T-Leave/TeacherLeave";
import OldStudentRegistration from "../components/os-registration/OldStudentInfo";
import FeedbackOnPaidStudent from "../components/FeedbackOnPaidStudent";
import ContactUs from "../components/Contact/ContactUs";
import ChangePassword from "../components/ChangePassword.js";
import TechSupport from "../components/TechSupport/TechSupport";
import TicketCreate from "../components/Ticket/TicketCreate";
import TicketSupport from "../components/Ticket/TicketSupport";
import Profile from "../components/Profile/Profile";
import PaidSlot from "../components/PaidSlot";
import ProfileEdit from "../components/Profile/ProfileEdit";

import BookingsInfo from "../components/S-Booking/StudentBookingInfo";
import { New } from "../components/S-Booking/tables/New.js";
import { NoLaptop } from "../components/S-Booking/tables/NoLaptop.js";
import { Converted } from "../components/S-Booking/tables/Converted.js";
import { TrialDone } from "../components/S-Booking/tables/TrialDone.js";
import { TrialBooked } from "../components/S-Booking/tables/TrialBooked.js";
import { CancelSlot } from "../components/S-Booking/tables/CancelSlot.js";
import { Reschedule } from "../components/S-Booking/tables/Reschedule.js";
import { HalfDone } from "../components/S-Booking/tables/HalfDone.js";
import Blog from "../components/Blog/newBlog";
import Display from "../components/Blog/display";
import TableContent from "../components/TableContent.js";
const PrivateRouteArray = [
  // {
  //   component: Dashboard,
  //   path: "/",
  //   exact: true,
  //   title: "Dashboard",
  //   permission: [Roles.ADMIN, Roles.GUEST, "TESTER"],
  // },
  {
    component: New,
    path: "/s-booking/new",
    title: "S_New",
    exact: true,
    permission: [Roles.ADMIN, Roles.GUEST, "TESTER"],
  },
  {
    component: HalfDone,
    path: "/s-booking/halfDone",
    title: "S_New",
    exact: true,
    permission: [Roles.ADMIN, Roles.GUEST, "TESTER"],
  },
  {
    component: CancelSlot,
    path: "/s-booking/cancel",
    title: "S_CancelSlot",
    exact: true,
    permission: [Roles.ADMIN, Roles.GUEST, "TESTER"],
  },
  {
    component: Reschedule,
    path: "/s-booking/reschedule",
    title: "S_Rescheduled",
    exact: true,
    permission: [Roles.ADMIN, Roles.GUEST, "TESTER"],
  },
  {
    component: TrialBooked,
    path: "/s-booking/trialBooked",
    title: "S_Trial_Booked",
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    component: NoLaptop,
    path: "/s-booking/noLaptop",
    title: "S_Laptop",
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    component: TrialDone,
    path: "/s-booking/trialDone",
    title: "S_Trial_Done",
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    component: Converted,
    path: "/s-booking/converted",
    title: "S_Converted",
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    component: StudentBooking,
    path: "/s-booking",
    title: "S-Booking",
    exact: true,
    permission: [Roles.ADMIN, Roles.GUEST, "TESTER"],
  },
  {
    component: StudentRegistration,
    path: "/s-registration",
    exact: true,
    title: "S-Registration",
    permission: [Roles.ADMIN],
  },
  {
    component: OldStudentRegistration,
    path: "/os-registration",
    exact: true,
    title: "OS-Registration",
    permission: [Roles.ADMIN],
  },
  {
    component: StudentCurriculum,
    path: "/s-curriculum",
    exact: true,
    title: "S-Curriculum",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: StudentFreeVideo,
    path: "/s-free-video",
    exact: true,
    title: "S-Free Video",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TeacherSlot,
    path: "/t-slot",
    exact: true,
    title: "T-Slot",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: PaidSlot,
    path: "/p-slot",
    exact: true,
    title: "P-Slot",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TeacherRegistration,
    path: "/t-registration",
    exact: true,
    title: "T-Registration",
    permission: [Roles.ADMIN],
  },
  {
    component: TeacherCurriculum,
    path: "/t-curriculum",
    exact: true,
    title: "T-Curriculum",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TeacherEarning,
    path: "/t-earning",
    exact: true,
    title: "T-Earning",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TeacherLeave,
    path: "/t-leave",
    title: "T-Leave",
    exact: true,
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: ReferEarn,
    path: "/refer&earn",
    exact: true,
    title: "Refer & Earn",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: Coupon,
    path: "/coupon",
    title: "Coupon Code",
    exact: true,
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: Ticket,
    path: "/ticket",
    exact: true,
    title: "Ticket",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: Notification,
    path: "/notification",
    exact: true,
    title: "Notification",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  // {
  //   component: Setting,
  //   path: "/setting",
  //   exact: true,
  //   title: "Setting",
  //   permission: [Roles.ADMIN, Roles.GUEST, "TESTER", "SUPPORT"],
  // },
  {
    component: TeacherEarningEdit,
    path: "/t-earning/edit/",
    exact: true,
    title: "Edit Earning",
    permission: [Roles.ADMIN, Roles.GUEST],
  },

  {
    component: ReferEarnIndividual,
    path: "/refer&earn/individual",
    exact: true,
    title: "Refer & Earn Individual",
    permission: [Roles.ADMIN, Roles.GUEST],
  },

  // {
  //   component: StudentBookingInfo,
  //   path: "/s-booking/info",
  //   exact: true,
  //   title: "Student Booking Info",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },
  // {
  //   component: Sales,
  //   path: "/sales",
  //   exact: true,
  //   title: "Sales",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },

  {
    component: User,
    path: "/user",
    exact: true,
    title: "User",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: UserInfo,
    path: "/user/info",
    exact: true,
    title: "User Info",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  // {
  //   component: Reminder,
  //   path: "/reminder",
  //   exact: true,
  //   title: "Reminder",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },

  // {
  //   component: Project,
  //   path: "/project",
  //   exact: true,
  //   title: "Project",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },

  {
    component: TeacherSlotTime,
    path: "/t-slot/time",
    exact: true,
    title: "Teacher Slot Time",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  // {
  //   component: StudentManager,
  //   path: "/student-manager",
  //   exact: true,
  //   title: "Student Manager",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },

  {
    component: StudentManager,
    path: "/student/:user",
    exact: true,
    title: "Student User",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TeacherManager,
    path: "/teacher/:teacher",
    exact: true,
    title: "Teacher",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: ProfileAccess,
    path: "/profile/access",
    exact: true,
    title: "Profile Access",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: ProfileAccessEdit,
    path: "/profile/access/add",
    exact: true,
    title: "Edit Profile Access",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  // {
  //   component: Profile,
  //   path: "/setting/profile",
  //   exact: true,
  //   title: "Profile",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },
  // {
  //   component: ProfileEdit,
  //   path: "/setting/profile/editprofile",
  //   exact: true,
  //   title: "Edit Profile",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },
  // {
  //   component: ContactUs,
  //   path: "/setting/contactus",
  //   exact: true,
  //   title: "Contact Us",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },
  // {
  //   component: ChangePassword,
  //   path: "/setting/changepassword",
  //   exact: true,
  //   title: "Change Password",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },
  // {
  //   component: TechSupport,
  //   path: "/setting/support/tech",
  //   exact: true,
  //   title: "Tech support",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },
  // {
  //   component: TicketSupport,
  //   path: "/setting/support/ticket",
  //   exact: true,
  //   title: "Ticket support",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },
  // {
  //   component: TicketCreate,
  //   path: "/setting/support/ticket/create",
  //   exact: true,
  //   title: "Tech support",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },

  {
    component: StudentInfo,
    path: "/s-registration/info/",
    exact: true,
    title: "Student Info",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: FeedbackOnPaidStudent,
    path: "/s-booking/info/student/feedback",
    exact: true,
    title: "Feedback On Paid Student",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: Display,
    path: "/blog",
    exact: true,
    title: "Blog",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: Blog,
    path: "/blog/createblog",
    exact: true,
    title: "Blog",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: Blog,
    path: "/blog/editblog/:id",
    exact: true,
    title: "Blog",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TableContent,
    path: "/payment",
    exact: true,
    title: "Payment",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
];

export default PrivateRouteArray;

import React from "react";
import TkDropDown from "../../../Common/Components/DropDown";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import "./../styles/header.css";
import {
  Nav,
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
export const Header = ({
  setIsAddUserClicked,
  setSearchFeild,
  searchFeild,
  search,
  setSearch,
  recordsPerPage,
  onRecordPerPageClick,
  currentPage,
  setCurrentPage,
  indexOfFirstRecord,
  indexOfLastRecord,
  data,
  fetchNewBookings,
  mode,
  title,
}) => {
  let width = window.innerWidth;
  let items = ["25", "50", "100", "150", "200"];

  return (
    <>
      <Navbar bg="light" expand="lg" className="mb-3">
        <Navbar.Brand href="#home">{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {mode == "New" && (
              <Nav.Link
                className="d-flex btn btn-outline-success text-dark pr-3"
                onClick={() => setIsAddUserClicked(true)}
              >
                <>
                  <AddIcon style={{ color: "#36ff6b" }} /> <div>Add User</div>
                </>
              </Nav.Link>
            )}
            <NavDropdown title="More Action" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="#link">
            <span>Records Per Page</span>{" "}
            <TkDropDown
              style={{ width: "80px", height: "30px", marginLeft: "10px" }}
              _onClick={onRecordPerPageClick}
              value={recordsPerPage}
              items={["25", "50", "100", "150", "200"]}
            />
          </Nav.Link> */}
            {/* <NavDropdown title="Records per page" id="basic-nav-dropdown">
              {items.map((item, index) => {
                return (
                  <NavDropdown.Item
                    href="#action/3.2"
                    _onClick={onRecordPerPageClick}
                  >
                    {items[index]}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown> */}
          </Nav>
          <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
      <div className="mb-2 d-flex justify-content-between">
        <div className="d-flex">
          <TkDropDown
            style={{ width: "80px", height: "30px", marginLeft: "10px" }}
            _onClick={onRecordPerPageClick}
            value={recordsPerPage}
            mainClass={"d-flex align-items-center"}
            label={"Records Per Page"}
            variant={"light"}
            items={["25", "50", "100", "150", "200"]}
          />
        </div>
        <div>
          <NavigateBeforeIcon
            onClick={() =>
              currentPage === 1 ? null : setCurrentPage(currentPage - 1)
            }
          />
          {indexOfFirstRecord + 1} - {indexOfLastRecord} / {data.length}
          <NavigateNextIcon
            onClick={() =>
              indexOfLastRecord > data.length
                ? null
                : setCurrentPage(currentPage + 1)
            }
          />
        </div>
      </div>
    </>
  );
};

import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

const DayPicker = ({ label, date, setDate, name }) => {
  // console.log("date at day picker", date);
  // const [value, setValue] = useState(new Date());
  return (
    <Grid container className='day_picker'>
      <label className='leave_label'>{label}</label>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          views={['date']}
          name={name}
          value={date == '' ? new Date() : date}
          format={'dd/MM/yyyy'}
          onChange={(e) => {
            setDate(e)
          }}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  )
}

export default DayPicker

import React, { useState, useEffect } from "react";
import Base from "../Common/Components/Base";
import { useHistory } from "react-router-dom";
import "../styles/UserInfo.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TkDropDown from "../Common/Components/DropDown";
import PhoneInput from "react-phone-input-2";
import SearchIcon from "@material-ui/icons/Search";
import "react-phone-input-2/lib/style.css";
import SearchDetails from "./SearchInfo";
import ManagerDetails from "./ManagerInfo";
import { useLocation } from "react-router-dom";
import { base_route } from "../backend";

const UserInfo = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [sclick, setSClick] = useState(false);
  const [contact, setContact] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [profile, setProfile] = useState("Please Select");
  const [doj, setDoj] = useState(new Date());
  const [status, setStatus] = useState("Active");
  const [reportingTo, setReportingTo] = useState("");
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [isReportingClicked, setIsReportingClicked] = useState(false);
  const [inputField, setInputField] = useState("");
  console.log("user info");
  const history = useHistory();
  const location = useLocation();
  var data = location.state.data;
  var type = location.state.type;
  const handleSearch = () => {
    // setIsClicked(true);
  };

  const handleSave = () => {
    setSClick(true);
    console.log(type, data);
    console.log(name, email, contact, employeeCode, profile, doj, reportingTo);

    if (!name || !email || !contact || !employeeCode) {
      alert("All fields required");
      setSClick(false);
      return;
    }

    if (profile == "Please Select") {
      alert("Please Select Profile");
      setSClick(false);
      return;
    }
    if (type != "edit") {
      fetch(`${base_route}/admin/settings/user/addUser`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          contact,
          employeeCode,
          profile,
          doj,
          reportingTo,
          status,
        }),
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.message) {
            alert("User saved Succesfully");
            setSClick(false);
            history.push("/dashboard/user");
          } else {
            alert(resp.error);
            setSClick(false);
            return;
          }
        })
        .catch((err) => console.log(err));
    } else {
      fetch(`${base_route}/admin/settings/user/updateUser`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          contact,
          employeeCode,
          profile,
          doj,
          reportingTo,
          status,
        }),
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.message) {
            history.push("/dashboard/user");
          } else {
            alert(resp.error);
            return;
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (type == "edit") {
      setName(data.name);
      setEmail(data.email);
      setEmployeeCode(data.empcode);
      setStatus(data.isActive);
      setReportingTo(data.reportingTo);
      setProfile(data.profile);
      setDoj(new Date());
      if (data.phone.number) {
        setContact("91" + data.phone.number.toString());
      }
    }
  }, []);

  return (
    <Base>
      <div className="container mt-5">
        <span className="back_icon" onClick={() => history.goBack()}>
          <ArrowBackIcon></ArrowBackIcon>
        </span>
        <div className="user_info">
          <h2>User Info</h2>
          <div className="user_details">
            <div>
              <label htmlFor="name">Name*</label>
              <input
                type="text"
                name="name"
                id=""
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <br />
              <label htmlFor="contact">Contact*</label>
              <PhoneInput
                country={"in"}
                value={contact}
                onChange={setContact}
              />
              <br />
              <label htmlFor="profile">Profile*</label>
              <div className="search_input">
                <i
                  onClick={() => {
                    setIsProfileClicked(true);
                    setInputField("profile");
                  }}
                >
                  <SearchIcon style={{ color: "#3f51b5" }}></SearchIcon>
                </i>
                <input
                  type="text"
                  name="profile"
                  id=""
                  value={profile}
                  readonly
                />
              </div>
              <br />
              <label htmlFor="userStatus">User Status</label>
              <TkDropDown
                style={{ width: "100px", height: "30px", marginLeft: "10px" }}
                _onClick={setStatus}
                value={status}
                items={["Active", "Inactive"]}
              />
            </div>
            <div>
              <label htmlFor="email">Email id*</label>
              <input
                type="text"
                name="email"
                id=""
                value={email}
                readonly={type == "edit" ? true : false}
                onChange={(e) => {
                  if (type != "edit") {
                    setEmail(e.target.value);
                  }
                }}
              />
              <br />
              <label htmlFor="employeeCode">Employee Code</label>
              <input
                type="text"
                name="employeeCode"
                id=""
                value={employeeCode}
                onChange={(e) => {
                  setEmployeeCode(e.target.value);
                }}
              />
              <br />
              <label htmlFor="DOJ">Date of Joining*</label>
              <input
                type="date"
                name="doj"
                id=""
                value={doj}
                onChange={(e) => {
                  setDoj(e.target.value);
                }}
              />
              <br />
              <label htmlFor="reportingTo">Reporting To</label>
              <div className="search_input">
                <i
                  onClick={() => {
                    setIsReportingClicked(true);
                    setInputField("reportingTo");
                  }}
                >
                  <SearchIcon style={{ color: "#3f51b5" }}></SearchIcon>
                </i>
                <input
                  type="text"
                  name="reportingTo"
                  id=""
                  value={reportingTo}
                  onChange={(e) => {
                    setReportingTo(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <button
            className="save_btn"
            onClick={() => handleSave()}
            disabled={sclick}
          >
            Save
          </button>
          {sclick ? <h6>Saving..</h6> : <></>}
        </div>
        <div>
          {isProfileClicked && (
            <SearchDetails
              setIsClicked={setIsProfileClicked}
              inputField={inputField}
              setProfile={setProfile}
              setReportingTo={setReportingTo}
            />
          )}
        </div>
        <div>
          {isReportingClicked && (
            <ManagerDetails
              setIsClicked={setIsReportingClicked}
              setReportingTo={setReportingTo}
            />
          )}
        </div>
      </div>
    </Base>
  );
};

export default UserInfo;

import React, { Component, useEffect, useState } from "react";
import "../../styles/students.css";
import "./styles/s-registration.css";
import { searchAlgo, findCountry } from "../../Utils/Helper.js";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import TkDropDown from "../../Common/Components/DropDown";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Base from "../../Common/Components/Base";
import { base_route } from "../../backend";
import { useHistory } from "react-router";
const StudentComponent = () => {
  const history = useHistory();
  const [students, setStudent] = useState([]);
  const [loading, setLoadig] = useState(false);
  const [search, setSearch] = useState("");
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [currentRecords, setcurrentRecords] = useState([]);

  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  useEffect(() => {
    setcurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage]);

  useEffect(() => {
    searchFeild && search != ""
      ? setcurrentRecords(searchAlgo(search, students))
      : setcurrentRecords(
          students.slice(indexOfFirstRecord, indexOfLastRecord)
        );
  }, [search, searchFeild]);

  useEffect(() => {
    loadStudent();
  }, []);
  useEffect(() => {
    setcurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [students]);

  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };

  const loadStudent = async () => {
    setLoadig(true);

    const response = await fetch(`${base_route}/booking/allBooking`);
    const student = await response.json();
    //console.log("Student", student);
    setStudent(student.reverse());
    setLoadig(false);
  };

  const handleDelete = async (type, ID) => {
    fetch(`${base_route}/admin/deletedata`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type, ID }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          alert(res.error);
          return;
        } else {
          alert(res.message);
          window.location.reload();
          return;
        }
      });
  };

  const handleOnRowClick = (i) => {
    setclickedIndex(i);
  };
  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    return new Date(secondsSinceEpoch * 1000).toDateString().substring(4, 15);
  };

  return (
    <Base>
      <div className="s-registration">
        <div className=" registration_actions">
          <div className="registration_action_item">
            <AddIcon
              // onClick={() => {
              //   history.push({
              //     pathname: "/s-registration/info",
              //     state: {
              //       student: null,
              //       mode: "add",
              //     },
              //   });
              // }}
              style={{ color: "#36ff6b" }}
            />
            Add
          </div>
          <div className="registration_action_item">
            <CloseIcon style={{ color: "#e82525" }} />
            Delete
          </div>
          <div
            className="registration_action_item"
            onClick={() => setSearchFeild(!searchFeild)}
          >
            <SearchIcon style={{ color: "#25a1e8" }} />
            Search
          </div>
          <div className="registration_action_item">
            <ArrowDropDownIcon />
            More Action
          </div>
        </div>
        {searchFeild && (
          <div className="d-flex align-items-center justify-content-center mt-3">
            <TextField
              label="Search by Anything"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}

        <div className="registration_tabel_action">
          <div className="d-flex">
            <span>Records per page</span>{" "}
            <TkDropDown
              style={{ width: "80px", height: "30px", marginLeft: "10px" }}
              _onClick={onRecordPerPageClick}
              value={recordsPerPage}
              items={["25", "50", "100", "150", "200"]}
            />
          </div>
          <div className="d-flex ">
            <NavigateBeforeIcon
              onClick={() =>
                currentPage === 1 ? null : setCurrentPage(currentPage - 1)
              }
            />
            {indexOfFirstRecord + 1} - {indexOfLastRecord} / {students.length}
            <NavigateNextIcon
              onClick={() =>
                indexOfLastRecord > students.length
                  ? null
                  : setCurrentPage(currentPage + 1)
              }
            />
            <div className="registration_action_item" onClick={loadStudent}>
              <RefreshIcon style={{ color: "#25a1e8" }} />
              Refresh
            </div>
          </div>
          <div
            className="registration_action_item "
            onClick={() => setSearchFeild(!searchFeild)}
          >
            <SearchIcon style={{ color: "#25a1e8" }} />
            Search
          </div>
        </div>

        <table id="customers">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Lead Date</th>
              <th>Student Name</th>
              <th>Parent Name</th>
              <th>Grade</th>
              <th>Country</th>
              <th>Phone No.</th>

              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>

          {loading || currentRecords.length < 1 ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            currentRecords.map((item, index) => (
              <tr
                key={item.uid}
                onClick={() => handleOnRowClick(index)}
                className={index === clickedIndex && `selected_row`}
              >
                <td>{indexOfFirstRecord + index + 1}</td>
                <td>{getRegDate(item._id)}</td>
                <td>{item.studentName}</td>
                <td>{item.parentName}</td>
                <td>{item.grade}</td>

                <td>
                  {findCountry(`+${item.contactNo.toString().substring(0, 2)}`)}
                </td>
                <td>
                  +{item.contactNo.toString().substring(0, 2)} {item.contactNo}
                </td>

                <td>{item.studentEmail}</td>
                <td>
                  <button
                    className="delete_buttton"
                    onClick={() => handleDelete("new", item._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </table>
      </div>
    </Base>
  );
};

export default StudentComponent;

import React, { useState, useEffect } from "react";
import "../styles/searchDetails.css";
import CloseIcon from "@material-ui/icons/Close";
import { base_route } from "../backend";

const SearchDetails = ({
  setIsClicked,
  setProfile,
  setReportingTo,
  inputField,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${base_route}/admin/settings/profileAccess/all`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log("resp", resp);
        setData(resp.reverse());
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="search_table_container">
      <h1>Profile</h1>
      <span className="feedback_close_icon">
        <CloseIcon fontSize="large" onClick={() => setIsClicked(false)} />
      </span>
      <table className="search_table">
        <tr>
          <th>Profile</th>
          <th>Access</th>
        </tr>
        {data.map((item, index) => {
          return (
            <tr
              onClick={() => {
                inputField === "profile"
                  ? setProfile(item.profile)
                  : setReportingTo(item.profile);
                setIsClicked(false);
              }}
            >
              <td>{item.profile}</td>
              <td>
                <div className="d-flex">
                  {item.access.map((item) => {
                    return item + ", ";
                  })}
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default SearchDetails;

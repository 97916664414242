import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/teachers.css";
import "./style/t-registration.css";
import TkDropDown from "../../Common/Components/DropDown";
import CloseIcon from "@material-ui/icons/Close";
import { coursesData, languagesData } from "../data";
import Base from "../../Common/Components/Base";
import { base_route } from "../../backend";

const TeacherComponent = () => {
  const [teachers, setTeacher] = useState([]);
  const [employeeCode, setEmployeeCode] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [techerEmail, setTeacherEmail] = useState("");
  const [teacherContact, setTeacherContact] = useState("");
  const [meetLink, setMeetLink] = useState("");
  const [designation, setDesignation] = useState("");
  const [grade, setGrade] = useState("");
  const [status, setStatus] = useState("");
  const [langList, setlangList] = useState([]);
  const [courseList, setcourseList] = useState([]);
  const [language, setLanguage] = useState("Select Language");
  const [courses, setCourses] = useState("Select Courses");
  const [isedit, setIsedit] = useState(false);
  const [render, setRender] = useState(0);
  const handleLanguageClick = (lang) => {
    setLanguage(lang);
    setlangList((oldArr) => [...oldArr, lang]);
  };
  const handleCourseClick = (course) => {
    setCourses(course);
    setcourseList((oldArr) => [...oldArr, course]);
  };
  const popLangItem = (index) => {
    setlangList(langList.filter((item, i) => i !== index));
  };

  const popCourseItem = (index) => {
    setcourseList(courseList.filter((item, i) => i !== index));
  };

  useEffect(() => {
    getTeacher();
  }, [render]);

  const getTeacher = async () => {
    const response = await fetch(`${base_route}/teacher/allTeachers`, {
      name: teacherName,
      email: techerEmail,
      phone: {
        countryCode: 91,
        number: parseInt(teacherContact),
      },
      meetingLink: meetLink,
      designation: designation,
      status: status,
    });
    const teacher = await response.json();

    setTeacher(teacher.reverse());
    console.log(teacher);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    registerTeacher();
  };

  const registerTeacher = async () => {
    if (
      employeeCode === "" ||
      teacherName === "" ||
      techerEmail === "" ||
      teacherContact === "" ||
      meetLink === "" ||
      designation === "" ||
      status === ""
    ) {
      toast("All Field are required");
      return;
    }

    fetch(`${base_route}/teacher/newTeacher`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: teacherName,
        email: techerEmail,
        phone: {
          countryCode: 91,
          number: parseInt(teacherContact),
        },
        meetingLink: meetLink,
        designation: designation,
        employeeCode: employeeCode,
        myCourses: Array.from(new Set(courseList)),
        languages: Array.from(new Set(langList)),
        grade: grade,
        status: status,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message) {
          setEmployeeCode("");
          setTeacherName("");
          setTeacherEmail("");
          setTeacherContact("");
          setMeetLink("");
          setDesignation("");
          setlangList([]);
          setcourseList([]);
          setLanguage("Select Language");
          setCourses("Select Courses");
          setGrade("");
          setStatus("");
          setIsedit(false);
          toast(resp.message);
          setRender((prev) => prev + 1);
        } else {
          toast(resp.error);
        }
      })
      .catch((err) => {
        toast("Error");
      });
  };
  const onRowClick = (teacher) => {
    setIsedit(true);
    setEmployeeCode(teacher.employeeCode);
    setTeacherName(teacher.name);
    setTeacherEmail(teacher.email);
    setTeacherContact(teacher.phone.number);
    setMeetLink(teacher.meetingLink);
    setDesignation(teacher.designation);
    setlangList(teacher.languages);
    setcourseList(teacher.myCourses);
    setLanguage("Select Language");
    setCourses("Select Courses");
    setGrade(teacher.grade);
    setStatus(teacher.isActive);
  };

  const handleDelete = (ID) => {
    console.log(ID);
    fetch(`${base_route}/teacher/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ID: ID,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message) {
          toast(resp.message);
          setRender((prev) => prev + 1);
        } else {
          toast(resp.error);
        }
      })
      .catch((err) => {
        toast("Error");
      });
  };
  return (
    <Base>
      <div className="t-registration">
        <div className="title">
          <h6 className="">Teacher Report</h6>
          <form style={{ width: "70%" }}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="text"
                    id="teacherName"
                    className="from-control"
                    placeholder="Full Name"
                    value={teacherName}
                    onChange={(e) => {
                      setTeacherName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    value={techerEmail}
                    placeholder="Email"
                    className="from-control"
                    onChange={(e) => {
                      setTeacherEmail(e.target.value);
                    }}
                    readOnly={isedit}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="number"
                    maxLength="10"
                    placeholder="Mobile"
                    id="mobile"
                    value={teacherContact}
                    onChange={(e) => {
                      setTeacherContact(e.target.value);
                    }}
                    className="from-control"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Employee Code"
                    id="Employee Code"
                    value={employeeCode}
                    onChange={(e) => {
                      setEmployeeCode(e.target.value);
                    }}
                    className="from-control"
                    readOnly={isedit}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    required
                    placeholder="Meeting Link"
                    value={meetLink}
                    className="from-control"
                    onChange={(e) => {
                      setMeetLink(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="text"
                    required
                    placeholder="Designation"
                    value={designation}
                    onChange={(e) => {
                      setDesignation(e.target.value);
                    }}
                    className="from-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    required
                    placeholder="Grade"
                    value={grade}
                    className="from-control"
                    onChange={(e) => {
                      setGrade(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <TkDropDown
                    style={{ width: "100%" }}
                    _onClick={setStatus}
                    value={status}
                    placeholder="Select status"
                    items={["Active", "Inactive"]}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <TkDropDown
                    style={{ width: "100%" }}
                    _onClick={handleLanguageClick}
                    value={language}
                    items={[...languagesData]}
                  />
                  <div className="d-flex flex-wrap mt-2 ">
                    {langList.map((item, index) => {
                      return (
                        <span className="badge_item" key={index}>
                          <span className="mr-1"> {item}</span>
                          <CloseIcon
                            style={{ fontSize: "15px" }}
                            onClick={() => popLangItem(index)}
                          />
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <TkDropDown
                    style={{ width: "100%" }}
                    _onClick={handleCourseClick}
                    value={courses}
                    items={[...coursesData]}
                  />
                  <div className="d-flex flex-wrap mt-2 ">
                    {courseList.map((item, index) => {
                      return (
                        <span className=" badge_item" key={index}>
                          <span className="mr-1"> {item}</span>
                          <CloseIcon
                            style={{ fontSize: "15px" }}
                            onClick={() => popCourseItem(index)}
                          />
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <button
              class="btn btn-primary mt-5"
              onClick={handleSubmit}
              type="submit"
            >
              Register Teacher
            </button>
          </form>
          <ToastContainer />
        </div>
        <table id="customers">
          <tr>
            <th>Sr.No</th>
            <th>Teacher Name</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Employee Code</th>
            <th>Designation</th>
            <th>Languages</th>
            <th>Course Taught</th>
            <th>Meet link</th>
            <th>Status</th>
            <th>Delete</th>
          </tr>

          {teachers.map((teacher, index) => (
            <tr onClick={() => onRowClick(teacher)}>
              <td>{index + 1}</td>
              <td>{teacher.name}</td>
              <td>{teacher.phone.number}</td>
              <td>{teacher.email}</td>
              <td>{teacher.employeeCode}</td>
              <td>{teacher.designation}</td>
              <td>
                {teacher.languages.map((item) => {
                  return item + ", ";
                })}
              </td>
              <td>
                {teacher.myCourses.map((item) => {
                  return item + ", ";
                })}
              </td>
              <td>{teacher.meetingLink}</td>
              <td>{teacher.isActive}</td>
              <td>
                <button
                  className="delete_button"
                  onClick={() => handleDelete(teacher._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </Base>
  );
};

export default TeacherComponent;

{
  /* <table id="customers">
  <tr>
    <th>Teacher Name</th>
    <th>Mobile</th>
    <th>Email</th>
    <th>Speciality</th>
    <th>Course Taught</th>
  </tr>

  {teachers.map((teacher) => (
    <tr>
      <td>{teacher.name}</td>
      <td>{teacher.parentName}</td>
      <td>{teacher.parentPhone.number}</td>
      <td>{teacher.email}</td>
      <td>{teacher.grade}</td>
      <td>{teacher.myCourses.length}</td>
    </tr>
  ))}
</table>; */
}

import countries from './CountryData.json'

export const findCountry = (code) => {
  const country = countries.find((data, index) => data.dial_code === code)
  return country ? country.name : 'International'
}

export const searchAlgo = (searchKey, arr) => {
  if (searchKey == 'All') searchKey = ''
  //console.log("algo", Object.values(obj).includes(searchKey));
  var data = arr.filter(function (obj) {
    return Object.values(obj).includes(searchKey)
  })
  return data
}

export const isArrayWithLength = (arr) => {
  return Array.isArray(arr) && arr.length
}

export const todayDate = () => {
  const d = new Date()
  const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
  const da = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  //   console.log(`${mo} ${da}`);
  return `${mo} ${da}`
}

export const openInNewTab = (url) => {
  window.open(url, '_blank')
}
export const onSameTab = (url) => {
  window.location.href = url
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
export const validatePassword = (password) => {
  // Minimum eight characters, at least one letter and one number:
  const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
  return re.test(String(password))
}

export const checkBase64 = (base64Img) => {
  const re = /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i
  return re.test(base64Img)
}

export const _arrayBufferToBase64 = (buffer) => {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export const calculateAge = (dobData) => {
  let dob = new Date(dobData)
  if (dob != undefined) {
    var month_diff = Date.now() - dob.getTime()
    var age_dt = new Date(month_diff)
    var year = age_dt.getUTCFullYear()
    var age = Math.abs(year - 1970)
    return age
  }
}

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Base from "../../../Common/Components/Base";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 400,
    background: "#006600",
    color: "white",
  },
  button: {
    color: "white",
    justifyContent: "center",
    display: "flex",
  },
});

const StudentBookingNew = ({ data }) => {
  const history = useHistory();
  const onClick = () => {
    history.push({
      pathname: "/dashboard/s-booking/new",
    });
  };

  const classes = useStyles();

  return (
    <div onClick={() => onClick()} style={{ cursor: "pointer" }}>
      <Card variant="contained" className={classes.card}>
        <CardContent>
          <>
            <Typography align="center">New</Typography>
            <Typography variant="h2">{data.newbooking}</Typography>
          </>
        </CardContent>
      </Card>
    </div>
  );
};

export default StudentBookingNew;

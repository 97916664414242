import React, { useState } from "react";
import BackNavigation from "../Common/Components/BackNavigation";
import Base from "../Common/Components/Base";
import "../styles/FeedbackOnPaidStudent.css";
import { useLocation } from "react-router";
import { base_route } from "../backend";

const FeedbackOnPaidStudent = () => {
  const location = useLocation();
  const email = location.state.student.email;

  const [classNo, setClassNo] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [empcode, setEmpcode] = useState("");
  const [date, setDate] = useState("");

  const handleGo = async (e) => {
    e.preventDefault();
    if (classNo == 0) {
      alert("Class Number can'nt be Zero");
      return;
    }
    await fetch(`${base_route}/admin/getpaidfeedback`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, classNo }),
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.error) {
          alert(resp.error);
          setFeedback("");
          setDate("");
          setEmpcode("");
          setClassNo(0);
          return;
        } else {
          setFeedback(resp.feedback);
          setDate(resp.date);
          setEmpcode(resp.empcode);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Base>
      <BackNavigation />
      <div className="feedback_on_paid_student_container">
        <form>
          <label htmlFor="classNumber">Enter Class Number</label>
          <br />
          <input
            type="number"
            name="classNumber"
            placeholder="class number"
            value={classNo}
            onChange={(e) => setClassNo(e.target.value)}
            min="1"
            max="300"
          />
          <input type="submit" value="Go" onClick={(e) => handleGo(e)} />
        </form>
        <div className="individual_feedback_on_paid_student">
          <span>Date:</span>
          <p>{date}</p>
          <span>Emp Code:</span>
          <p>{empcode}</p>
          <span>Feedback</span>
          <p>{feedback == "NA" ? "No Feedback Avaliable" : feedback}</p>
        </div>
      </div>
    </Base>
  );
};

export default FeedbackOnPaidStudent;

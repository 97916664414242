import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { InputFeild } from "./Components/InputFeild";
import LoginBtn from "./Components/LoginBtn";
import "./Login.css";
import {
  authenticate,
  loginWithOTP,
  loginWithPassword,
  verifyOTP,
  resetPassword,
} from "./LoginAPICalls";
import Overlay from "./Overlay";
import StatusModal from "./StatusModal";
import LoginBase from "./LoginBase";

function Login() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });

  let history = useHistory();

  const onSameTab = (url) => {
    window.location.href = url;
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onSignInWithPassword = (event) => {
    //console.log("email ans password", email, password);
    event.preventDefault();
    setLoading(true);
    if (!email && !password) {
      setLoading(false);

      setError("Enter all the feilds");
    } else if (!validateEmail(email)) {
      setLoading(false);
      setError("Email not valid");
    } else {
      loginWithPassword(email, password).then((response) => {
        if (response.message) {
          setLoading(false);
          setError("");
          console.log(response);
          authenticate(
            {
              email: email,
              token: response.token,
              profile: response.profile.toUpperCase(),
              accessList: response.accessList,
              personID: response.personID,
            },
            () => {
              history.push("/dashboard/");
              window.location.reload();
            }
          );
        } else if (response.error) {
          setLoading(false);
          setError(response.error);
        }
      });
    }
  };

  return (
    <>
      {loading && <Overlay />}
      <LoginBase
        topsection={
          <>
            <h1 className="login_h1">Welcome Back!</h1>
            <p className="login_p">Sign in to your account</p>
          </>
        }
        children={
          <>
            <InputFeild
              type="email"
              placeholder={"Email"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
            />
            <InputFeild
              type="password"
              placeholder={"Password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError("");
              }}
            />
            <h6 className="text-danger">
              {" "}
              <b> {error}</b>
            </h6>
            <Link
              className="d-block text-right login_p mb-2 "
              to="/reset-password"
            >
              Reset Password
            </Link>
            <LoginBtn
              type={"button"}
              btnName={"NEXT"}
              className={"mt-3"}
              onClick={(e) => onSignInWithPassword(e)}
            />
          </>
        }
        bottomsection={
          <>
            <Link
              className=" login_p mb-5 "
              onClick={() => history.push("/login-with-otp")}
            >
              Sign in with OTP
            </Link>
            <p className="mt-3 login_p">
              Don't have an account
              <Link
                onClick={() => onSameTab("https://forms.gle/36WstfpZG4A4f6v59")}
              >
                Create an account
              </Link>
            </p>
          </>
        }
      />
      <StatusModal
        className="full_view"
        showModal={statusModal.showStatusModal}
        text={statusModal.statusText}
        status={statusModal.status}
        closeModal={() =>
          setStatusModal({ ...statusModal, showStatusModal: false })
        }
      />
    </>
  );
}

export default Login;

import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "../../styles/slots.css";
import "./styles/t-slot.css";
import { timings } from "../data";
import { useHistory, useLocation } from "react-router";
import Loading from "../../Assests/loading_logo.jpg";
import Base from "../../Common/Components/Base";
import { base_route } from "../../backend";
import TkDropDown from "../../Common/Components/DropDown";
import BackNavigation from "../../Common/Components/BackNavigation";

const Slot = () => {
	let history = useHistory();
	const location = useLocation();
	let currentDate;
	let currentTeacherName;
	let currentGrade;
	let currentTeacherID;
	let currentTeachersOBJ;
	let currentTeachers;
	if (location.state) {
		currentDate = location.state.rdate;
		currentTeacherName = location.state.teacherName;
		currentTeacherID = location.state.teacherID;
		currentGrade = location.state.grade;
		currentTeachersOBJ = location.state.teachersOBJ;
		currentTeachers = location.state.teachers;
	}
	const [slotData, setslotData] = useState([]);
	const [date, setDate] = useState(currentDate || new Date());
	const [show, setShow] = useState(false);
	const [Grade, setGrade] = useState(currentGrade || "");
	const [showDetails, setDetails] = useState(false);
	const [booking, setBooking] = useState(false);
	const [back, setBack] = useState();
	const [teachers, setTeachers] = useState(currentTeachers || []);
	const [teachersOBJ, setTeachersOBJ] = useState(currentTeachersOBJ || []);
	const [teacherName, setTeacherName] = useState(currentTeacherName || "");
	const [teacherID, setTeacherID] = useState(currentTeacherID || "");
	const [slotInfo, setslotInfo] = useState([
		{
			studentName: "Loading..",
			studentEmail: "Loading...",
			slot: "Loading..",
			teacherID: { name: "Loading.." },
		},
	]);
	const handleGradeClick = (grade) => {
		setGrade(grade);
	};
	const handleTeacherClick = (teacher) => {
		setTeacherName(teacher);
		console.log(teachersOBJ);
		teachersOBJ.forEach((c) => {
			if (c.name == teacher) {
				setTeacherID(c.id);
			}
		});
	};
	let width = window.innerWidth;
	let dateAndDrop = "";
	if (width <= 1000 && width > 800) {
		dateAndDrop = "";
	}

	const [clicked, setClick] = useState("");
	const my = useRef();
	const [u, setU] = useState(0);
	const onSlotClick = (time) => {
		history.push({
			pathname: "t-slot/time",
			state: {
				time: time,
				teacherID: teacherID,
				rdate: new Date(date),
				teacherName: teacherName,
				teachers: teachers,
				teachersOBJ: teachersOBJ,
			},
		});
	};

	const checkBooked = (slotData) => {
		var bookedSlot = 0;
		if (!slotData) {
			return 0;
		}
		slotData.forEach((item) => {
			bookedSlot += item.booked;
		});
		return bookedSlot;
	};

	const checkAvaliable = (slotData) => {
		var availableSlot = 0;
		if (!slotData) {
			return 0;
		}
		slotData.forEach((item) => {
			availableSlot += item.available;
		});
		return availableSlot;
	};
	useEffect(() => {
		async function fetchData() {
			my.current = undefined;
			setU((p) => p + 1);

			// console.log(new Date(date).toDateString());
			const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
			const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
			await fetch(`${base_route}/admin/t-slots/checkSlots`, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					role: role,
					personID: personID,
					date: new Date(date).toDateString(),
					grade: Grade,
					teacherID: teacherID,
				}),
			})
				.then((res) => res.json())
				.then(async (result) => {
					if (!result) {
						alert("Error While fetching Details");
					} else {
						//setslotData(result);
						console.log(result, "tslot");
						let currentTeachers = result.teachers;
						let teacherNames = [];
						currentTeachers.forEach((teacher) => {
							if (!teacherNames.includes(teacher.name)) {
								teacherNames.push(teacher.name);
							}
						});
						console.log(teachers.length);
						if (teachers.length == 0) {
							setTeachers(teacherNames);
							setTeachersOBJ(currentTeachers);
						}
						if (result.teachers.length > 2) {
							setTeachers(teacherNames);
							setTeachersOBJ(currentTeachers);
						}
						my.current = result.data;
						console.log(my.current);
						setU((p) => p + 1);
						// console.log("SlotData", my.current);
					}
				});
		}
		fetchData();
	}, [date, Grade, teacherID]);
	const showBooked = async (date) => {
		console.log(date);
		history.push({
			pathname: "/dashboard/t-slot/bookedTable",
			state: {
				teacherID: teacherID,
				rdate: new Date(date),
				teacherName: teacherName,
				teachers: teachers,
				teachersOBJ: teachersOBJ,
			},
		});
		// var rdate = new Date(date).toDateString();
		// setDetails(!showDetails);
		// setClick("booked");
		// setslotInfo([
		//   {
		//     studentName: "Null",
		//     studentEmail: "Null",
		//     slot: "Null",
		//     teacherID: { name: "Null" },
		//   },
		// ]);
	};
	const showAvaliable = async (date) => {
		history.push({
			pathname: "/dashboard/t-slot/availableTable",
			state: {
				teacherID: teacherID,
				Grade: Grade,
				rdate: new Date(date),
				teachers: teachers,
				teachersOBJ: teachersOBJ,
				teacherName: teacherName,
			},
		});
		// var rdate = new Date(date).toDateString();
		// console.log(rdate);

		// setDetails(!showDetails);
		// setClick("avaliable");
		// setslotInfo([
		//   {
		//     studentName: "Loading..",
		//     studentEmail: "Loading...",
		//     slot: "Loading..",
		//     teacherID: { name: "Loading.." },
		//   },
		// ]);
	};
	const status = (data) => {
		if (data.Total == 0) {
			return "unavailable";
		} else if (data.Total === data.booked) {
			return "violate";
		} else if (data.Total == data.available) {
			return "available";
		} else {
			return "bg-warning";
		}
	};
	// if (clicked) setBack(true);
	// const [table, setTable] = useState(true)

	// const displayTable = () => {
	//   setBooking(true);
	//   setBack(false);
	// };
	useEffect(() => {}, [u]);
	return (
		<Base>
			{my.current === undefined ? (
				<div style={{ position: "fixed", top: "50%", left: "50%" }}>
					<CircularProgress size={150} />
				</div>
			) : (
				<div className="t-slot ">
					<div
						className="threeFlex d-flex justify-content-between"
						style={{ width: "95%" }}
					>
						<div className="slot_today_info d-flex flex-column">
							<h2 className="dateLeft">
								<ArrowBackIcon
									className="slot_date_controller "
									onClick={() => {
										setDate(new Date(new Date().setDate(date.getDate() - 1)));
									}}
								></ArrowBackIcon>{" "}
								{new Date(date).toDateString()}{" "}
								<ArrowForwardIcon
									className="slot_date_controller "
									onClick={() => {
										setDate(new Date(new Date().setDate(date.getDate() + 1)));
									}}
								></ArrowForwardIcon>
							</h2>
							{!clicked ? (
								<div className="d-flex ">
									<div
										className="rounded slot_today_booked d-flex align-items-center py-2 flex-column text-white"
										onClick={() => showBooked(date)}
										style={{ cursor: "pointer" }}
									>
										<p>Booked</p>
										<h2>
											<b>{checkBooked(my.current)}</b>
										</h2>
									</div>
									<div
										className="rounded  slot_today_available d-flex align-items-center py-2 flex-column text-white "
										onClick={() => showAvaliable(date)}
										style={{ cursor: "pointer" }}
									>
										<p>Available</p>
										<h2>
											<b>{checkAvaliable(my.current)}</b>
										</h2>
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
						<div className="dateDiv d-flex align-items-center">
							<div className="gradeDiv d-flex ">
								<div className="m-2 ">
									<TkDropDown
										variant="warning"
										style={{ width: "100px" }}
										_onClick={handleGradeClick}
										value={Grade || "Grade"}
										items={["All", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
									/>
								</div>
								<div className="m-2">
									<TkDropDown
										variant="info"
										style={{ width: "100px" }}
										_onClick={handleTeacherClick}
										value={teacherName || "Teachers"}
										items={teachers}
									/>
								</div>
							</div>
							<div className="mt-4">
								<input
									className=""
									type="date"
									value={date}
									onChange={(e) => setDate(e.target.value)}
									style={{}}
								></input>
							</div>
						</div>
					</div>
					<div className="slots_info mt-4 d-flex flex-wrap">
						{showDetails ? (
							<></>
						) : (
							<div className="row">
								{timings.map((item, index) => {
									return (
										<div
											className={`slot col ${status(my.current[index])}`}
											key={item}
											style={{ marginLeft: "5%" }}
											onClick={() =>
												my.current[index].Total &&
												onSlotClick(my.current[index])
											}
										>
											<div className="slot_available_number">
												{my.current[index].Total}
											</div>
											{item}
										</div>
									);
								})}
							</div>
						)}
					</div>
					<hr />
					<div className="d-flex flex-column mt-5">
						<div className="d-flex align-items-center">
							<div
								className={`slot available`}
								key={"available"}
								style={{ marginLeft: "5%" }}
							>
								Available
							</div>
							<div style={{ fontSize: "120%" }}>
								Time which is available to be allotted as a trial in future.
							</div>
						</div>
						<div className="d-flex align-items-center">
							<div
								className={`slot violate`}
								key={"booked"}
								style={{ marginLeft: "5%" }}
							>
								Booked
							</div>
							<div style={{ fontSize: "120%" }}>
								Time which is booked for trial class.
							</div>
						</div>
						<div className="d-flex align-items-center">
							<div
								className={`slot bg-warning`}
								key={"both"}
								style={{ marginLeft: "5%" }}
							>
								Both
							</div>
							<div style={{ fontSize: "120%" }}>
								Both available and booked slots.
							</div>
						</div>
						<div className="d-flex align-items-center">
							<div
								className={`slot unavailable`}
								key={"empty"}
								style={{ marginLeft: "5%" }}
							>
								Empty
							</div>
							<div style={{ fontSize: "120%" }}>No slot at this time.</div>
						</div>
					</div>
				</div>
			)}
		</Base>
	);
};

export default Slot;

import React from "react";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Rating from "@material-ui/lab/Rating";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import BackNavigation from "../../Common/Components/BackNavigation";

const Feedback = ({ setIsFeedbackClicked, scrollY }) => {
  const location = useLocation();
  let name = location.state.name;
  let data = location.state.data;

  return (
    <div className="feedback_container d-flex flex-column align-items-center">
      <div className="d-flex align-items-between mt-4">
        <BackNavigation />
        <div
          className="d-flex flex-column p-4"
          style={{ border: "2px solid blue" }}
        >
          <div className="d-flex ">
            <h2 className="p-2">{name}</h2>
          </div>
          <div
            className=" h6 p-2 d-flex justify-content-between align-items-start"
            style={{ position: "relative", top: "20%" }}
          >
            <div className="chances_buying">
              <p>
                Chances of Buying:
                <span className="btn btn-danger">{data[0].chanceofBuy}</span>
              </p>
              <p>
                Chances of Buying:{" "}
                <span className="btn btn-success">{data[0].chanceofBuy2}</span>
              </p>
            </div>
          </div>
        </div>
        <div
          className="student_competence p-4 ml-3"
          style={{ border: "2px solid black" }}
        >
          <p className="h3">Student Competence</p>
          <div className="d-flex p-0 m-0">
            <Box component="fieldset" mb={1} mb={1} borderColor="transparent">
              <Typography component="legend">Logic:</Typography>
              <Rating
                name="simple-controlled"
                className="p-0 m-0"
                value={data[0].logic}
                readOnly
              />
            </Box>
          </div>
          <div className="d-flex p-0 m-0">
            <Box component="fieldset" mb={1} borderColor="transparent">
              <Typography component="legend">Concentration:</Typography>
              <Rating
                name="simple-controlled"
                className="p-0 m-0"
                value={data[0].concentration}
                readOnly
              />
            </Box>
          </div>
          <div className="d-flex p-0 m-0">
            <Box component="fieldset" mb={1} borderColor="transparent">
              <Typography component="legend">Creativity:</Typography>
              <Rating
                name="simple-controlled"
                className="p-0 m-0"
                value={data[0].creativity}
                readOnly
              />
            </Box>
          </div>
          <div className="d-flex p-0 m-0">
            <Box
              className="mt-0"
              component="fieldset"
              mb={1}
              borderColor="transparent"
            >
              <Typography component="legend">Memory:</Typography>
              <Rating
                name="simple-controlled"
                className="p-0 m-0"
                value={data[0].memory}
                readOnly
              />
            </Box>
          </div>
        </div>
      </div>
      <div className="feedback_content">
        <div className="d-flex flex-column align-items-center">
          <p className="pt-4  m-2 h6 ">
            Has your student posted his/her Project/Certificate and tagged
            @techokids on social media?
          </p>
          {data[0].socialMedia == "true" ? (
            <button className="btn btn-success m-2" style={{ width: "100px" }}>
              Yes
            </button>
          ) : (
            <button className="btn btn-danger m-2" style={{ width: "100px" }}>
              No
            </button>
          )}{" "}
          <div className="d-flex flex-column align-items-center justify-content-center">
            {data[0].reason && (
              <div
                className="d-flex flex-column p-2 m-2"
                style={{ border: "2px solid black", width: "500px" }}
              >
                <p className="h4">Reason</p>
                <div>{data[0].reason}</div>
              </div>
            )}
            <div
              className="d-flex flex-column p-2 m-2"
              style={{ border: "2px solid black", width: "500px" }}
            >
              <p className="h4">Feedback</p>
              <div>{data[0].feedback}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Feedback;

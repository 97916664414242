import React, { Component, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import BackNavigation from "../../Common/Components/BackNavigation";
import "../../styles/t_earning.css";
import Base from "../../Common/Components/Base";
import { base_route } from "../../backend";
import TkDropDown from "../../Common/Components/DropDown";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const EarningEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const [empcode, setEmpcode] = useState(location.state.empcode);
  const [date, setDate] = useState(location.state.date);
  const [data, setData] = useState([{}]);
  const [fixdata, setFixData] = useState([{}]);
  const [classType, setClassType] = useState("All");
  const [studentName, setStudentName] = useState("All");
  const [currentDate, setCurrentDate] = useState("All");
  const [time, setTime] = useState("All");
  const [studentNameList, setStudentNameList] = useState([]);
  const [dateList, setDateList] = useState([
    "All",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ]);
  const [render, setRender] = useState(0);
  console.log(empcode, date);

  const check1 = (value) => {
    if (value) {
      return true;
    }
  };
  const check2 = (value) => {
    if (!value) {
      return true;
    }
  };

  const onUpdate = async (ID, type, status) => {
    console.log(ID, type, status);
    await fetch(`${base_route}/admin/slotupdate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ID, type, status }),
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log(resp);
        setRender((prev) => prev + 1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(async () => {
    if (empcode != "") {
      console.log("empcode", empcode, date);
      await fetch(`${base_route}/admin/teacherslot`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ empcode, date }),
      })
        .then((res) => res.json())
        .then((resp) => {
          let newSlots = [];
          dateList.forEach((date) => {
            resp.forEach((slot) => {
              if (slot.slot.includes(` ${date} `)) {
                newSlots.push(slot);
              }
            });
          });
          setData(newSlots);
          setFixData(newSlots);
          let students = [];
          resp.forEach((slot) => {
            if (!students.includes(slot.studentName))
              students.push(slot.studentName);
          });
          students.unshift("All");
          setStudentNameList(students);
        })
        .catch((err) => console.log(err));
    }
  }, [empcode, date, render]);
  const handleTime = (time) => {
    setTime(time);
    setClassType("All");
    setStudentName("All");
    setCurrentDate("All");
    if (time == "All") {
      setData(fixdata);
    } else {
      let newSlot = [];
      fixdata.forEach((slot) => {
        if (slot.slot.includes(time)) {
          newSlot.push(slot);
        }
      });
      setData(newSlot);
    }
  };
  const handleDate = (date) => {
    setCurrentDate(date);
    setTime("All");
    setClassType("All");
    setStudentName("All");
    if (date == "All") {
      setData(fixdata);
    } else {
      let newSlot = [];
      fixdata.forEach((slot) => {
        if (slot.slot.includes(` ${date} `)) {
          newSlot.push(slot);
        }
      });
      setData(newSlot);
    }
  };
  const handleStudentName = (name) => {
    setTime("All");
    setClassType("All");
    setCurrentDate("All");
    setStudentName(name);
    if (name == "All") {
      setData(fixdata);
    } else {
      let newSlot = [];
      fixdata.forEach((slot) => {
        if (slot.studentName == name) {
          newSlot.push(slot);
        }
      });
      setData(newSlot);
    }
  };
  const handleClassType = (type) => {
    setTime("All");
    setClassType(type);
    setStudentName("All");
    setCurrentDate("All");
    if (type == "Paid") {
      let newData = [];
      fixdata.forEach((data) => {
        if (data.classtype == "paid") {
          newData.push(data);
        }
      });
      console.log(newData);
      setData(newData);
    } else if (type == "Trial") {
      let newData = [];
      fixdata.forEach((data) => {
        if (data.classtype == "") {
          newData.push(data);
        }
      });
      console.log(newData);
      setData(newData);
    } else {
      setData(fixdata);
    }
  };
  return (
    <Base>
      <BackNavigation />
      {empcode == "" ? (
        <h1>Select Teacher Please..</h1>
      ) : (
        <>
          <div className="d-flex justify-content-around">
            <TkDropDown
              value={time}
              label={"Time"}
              items={[
                "All",
                "06:00 AM",
                "07:00 AM",
                "08:00 AM",
                "09:00 AM",
                "10:00 AM",
                "11:00 AM",
                "12:00 PM",
                "1:00 PM",
                "2:00 PM",
                "3:00 PM",
                "4:00 PM",
                "5:00 PM",
                "6:00 PM",
                "7:00 PM",
                "8:00 PM",
                "9:00 PM",
                "10:00 PM",
                "11:00 PM",
                "12:00 AM",
                "01:00 AM",
                "02:00 AM",
                "03:00 AM",
                "04:00 AM",
                "05:00 AM",
              ]}
              variant={"primary"}
              _onClick={handleTime}
            />
            <TkDropDown
              value={currentDate}
              label={"Date"}
              items={dateList}
              variant={"primary"}
              _onClick={handleDate}
            />
            <TkDropDown
              value={classType}
              label={"C-Type"}
              items={["All", "Paid", "Trial"]}
              variant={"primary"}
              _onClick={handleClassType}
            />
            {/* <TkDropDown
              value={studentName}
              items={studentNameList}
              label={"S-Name"}
              variant={"primary"}
              _onClick={handleStudentName}
            /> */}
            <Autocomplete
              id="combo-box-demo"
              options={studentNameList}
              onInputChange={(event, newValue) => {
                handleStudentName(newValue);
                // setValues({ ...values, ["studentEmail"]: newValue });
              }}
              // onChange={(event, newValue) => {
              //   console.log(newValue);
              // fetchDetails(newValue);
              // }}
              inputValue={studentName}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="S-Name" variant="outlined" />
              )}
            />
          </div>
          <div className="slot_data">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">S-Time</th>
                  <th scope="col">C-Type</th>
                  <th scope="col">S-Name</th>
                  <th scope="col">Cancelled</th>
                  <th scope="col">H-Completed</th>
                  <th scope="col">P-IN</th>
                  <th scope="col">P-EIN</th>
                  <th scope="col">T-completed</th>
                  <th scope="col">T-converted</th>
                  <th scope="col">Panalty</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  if (item.booked) {
                    return (
                      <tr>
                        <td>{item.slot}</td>
                        <td>{item.classtype == "" ? "Trial" : "Paid"}</td>
                        <td>{item.studentName}</td>
                        <td>
                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              type="button"
                              class={
                                check1(item.cancelled)
                                  ? "success_button"
                                  : "white_button"
                              }
                              disabled={check1(item.cancelled)}
                              onClick={() =>
                                onUpdate(item._id, "cancelled", true)
                              }
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              class={
                                check2(item.cancelled)
                                  ? "danger_button"
                                  : "white_button"
                              }
                              disabled={check2(item.cancelled)}
                              onClick={() =>
                                onUpdate(item._id, "cancelled", false)
                              }
                            >
                              No
                            </button>
                          </div>
                        </td>
                        <td>
                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              type="button"
                              class={
                                check1(item.halfCompleted)
                                  ? "success_button"
                                  : "white_button"
                              }
                              disabled={check1(item.halfCompleted)}
                              onClick={() =>
                                onUpdate(item._id, "halfCompleted", true)
                              }
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              class={
                                check2(item.halfCompleted)
                                  ? "danger_button"
                                  : "white_button"
                              }
                              disabled={check2(item.halfCompleted)}
                              onClick={() =>
                                onUpdate(item._id, "halfCompleted", false)
                              }
                            >
                              No
                            </button>
                          </div>
                        </td>
                        <td>
                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              type="button"
                              class={
                                check1(item.paidClassINR)
                                  ? "success_button"
                                  : "white_button"
                              }
                              disabled={check1(item.paidClassINR)}
                              onClick={() =>
                                onUpdate(item._id, "paidClassINR", true)
                              }
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              class={
                                check2(item.paidClassINR)
                                  ? "danger_button"
                                  : "white_button"
                              }
                              disabled={check2(item.paidClassINR)}
                              onClick={() =>
                                onUpdate(item._id, "paidClassINR", false)
                              }
                            >
                              No
                            </button>
                          </div>
                        </td>
                        <td>
                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              type="button"
                              class={
                                check1(item.paidClassEINR)
                                  ? "success_button"
                                  : "white_button"
                              }
                              disabled={check1(item.paidClassEINR)}
                              onClick={() =>
                                onUpdate(item._id, "paidClassEINR", true)
                              }
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              class={
                                check2(item.paidClassEINR)
                                  ? "danger_button"
                                  : "white_button"
                              }
                              disabled={check2(item.paidClassEINR)}
                              onClick={() =>
                                onUpdate(item._id, "paidClassEINR", false)
                              }
                            >
                              No
                            </button>
                          </div>
                        </td>
                        {item.classtype == "" ? (
                          <td>
                            <div
                              class="btn-group"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                type="button"
                                class={
                                  check1(item.trailCompleted)
                                    ? "success_button"
                                    : "white_button"
                                }
                                disabled={check1(item.trailCompleted)}
                                onClick={() =>
                                  onUpdate(item._id, "trailCompleted", true)
                                }
                              >
                                Yes
                              </button>
                              <button
                                type="button"
                                class={
                                  check2(item.trailCompleted)
                                    ? "danger_button"
                                    : "white_button"
                                }
                                disabled={check2(item.trailCompleted)}
                                onClick={() =>
                                  onUpdate(item._id, "trailCompleted", false)
                                }
                              >
                                No
                              </button>
                            </div>
                          </td>
                        ) : (
                          <td>NA</td>
                        )}
                        {item.classtype == "" ? (
                          <td>
                            <div
                              class="btn-group"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                type="button"
                                class={
                                  check1(item.trailConverted)
                                    ? "success_button"
                                    : "white_button"
                                }
                                disabled={check1(item.trailConverted)}
                                onClick={() =>
                                  onUpdate(item._id, "trailConverted", true)
                                }
                              >
                                Yes
                              </button>
                              <button
                                type="button"
                                class={
                                  check2(item.trailConverted)
                                    ? "danger_button"
                                    : "white_button"
                                }
                                disabled={check2(item.trailConverted)}
                                onClick={() =>
                                  onUpdate(item._id, "trailConverted", false)
                                }
                              >
                                No
                              </button>
                            </div>
                          </td>
                        ) : (
                          <td>NA</td>
                        )}
                        <td>
                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              type="button"
                              class={
                                check1(item.panalty)
                                  ? "success_button"
                                  : "white_button"
                              }
                              disabled={check1(item.panalty)}
                              onClick={() =>
                                onUpdate(item._id, "panalty", true)
                              }
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              class={
                                check2(item.panalty)
                                  ? "danger_button"
                                  : "white_button"
                              }
                              disabled={check2(item.panalty)}
                              onClick={() =>
                                onUpdate(item._id, "panalty", false)
                              }
                            >
                              No
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </Base>
  );
};

export default EarningEdit;

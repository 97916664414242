import React, { useEffect, useState } from "react";
import "../../styles/User.css";
import { useHistory } from "react-router-dom";
import { searchAlgo, findCountry } from "../../Utils/Helper.js";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import TkDropDown from "../../Common/Components/DropDown";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Feedback from "../student/StudentFeedback";
import Base from "../../Common/Components/Base";
import EditIcon from "@material-ui/icons/Edit";
import BackNavigation from "../../Common/Components/BackNavigation";
import { base_route } from "../../backend";
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";

const ProfileAccess = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [searchFeild, setSearchFeild] = useState(false);
	// const [clickedIndex, setclickedIndex] = useState(null)
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(25);
	const [currentRecords, setcurrentRecords] = useState([]);
	const [isFeedbackClicked, setIsFeedbackClicked] = useState(false);
	const [sortBy, setSortBy] = useState("All");
	const history = useHistory();

	let indexOfLastRecord = currentPage * recordsPerPage;
	let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

	useEffect(() => {
		setcurrentRecords(users.slice(indexOfFirstRecord, indexOfLastRecord));
	}, [currentPage, users]);

	useEffect(() => {
		searchFeild && search !== ""
			? setcurrentRecords(searchAlgo(search, users))
			: setcurrentRecords(users.slice(indexOfFirstRecord, indexOfLastRecord));
	}, [search, searchFeild]);

	useEffect(() => {
		console.log("useeffct");
		loadUsers();
	}, []);

	const onRecordPerPageClick = (numberOfRecord) => {
		setRecordsPerPage(numberOfRecord);
	};

	const handleClick = (type, item) => {
		if (type == "add") {
			history.push({
				pathname: "/dashboard/profile/access/add",
				state: { type: "add", data: [{ profile: "", access: [] }] },
			});
		} else {
			history.push({
				pathname: "/dashboard/profile/access/add",
				state: { type: "edit", data: item },
			});
		}
	};

	const handleDelete = (ID) => {
		fetch(`${base_route}/admin/profileAccess/delete`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ ID }),
		})
			.then((res) => res.json())
			.then((resp) => {
				if (resp.error) {
					alert(resp.error);
					return;
				}
				alert(resp.message);
				//history.push("/dashboard/setting/profile/access");
				loadUsers();
			})
			.catch((err) => console.log(err));
	};
	const loadUsers = async () => {
		console.log("load user");
		setLoading(true);
		await fetch(`${base_route}/admin/settings/profileAccess/all`, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		})
			.then((resp) => resp.json())
			.then((resp) => {
				console.log("resp", resp);
				setUsers(resp.reverse());
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};
	const handleOnRowClick = (index) => {
		history.push(`/dashboard/user/${index}`);
	};

	return (
		<div>
			<Base>
				<BackNavigation />
				<div className="registration_actions">
					<div
						className="registration_action_item"
						onClick={() => handleClick("add", {})}
					>
						<AddIcon style={{ color: "#36ff6b" }} />
						Add
					</div>
				</div>

				<div className="registration_tabel_action">
					<div className="d-flex">
						<span>Records per page</span>{" "}
						<TkDropDown
							style={{ width: "80px", height: "30px", marginLeft: "10px" }}
							_onClick={onRecordPerPageClick}
							value={recordsPerPage}
							items={["25", "50", "100", "150", "200"]}
						/>
					</div>
					<div className="d-flex ">
						<NavigateBeforeIcon
							onClick={() =>
								currentPage === 1 ? null : setCurrentPage(currentPage - 1)
							}
						/>
						{indexOfFirstRecord + 1} - {indexOfLastRecord} / {users.length}
						<NavigateNextIcon
							onClick={() =>
								indexOfLastRecord > users.length
									? null
									: setCurrentPage(currentPage + 1)
							}
						/>
						<div className="registration_action_item" onClick={loadUsers}>
							<RefreshIcon style={{ color: "#25a1e8" }} />
							Refresh
						</div>
					</div>
				</div>
				{/* <table id="users">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Profile</th>
              <th>Access</th>
              <th>Action</th>
            </tr>
          </thead>

          {loading || users.length < 0 ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            currentRecords.map((item, index) => (
              <tr
                key={item.uid}

                // className={index === clickedIndex && `selected_row`}
              >
                <td>{index + 1}</td>
                <td onClick={() => handleClick("edit", item)}>
                  {item.profile}
                </td>
                <td onClick={() => handleClick("edit", item)}>
                  {" "}
                  {item.access.map((item) => {
                    return item + ", ";
                  })}
                </td>
                <td>
                  <div className="d-flex">
                    <button
                      className="registration_action_item"
                      onClick={() => handleDelete(item._id)}
                      style={{ border: "none" }}
                      disabled={item.profile === "ADMIN" ? true : false}
                    >
                      <CloseIcon style={{ color: "#e82525" }} />
                      Delete
                    </button>
                    <div
                      className="registration_action_item"
                      onClick={() => handleClick("edit", item)}
                    >
                      <EditIcon style={{ color: "#36ff6b" }} />
                      Edit
                    </div>
                  </div>
                </td>
              </tr>
            ))
          )}
        </table> */}
				{loading || users.length < 0 ? (
					<div className="loader">
						<CircularProgress />
					</div>
				) : (
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>S. No.</TableCell>
									<TableCell align="center">Profile</TableCell>
									<TableCell align="center">Access</TableCell>
									<TableCell align="center">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{currentRecords.map((item, index) => (
									<TableRow key={item.uid}>
										<TableCell component="th" scope="row">
											{index + 1}
										</TableCell>
										<TableCell align="center">{item.profile}</TableCell>
										<TableCell align="center">
											{item.access.map((item) => {
												return item + ", ";
											})}
										</TableCell>
										<TableCell style={{ placeItems: "center" }}>
											<Button
												size="small"
												onClick={() => handleClick("edit", item)}
												style={{ color: "white" }}
												variant="contained"
												color="primary"
											>
												Edit
											</Button>
											<Button
												size="small"
												onClick={() => handleDelete(item._id)}
												variant="outlined"
												style={{ marginTop: "1rem" }}
												color="secondary"
											>
												Delete
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Base>
		</div>
	);
};

export default ProfileAccess;

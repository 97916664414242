import React, { Component, useEffect, useState } from "react";
import Base from "../Common/Components/Base";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/coupon.css";
import { base_route } from "../backend";

const PaymentCoupon = () => {
  const [code, setCode] = useState("");
  const [amount, setAmount] = useState(0);
  const [plan, setPlan] = useState("All plans");
  const [date, setDate] = useState(new Date());
  const [limit, setLimit] = useState(0);
  const [type, setType] = useState("Percentage");
  const [coupon, setCoupon] = useState([]);
  const [render, setRender] = useState(0);

  const onAdd = (e) => {
    e.preventDefault();
    if (!code) {
      toast("Add code please");
      return;
    }
    if (limit == 0) {
      toast("Usage can'nt be Zero");
      return;
    }
    if (amount == 0) {
      toast("Amount can'nt be Zero");
      return;
    }
    if (type == "Percentage" && amount > 100) {
      toast("Percantage must be less than 100");
      return;
    }

    fetch(`${base_route}/payment/addcoupon`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        amount,
        type,
        limit,
        plan,
        type,
        date,
      }),
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.error) {
          toast(resp.error);
        } else {
          setCode("");
          setAmount(0);
          setPlan("Beginner");
          setLimit(0);
          setType("Percentage");
          setDate(new Date());
          toast(resp.message);
          setRender((prev) => prev + 1);
        }
      })
      .catch((err) => console.log(err));

    //	console.log(code, amount, plan, date, limit, type);
  };

  useEffect(async () => {
    await fetch(`${base_route}/payment/getcoupon`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log(resp);
        setCoupon(resp);
      })
      .catch((err) => console.log(err));
  }, [render]);
  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    return new Date(secondsSinceEpoch * 1000).toDateString().substring(4, 15);
  };

  const Action = async (ID, action) => {
    await fetch(`${base_route}/payment/updatecoupon`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ID,
        action,
      }),
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.error) {
          toast(resp.error);
          return;
        }
        toast(resp.message);
        setRender((prev) => prev + 1);
        return;
      })
      .catch((err) => console.log(err));
  };
  return (
    <Base>
      <div className="coupon_input">
        <form>
          <div class="form-group row">
            <label for="exampleInputEmail1" class="col-sm-2 col-form-label">
              Coupon Code
            </label>
            <div class="col-xs-4">
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                class="form-control"
                placeholder="Enter Coupon"
              ></input>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="exampleFormControlSelect1"
              class="col-sm-2 col-form-label"
            >
              Plan Name
            </label>
            <div class="col-xs-4">
              <select
                class="form-control"
                value={plan}
                onChange={(e) => setPlan(e.target.value)}
                id="exampleFormControlSelect1"
              >
                <option>Advanced</option>
                <option>Intermediate</option>
                <option>Master</option>
                <option>All plans</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="exampleInputEmail1" class="col-sm-2 col-form-label">
              Coupon Amount
            </label>
            <div class="col-xs-4">
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder=""
              ></input>
              <select
                class="form-control"
                value={type}
                onChange={(e) => setType(e.target.value)}
                id="exampleFormControlSelect1"
              >
                <option>Percentage</option>
                <option>Amount</option>
              </select>
            </div>
          </div>
          <ToastContainer />
          <div class="form-group row">
            <label for="exampleInputPassword1" class="col-sm-2 col-form-label">
              Coupon Expire Date
            </label>
            <div class="col-xs-4">
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Coupon Expire Date"
              ></input>
            </div>
          </div>
          <div class="form-group row">
            <label for="exampleInputPassword1" class="col-sm-2 col-form-label">
              Usage Limit
            </label>
            <div class="col-xs-4">
              <input
                type="number"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Usage Limit"
              ></input>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-primary"
            onClick={(e) => onAdd(e)}
          >
            Add Coupon
          </button>
        </form>
        <hr></hr>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Sno</th>
              <th scope="col">Date</th>
              <th scope="col">Coupon Name</th>
              <th scope="col">Discount</th>
              <th scope="col">Plan</th>
              <th scope="col">Usage Limit</th>
              <th scope="col">Used</th>
              <th scope="col">Expire</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {coupon.map((item, index) => (
              <tr>
                <th scope="col">{index + 1}</th>
                <th scope="col">{getRegDate(item._id)}</th>
                <th scope="col">{item.name}</th>
                {item.coupontype == "Percentage" ? (
                  <th scope="col">{`${item.amount}%`}</th>
                ) : (
                  <th scope="col">{`Rs ${item.amount}`}</th>
                )}

                <th scope="col">{item.plan}</th>
                <th scope="col">{item.usagelimit}</th>
                <th scope="col">{item.used}</th>
                <th scope="col">{item.expire}</th>
                <th scope="col">{item.status}</th>
                <th scope="col">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => Action(item._id, "Draft")}
                    >
                      Draft
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => Action(item._id, "Publish")}
                    >
                      Publish
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => Action(item._id, "Stop")}
                    >
                      Stop
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => Action(item._id, "Delete")}
                    >
                      Delete
                    </button>
                  </div>
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Base>
  );
};

export default PaymentCoupon;

import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory, useLocation } from "react-router";
import "../../styles/slots.css";
import "./styles/t-slotTime.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import TkDropDown from "../../Common/Components/DropDown";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Base from "../../Common/Components/Base";
import SearchIcon from "@material-ui/icons/Search";
import { base_route } from "../../backend";
import BackNavigation from "../../Common/Components/BackNavigation";
import { Dropdown, DropdownButton } from "react-bootstrap";

const SlotTime = () => {
	const history = useHistory();
	const location = useLocation();
	const [teacherID, setTeacherID] = useState(location.state.teacherID);
	const [teacherName, setTeacherName] = useState(location.state.teacherName);
	const [teachers, setTeachers] = useState(location.state.teachers);
	const [teachersOBJ, setTeachersOBJ] = useState(location.state.teachersOBJ);
	const [rdate, setRdate] = useState(location.state.rdate);
	const time = location.state.time;
	const [modalOpen, setmodalOpen] = useState(false);
	const [modalData, setModalData] = useState({
		name: "",
		grade: "",
		myCourses: [],
	});

	const [Grade, setGrade] = useState("Grade");
	const [values, setValues] = useState({
		studentName: "",
		parentName: "",
		remark: "",
		studentEmail: "",
	});

	const [teacher, setTeacher] = useState();
	const [student, setStudent] = useState();
	const [r, setR] = useState(0);
	const { studentName, parentName, remark, studentEmail } = values;

	const onChange = (field) => (event) => {
		console.log("called");
		setValues({ ...values, [field]: event.target.value });
	};
	const [status, setStatus] = useState("Status");
	const handleGradeClick = (grade) => {
		setGrade(grade);
	};
	const handleStatusClick = (grade) => {
		setStatus(grade);
	};
	const onSubmit = () => {
		if (!studentEmail) {
			alert("Student Email Required");
			return;
		}
		if (!studentName || !parentName || !studentEmail) {
			alert("Enter All the fields");
			return;
		}
		// console.log(studentEmail, studentName);
		const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
		fetch(`${base_route}/admin/t-slots/bookSlot`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				Rslot: time.date,
				studentName: studentName,
				parentName: parentName,
				remark: remark,
				grade: Grade,
				studentEmail: studentEmail,
				ID: modalData._id,
				personID: personID,
				status: status,
			}),
		})
			.then((res) => res.json())
			.then(async (result) => {
				if (result.error) {
					alert(result.error);
				} else {
					setmodalOpen(false);
					window.location.reload();
				}
			});
	};
	const handleClose = () => {
		setmodalOpen(false);
	};

	const onRowClick = (data) => {
		setModalData(data);
		setmodalOpen(true);
	};
	const useStyles = makeStyles({
		paperFullWidth: {
			overflowY: "visible",
		},
		dialogContentRoot: {
			overflowY: "visible",
		},
	});
	const classes = useStyles();
	const applyCss = (teacher) => {
		var countA = 0;
		var countB = 0;
		for (var i = 0; i < teacher.length; i++) {
			if (teacher[i].booked) countB += 1;
			else countA += 1;
		}
		if (countB == teacher.length) return "violate";
		else return "available";
	};
	useEffect(() => {
		const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
		const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
		async function fetchData() {
			await fetch(`${base_route}/admin/t-slots/slotbyTime`, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					Rslot: time.date,
					role: role,
					personID: personID,
				}),
			})
				.then((res) => res.json())
				.then(async (result) => {
					if (!result) {
						alert("Error While fetching Details");
					} else {
						//console.log("teacher", result);
						setTeacher(result.teacher);
						setStudent(result.student);
					}
				});
		}
		fetchData();
	}, []);
	const fetchDetails = async (email) => {
		console.log("email", email);
		await fetch(`${base_route}/booking/info`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ email }),
		})
			.then((res) => res.json())
			.then((result) => {
				if (result) {
					setValues({
						...values,
						["studentName"]: result.studentName,
						["parentName"]: result.parentName,
						["studentEmail"]: result.studentEmail,
					});
					setGrade(result.grade);
					setStatus(result.status);
				}
			});
	};
	const style = {
		height: 30,
		width: 30,
		marginTop: 30,
		cursor: "pointer",
	};
	let items = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
	let statuses = ["New", "Half Done", "Cancelled"];

	return (
		<Base>
			<div className="t-slotTime container">
				<ArrowBackIcon
					style={style}
					onClick={() => {
						history.push({
							pathname: "/dashboard/t-slot",
							state: {
								rdate: rdate,
								teacherName: teacherName,
								teacherID: teacherID,
								teachersOBJ: teachersOBJ,
								teachers: teachers,
							},
						});
					}}
				/>
				{teacher === undefined ? (
					<div style={{ position: "fixed", top: "50%", left: "50%" }}>
						<CircularProgress size={150} />
					</div>
				) : (
					<div className="mt-5 slot_info">
						<div className={`slot ${applyCss(teacher)}`}>
							<div className="slot_available_number">{teacher.length}</div>
							{time.date}
						</div>
						<table className="slot_table mt-5">
							{teacher.map((item) => (
								<tr onClick={() => !item.booked && onRowClick(item.teacherID)}>
									<td>{item.teacherID.name}</td>
									<td>{item.teacherID.grade}</td>
									{item.booked ? <td>{item.studentEmail}</td> : <></>}
									<td>
										{item.teacherID.myCourses.map((item) => (
											<h6>{item}</h6>
										))}
									</td>
								</tr>
							))}
						</table>
						<Dialog
							onClose={handleClose}
							className="slot_modal"
							maxWidth="md"
							aria-labelledby="simple-dialog-title"
							open={modalOpen}
							classes={{
								paperFullWidth: classes.paperFullWidth,
							}}
						>
							<CloseIcon
								className="slot_close"
								onClick={() => setmodalOpen(false)}
							/>

							<DialogContent
								classes={{
									root: classes.dialogContentRoot,
								}}
							>
								<table className="slot_table ">
									<tr>
										<td>Teacher : {modalData.name}</td>
									</tr>
								</table>
								<form>
									<div class="slot_form_group d-flex flex-column">
										<label>Student’s Email </label>
										<Autocomplete
											id="combo-box-demo"
											options={student}
											onChange={(event, newValue) => {
												fetchDetails(newValue.studentEmail);
											}}
											getOptionLabel={(option) => option.studentEmail}
											style={{ width: 300 }}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Enter Email"
													variant="outlined"
												/>
											)}
										/>
									</div>
									<div class="slot_form_group d-flex flex-column">
										<label>Student’s Name</label>
										<input
											type="text"
											value={studentName}
											placeholder="Student’s Full Name"
											onChange={onChange("studentName")}
										/>
									</div>
									<div class="slot_form_group d-flex flex-column">
										<label>Parent’s Name</label>
										<input
											type="text"
											value={parentName}
											placeholder="Parent’s Full Name"
											onChange={onChange("parentName")}
										/>
									</div>
									<div className="d-flex slot_form_group ">
										<div className="mr-2">
											{/* <TkDropDown
                        label="Grade"
                        style={{ width: "100px" }}
                        _onClick={handleGradeClick}
                        value={Grade}
                        items={[
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "10",
                          "11",
                          "12",
                        ]}
                      /> */}
											<label className="mr-2">Grade</label>

											<DropdownButton
												id="dropdown-basic-button"
												variant="light"
												title={`${Grade}`}
											>
												{items.map((item, index) => {
													return (
														<Dropdown.Item
															href="#"
															onClick={() => handleGradeClick(items[index])}
														>
															{item}
														</Dropdown.Item>
													);
												})}
											</DropdownButton>
										</div>
										{/* <TkDropDown
                      label="Status"
                      style={{ width: "100px" }}
                      _onClick={handleStatusClick}
                      value={status}
                      items={["New", "Half Done", "Cancelled"]}
                    /> */}
										<div>
											<label className="mr-2">Status</label>

											<DropdownButton
												id="dropdown-basic-button"
												variant="light"
												title={`${status}`}
											>
												{statuses.map((item, index) => {
													return (
														<Dropdown.Item
															href="#"
															onClick={() => handleStatusClick(statuses[index])}
														>
															{item}
														</Dropdown.Item>
													);
												})}
											</DropdownButton>
										</div>
										<div class="slot_form_group remark d-flex flex-column">
											<label>Remark</label>
											<input
												type="text"
												value={remark}
												onChange={onChange("remark")}
												placeholder="Remark"
											/>
										</div>
									</div>
								</form>
								<div class="slot_form_group ">
									<button
										className="btn btn-success"
										onClick={() => onSubmit()}
									>
										Save
									</button>
								</div>
							</DialogContent>
						</Dialog>
					</div>
				)}
			</div>
		</Base>
	);
};

export default SlotTime;

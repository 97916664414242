import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Base from "../../../Common/Components/Base";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 400,
    background: "#ffd400",
    color: "white",
  },
});

const StudentTrialBooked = ({ data }) => {
  const history = useHistory();

  const onClick = () => {
    history.push({
      pathname: "/dashboard/s-booking/trialBooked",
    });
  };

  const classes = useStyles();

  return (
    <div onClick={() => onClick()} style={{ cursor: "pointer" }}>
      <Card variant="contained" className={classes.card}>
        <CardContent>
          <>
            <Typography>Trial Booked</Typography>
            <Typography variant="h2" align="center">
              {data.bookedTrail}
            </Typography>
          </>
        </CardContent>
      </Card>
    </div>
  );
};

export default StudentTrialBooked;

import React, { useEffect, useState, useRef } from "react";
import { base_route } from "../../../backend";
import { searchAlgo, findCountry } from "../../../Utils/Helper.js";
import BackNavigation from "../../../Common/Components/BackNavigation";
import Base from "../../../Common/Components/Base";
import { CircularProgress } from "@material-ui/core";
import { Header } from "./../components/Header";
import { useHistory } from "react-router";
import "./../../../styles/common/table.css";
import StatusModal from "../../../Common/Components/StatusModal";

export const TrialBooked = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });
  const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  useEffect(() => {
    // to show limited records
    setCurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage, students]);
  useEffect(() => {
    fetchNoLaptop();
  }, []);
  const fetchNoLaptop = async () => {
    setLoading(true);
    let URL;
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    URL = `${base_route}/admin/s-booking/trialBooked?role=${role}&personID=${personID}`;
    const response = await fetch(URL, {
      method: "GET",
    });
    const res = await response.json();
    setStudents(res.reverse());
    setLoading(false);
  };
  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };
  const handleDelete = async (type, ID) => {
    fetch(`${base_route}/admin/s-booking/deleteData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type, ID }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        alert(res.error);
        if (res.error) {
          alert(res.error);
          return;
        } else {
          alert(res.message);
          return;
        }
      });
  };
  const handleCancelSlot = (ID) => {
    fetch(`${base_route}/admin/s-booking/updateSlotStatus`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: ID }),
    }).then((res) => {
      res.json().then((res) => {
        if (res.error) {
          setStatusModal({
            ...statusModal,
            showStatusModal: true,
            status: "error",
            statusText: res.error,
          });
        } else {
          setStatusModal({
            ...statusModal,
            showStatusModal: true,
            status: "success",
            statusText: res.message,
          });
        }
      });
    });
  };
  return (
    <Base>
      <div className="s-bookingTrialBooked">
        <BackNavigation />
        <Header
          setSearchFeild={setSearchFeild}
          searchFeild={searchFeild}
          search={search}
          setSearch={setSearch}
          recordsPerPage={recordsPerPage}
          onRecordPerPageClick={onRecordPerPageClick}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          indexOfFirstRecord={indexOfFirstRecord}
          indexOfLastRecord={indexOfLastRecord}
          data={students}
          title={"Trial Booked"}
        />
      </div>
      <div className="mainTable">
        <table id="customers">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Trial Date</th>
              <th>Teacher's Name</th>
              <th>Parent's Name</th>
              <th>Student's Name</th>
              <th>Grade</th>
              <th>Country</th>
              <th>Phone No.</th>
              <th>Email</th>
              {role == "ADMIN" && <th>Delete</th>}
              <th>Cancel Slot</th>
            </tr>
          </thead>

          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            currentRecords.map((item, index) => {
              return (
                <tr
                  key={item.uid}
                  className={index === clickedIndex && `selected_row`}
                >
                  <td>{indexOfFirstRecord + index + 1}</td>
                  <td>{item.slot}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.studentName}</td>
                  <td>{item.parentName}</td>
                  <td>{item.grade}</td>
                  <td>{findCountry(`+${item.parentPhone.countryCode}`)}</td>
                  <td>{item.parentPhone.number}</td>
                  <td>{item.studentEmail}</td>
                  {role === "ADMIN" && (
                    <td>
                      <button
                        className="delete_buttton"
                        onClick={() => handleDelete("bookedTrail", item._id)}
                      >
                        Delete
                      </button>
                    </td>
                  )}
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={() => handleCancelSlot(item._id)}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </table>
      </div>
      <StatusModal
        showModal={statusModal.showStatusModal}
        text={statusModal.statusText}
        status={statusModal.status}
        closeModal={() => {
          setStatusModal({ ...statusModal, showStatusModal: false });
        }}
      />
    </Base>
  );
};

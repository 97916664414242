import React, { Component } from "react";
import SidebarComponent from "../Common/Components/Sidebar";
import PrivateRoute from "../Common/Components/PrivateRoute";
import CurriculumComponent from "../components/student/StudentCurriculum";
import StudentManagerComponent from "./Manager/StudentManager";
import TeacherManagerComponent from "./Manager/TeacherManager";
import StudentComponent from "../components/student/StudentRegistration";
import TeacherComponent from "./T-Registration/TeacherRegistration";
import SalesComponent from "./Sales";
import { Route, Switch, Link, BrowserRouter as Router } from "react-router-dom";
import ReminderComponent from "./Reminder";
import SettingComponent from "./Setting";
import TicketComponent from "./Ticket";
import ProjectComponent from "./Project";
import NotificationComponent from "./Notification";
import FuncComponent from "./func";
import NotFoundComponent from "./NotFound";
import UserComponent from "./User";
import ProfileAccess from "./ProfileAccessCom/ProfileAccess";
import UserInfoComponent from "./UserInfo";
import ProfileEdit from "./ProfileAccessCom/ProfileAccessEdit";
import AddVideo from "../components/student/StudentFeeVideo";
import Slot from "./T-Slot/TeacherSlot";
import Login from "./Login/Login";
import SlotTime from "./T-Slot/TeacherSlotTime";
import BookingsInfo from "./S-Booking/StudentBookingInfo";
import LoginWithOTP from "./Login/LoginWithOTP";
import ResetPassword from "./Login/ResetPassword";
import Booking from "./S-Booking/StudentBooking";
import StudentInfo from "../components/student/StudentInfo";
import Welcome from "./Dashboard";
import TeacherCurriculum from "./T-Curriculum/TeacherCurriculum";
import ReferEarn from "./ReferEarn/ReferEarn";
import ReferEarnIndividual from "./ReferEarn/ReferEarnIndividual";
import PaymentCoupon from "./Coupon";
import Earning from "./T-Earning/TeacherEarning";
import EarningEdit from "./T-Earning/TeacherEarningEdit";
import Leave from "./T-Leave/TeacherLeave";
import OldStudentInfo from "./os-registration/OldStudentInfo";
import FeedbackOnPaidStudent from "./FeedbackOnPaidStudent";
import Blog from "./Blog/newBlog";
import Display from "./Blog/display";

import TableContent from "./TableContent";

class HomeComponent extends Component {
  state = {};
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/login-with-otp" exact component={LoginWithOTP} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <PrivateRoute path="/" exact component={Welcome} />

          <PrivateRoute path="/dashboard/booking" exact component={Booking} />

          <PrivateRoute
            path="/dashboard/earning/edit/"
            exact
            component={EarningEdit}
          />

          <PrivateRoute path="/dashboard/earnings" exact component={Earning} />
          <PrivateRoute
            path="/dashboard/paymentcoupon"
            exact
            component={PaymentCoupon}
          />
          <PrivateRoute path="/dashboard/leave" exact component={Leave} />
          <PrivateRoute
            path="/dashboard/referearn"
            exact
            component={ReferEarn}
          />
          <PrivateRoute
            path="/dashboard/referearn/individual"
            exact
            component={ReferEarnIndividual}
          />
          <PrivateRoute
            path="/dashboard/teachercurriculum"
            exact
            component={TeacherCurriculum}
          />
          <PrivateRoute
            path="/dashboard/booking/info"
            exact
            component={BookingsInfo}
          />
          <PrivateRoute
            path="/dashboard/oldstudent/add"
            exact
            component={OldStudentInfo}
          />
          <PrivateRoute
            path="/booking/student/info"
            exact
            component={StudentInfo}
          />
          <PrivateRoute
            path="/booking/student/info/feedback"
            exact
            component={FeedbackOnPaidStudent}
          />
          <PrivateRoute
            path="/dashboard/curriculum"
            component={CurriculumComponent}
          />
          <PrivateRoute
            path="/dashboard/student"
            exact
            component={StudentComponent}
          />
          <PrivateRoute
            path="/dashboard/sales"
            exact
            component={SalesComponent}
          />
          <PrivateRoute
            path="/dashboard/teacher"
            exact
            component={TeacherComponent}
          />
          <PrivateRoute
            path="/dashboard/setting"
            exact
            component={SettingComponent}
          />
          <PrivateRoute
            path="/dashboard/setting/user"
            exact
            component={UserComponent}
          />
          <PrivateRoute
            path="/dashboard/setting/user/action"
            exact
            component={UserInfoComponent}
          />

          <PrivateRoute
            path="/dashboard/reminder"
            exact
            component={ReminderComponent}
          />
          <PrivateRoute
            path="/dashboard/tickets"
            exact
            component={TicketComponent}
          />
          <PrivateRoute
            path="/dashboard/projects"
            exact
            component={ProjectComponent}
          />
          <PrivateRoute
            path="/dashboard/free-video"
            exact
            component={AddVideo}
          />
          <PrivateRoute path="/dashboard/slots" exact component={Slot} />
          <PrivateRoute
            path="/dashboard/slots/time"
            exact
            component={SlotTime}
          />

          <PrivateRoute
            path="/dashboard/manage-student"
            exact
            component={StudentManagerComponent}
          />

          <PrivateRoute
            path="/dashboard/notification"
            exact
            component={NotificationComponent}
          />
          <PrivateRoute
            path="/dashboard/student/:user"
            exact
            component={StudentManagerComponent}
          />
          <PrivateRoute
            path="/dashboard/teacher/:teacher"
            exact
            component={TeacherManagerComponent}
          />
          <PrivateRoute
            path="/dashboard/setting/profile/access"
            exact
            component={ProfileAccess}
          />

          <PrivateRoute
            path="/dashboard/setting/profile/access/add"
            exact
            component={ProfileEdit}
          />
          <PrivateRoute path="/dashboard/blog" exact component={Display} />
          <PrivateRoute
            path="/dashboard/blog/createblog"
            exact
            component={Blog}
          />
          <PrivateRoute
            path="/dashboard/blog/editblog/:id"
            exact
            component={Blog}
          />
          <PrivateRoute
            path="/dashboard/Payment"
            exact
            component={TableContent}
          />
        </Switch>
      </Router>
    );
  }
}

export default HomeComponent;

import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DropdownButton, Dropdown } from "react-bootstrap";

// import "../../../styles/dropdown.css";
import "../../styles/dropdown.css";

const TkDropDown = ({
  label,
  value,
  placeholder,
  items,
  _onClick,
  style,
  variant,
  mainClass,
}) => {
  // const [btnText, setBtnText] = useState("")
  return (
    <div className={`tk_dropdown ${mainClass}`}>
      <label className="mr-2">{label}</label>
      <DropdownButton
        id="dropdown-basic-button"
        variant={variant ? variant : "secondary"}
        title={value ? value : label}
      >
        <div
          style={{
            overflowY: "auto",
            maxHeight: "200px",
            zIndex: 101,
          }}
        >
          {items.map((item, index) => {
            return (
              // <span class="dropdown-item" onClick={() => _onClick(item)}>
              <Dropdown.Item href="#" onClick={() => _onClick(item)}>
                {item}
              </Dropdown.Item>
            );
          })}
        </div>
      </DropdownButton>
    </div>
  );
};

export default TkDropDown;

import React, { useEffect, useState } from "react";
import Base from "../Common/Components/Base";
import "../styles/setting.css";
import { Link } from "react-router-dom";
import fbImg from "../Assests/Social/fb.png";
import instaImg from "../Assests/Social/insta.png";
import linkedinImg from "../Assests/Social/linkedin.png";
import twittImg from "../Assests/Social/twitt.png";
import ytImg from "../Assests/Social/yt.png";
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ReactComponent as Logo } from "../Assests/logout.svg";
export const openInNewTab = (url) => {
  window.open(url, "_blank");
};

const SettingComponent = ({ allowedRoutes }) => {
  // console.log(allowedRoutes)
  const routes = allowedRoutes;
  const [isUserAccess, setIsUserAccess] = useState(false);
  const [isProfileAccess, setIsProfileAccess] = useState(false);
  const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
  console.log(role);
  const settingsData = [
    {
      title: "Profile",
      link: "/dashboard/setting/profile",
    },

    {
      title: "Change Password",
      link: "/dashboard/setting/changepassword",
    },
    {
      title: "Contact Us",
      link: "/dashboard/setting/contactus",
    },
    {
      title: "Tech Support",
      link: "/dashboard/setting/support/tech",
    },
    {
      title: "Support Ticket",
      link: "/dashboard/setting/support/ticket",
    },
  ];
  // {
  //   title: 'User',
  //   link: '/dashboard/user',
  // },
  // {
  //   title: 'Profile Access',
  //   link: '/dashboard/profile/access',
  // },

  // allowedRoutes.map(({ title }) => {
  //   if (title === 'User')
  //     return settingsData.unshift({
  //       title: 'User',
  //       link: '/dashboard/user',
  //     })
  //   if (title === 'Profile Access')
  //     return settingsData.unshift({
  //       title: 'Profile Access',
  //       link: '/dashboard/profile/access',
  //     })
  // })

  // for (const route of routes) {
  //   if (route.title === 'User') setIsUserAccess(true)
  //   if (route.title === 'Profile Access') setIsProfileAccess(true)
  // }

  //   useEffect(() => {
  //     setSettingsData([
  //    {
  //      title: 'User',
  //      link: '/setting/user',
  //      },
  //       {
  //         title: 'Profile',
  //         link: '/setting/profile',
  //       },
  //       {
  //         title: 'Change Password',
  //         link: '/setting/change/password',
  //       },
  //       {
  //         title: 'Contact Us',
  //         link: '/setting/contactus',
  //       },
  //       {
  //         title: 'Tech Support',
  //         link: '/setting/support/tech',
  //       },
  //       {
  //         title: 'Support Ticket',
  //         link: '/setting/support/ticket',
  //       },
  //     ],[settingsData])
  //   })
  let history = useHistory();
  const logoutHandler = (next) => {
    if (typeof window != "undefined") {
      sessionStorage.removeItem("tk_jwt");
      next();
    }
  };

  // const SettingItem = ({ item }) => {
  //   return (
  //     <>
  //       <Link className='setting_item' to={item.link}>
  //         <span>{item.title}</span>
  //         <ChevronRightIcon></ChevronRightIcon>
  //       </Link>
  //     </>
  //   )
  // }

  return (
    <Base>
      <div className="setting_section  w_85 mx-auto  ml-3">
        <ul>
          {role == "ADMIN" && (
            <div>
              <li>
                <Link className="setting_item" to={"/dashboard/user"}>
                  <span>User</span>
                  <ChevronRightIcon></ChevronRightIcon>
                </Link>
              </li>

              <li>
                <Link className="setting_item" to={"/dashboard/profile/access"}>
                  <span>Profile Access</span>
                  <ChevronRightIcon></ChevronRightIcon>
                </Link>
              </li>
            </div>
          )}

          <li>
            <Link className="setting_item" to={"/dashboard/setting/profile"}>
              <span>Profile</span>
              <ChevronRightIcon></ChevronRightIcon>
            </Link>
          </li>

          <li>
            <Link
              className="setting_item"
              to={"/dashboard/setting/changepassword"}
            >
              <span>Change Password</span>
              <ChevronRightIcon></ChevronRightIcon>
            </Link>
          </li>

          <li>
            <Link className="setting_item" to={"/dashboard/setting/contactus"}>
              <span>Contact Us</span>
              <ChevronRightIcon></ChevronRightIcon>
            </Link>
          </li>

          <li>
            <Link
              className="setting_item"
              to={"/dashboard/setting/support/tech"}
            >
              <span>Tech Support</span>
              <ChevronRightIcon></ChevronRightIcon>
            </Link>
          </li>

          <li>
            <Link
              className="setting_item"
              to={"/dashboard/setting/support/ticket"}
            >
              <span>Support Ticket</span>
              <ChevronRightIcon></ChevronRightIcon>
            </Link>
          </li>

          <li>
            <button
              className="logout_btn"
              onClick={() => logoutHandler(() => history.push("/"))}
            >
              <span className="logout_icon">
                <Logo color="white" />
              </span>
              <span>Logout</span>
            </button>
          </li>
        </ul>
        <span>
          <b> Follow Us On</b>
        </span>
        <div className="setting_social_icon mt-3">
          <img
            src={fbImg}
            alt="Facebook"
            onClick={() => openInNewTab("https://www.facebook.com/TechoKids/")}
          />

          <img
            src={instaImg}
            alt="Instagram"
            onClick={() => openInNewTab("https://www.instagram.com/techokids/")}
          />
          <img
            src={linkedinImg}
            alt="Linkedin"
            onClick={() =>
              openInNewTab("https://www.linkedin.com/company/techokids/")
            }
          />
          <img
            src={twittImg}
            alt="Twitter"
            onClick={() => openInNewTab("https://twitter.com/TechoKids/")}
          />
          <img
            src={ytImg}
            alt="Youtube"
            onClick={() =>
              openInNewTab(
                "https://www.youtube.com/channel/UCIQ3U-ea7I3oOA6a8dnvIcQ/"
              )
            }
          />
        </div>
      </div>
    </Base>
  );
};

export default SettingComponent;

import React, { useEffect, useRef, useState } from "react";
import Base from "../Common/Components/Base";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { base_route } from "../backend";
import "../styles/slots.css";
import TkDropDown from "../Common/Components/DropDown";

const PaidSlot = () => {
	const [date, setDate] = useState(new Date());
	const [slots, setSlots] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [teachersOBJ, setTeachersOBJ] = useState([]);
	const [teacherName, setTeacherName] = useState();
	const [teacherID, setTeacherID] = useState();

	useEffect(() => {
		console.log(teacherID);
		async function fetchData() {
			await fetch(`${base_route}/slot/getpaidclasses`, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"bearer-token": JSON.parse(sessionStorage.getItem("tk_jwt")).token,
				},
				body: JSON.stringify({
					date: new Date(date).toDateString(),
					teacherID: teacherID,
				}),
			})
				.then((res) => res.json())
				.then(async (result) => {
					if (!result) {
						alert("Error While fetching Details");
					} else {
						console.log(result, "pslot");
						setSlots(result.result);
						let currentTeachers = result.teachers;
						let teacherNames = [];
						currentTeachers.forEach((teacher) => {
							if (!teacherNames.includes(teacher.name)) {
								teacherNames.push(teacher.name);
							}
						});
						if (teacherNames.length > 2) {
							setTeachers(teacherNames);
							setTeachersOBJ(currentTeachers);
						}
						if (teachers.length == 0) {
							setTeachers(teacherNames);
							setTeachersOBJ(currentTeachers);
						}
					}
				});
		}
		fetchData();
	}, [date, teacherID]);
	const handleTeacherClick = (teacher) => {
		console.log(teacher);
		setTeacherName(teacher);
		teachersOBJ.forEach((c) => {
			if (c.name == teacher) {
				console.log(c);
				setTeacherID(c.id);
			}
		});
	};
	return (
		<Base>
			{!slots ? (
				<div style={{ position: "fixed", top: "50%", left: "50%" }}>
					<CircularProgress size={150} />
				</div>
			) : (
				<div className=" mr-5">
					<div className="d-flex justify-content-between align-items-center">
						<div className="slot_today_info d-flex flex-column">
							<h2>
								<ArrowBackIcon
									className="slot_date_controller "
									onClick={() => {
										console.log(date);
										setDate(new Date(new Date().setDate(date.getDate() - 1)));
									}}
								></ArrowBackIcon>{" "}
								{new Date(date).toDateString()}{" "}
								<ArrowForwardIcon
									className="slot_date_controller "
									onClick={() => {
										setDate(new Date(new Date().setDate(date.getDate() + 1)));
									}}
								></ArrowForwardIcon>
							</h2>
							<div className="d-flex justify-content-between">
								<div
									className="slot_today_booked d-flex align-items-center py-2 flex-column text-white mt-1 mb-2"
									//   onClick={() => showBooked(date)}
								>
									<p>Booked</p>
									<h2>{slots.length}</h2>
								</div>
							</div>
						</div>
						<div className="m-2 mt-4">
							<TkDropDown
								variant="info"
								label="Teachers"
								style={{ width: "100px" }}
								_onClick={handleTeacherClick}
								value={teacherName}
								items={teachers}
							/>
						</div>
						<div className="slot_calendar pt-5">
							<input
								style={{ background: "#999999" }}
								type="date"
								value={date}
								onChange={(e) => setDate(e.target.value)}
							></input>
						</div>
					</div>
					{!slots.length ? (
						<h5 style={{ textAlign: "center" }}>
							No paid classes scheduled on today
						</h5>
					) : (
						<table class="table table-striped">
							<thead className="thead-dark">
								<tr>
									<th scope="col">Sno</th>
									<th scope="col">Teacher Email</th>
									<th scope="col">Student Name</th>
									<th scope="col">Student EmailID</th>
									<th scope="col">Slot Time</th>
								</tr>
							</thead>
							<tbody>
								{slots.map((item, index) => (
									<tr>
										<th scope="row">{index + 1}</th>
										<td>{item.teacherEmail}</td>
										<td>{item.studentName}</td>

										<td>{item.studentEmail}</td>

										<td>{item.slot.slice(16)}</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			)}
		</Base>
	);
};

export default PaidSlot;

import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import { base_route } from "../../../backend";
import { searchAlgo, findCountry } from "../../../Utils/Helper.js";
import BackNavigation from "../../../Common/Components/BackNavigation";
import Base from "../../../Common/Components/Base";
import TkDropDown from "../../../Common/Components/DropDown";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import { Header } from "./../components/Header";
import "./../styles/new.css";
import AlertBox from "../../../Common/Components/AlertBox";

export const New = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [search, setSearch] = useState("");
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [currentRecords, setCurrentRecords] = useState([]);
  const history = useHistory();
  const [alertBox, setAlertBox] = useState(false);
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const [isAddUserClicked, setIsAddUserClicked] = useState(false);
  const [isEditBookingBtnClicked, setIsEditBookingBtnClicked] = useState(false);
  const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
  const [newUser, setNewUser] = useState({
    name: "",
    parentName: "",
    grade: "",
    number: "91",
    studentEmail: "",
    bookingDate: "",
    bookingTime: "",
  });
  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  useEffect(() => {
    // to show limited records
    setCurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage, students]);
  useEffect(() => {
    fetchNewBookings();
  }, []);

  const fetchNewBookings = async () => {
    setLoading(true);
    let URL;
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    URL = `${base_route}/admin/s-booking/newBookings?role=${role}&personID=${personID}`;
    const response = await fetch(URL, {
      method: "GET",
    });
    const res = await response.json();
    setStudents(res.reverse());
    setLoading(false);
  };

  const onUpload = async () => {
    if (!file) {
      // alert("Select .csv file");
      setMessage("Select .csv file");
      setType("error");
      setAlertBox(true);
      return;
    }
    const data = new FormData();
    data.append("file", file);
    data.append("type", "newbooking");
    await fetch(`${base_route}/booking/upload`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.error) {
          // alert(resp.error);
          setMessage(resp.error);
          setType("error");
          setAlertBox(true);
        } else {
          setFile(null);
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  };
  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };
  const handleClose = () => {
    setNewUser([
      {
        name: "",
        number: 0,
        StudentEmail: "",
        grade: 0,
        parentName: "",
        bookingDate: "",
        bookingTime: "",
      },
    ]);
    setIsAddUserClicked(false);
  };
  const handleCloseAlert = () => {
    setAlertBox(false);
  };
  const handleDelete = async (type, ID) => {
    fetch(`${base_route}/admin/s-booking/deleteData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type, ID }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          // alert(res.error);
          setMessage(res.error);
          setType("error");
          setAlertBox(true);
          return;
        } else {
          window.location.reload();
          return;
        }
      });
  };

  const handleBookingEdit = (item) => {
    setNewUser({
      ...newUser,
      ["name"]: item.studentName,
      ["studentEmail"]: item.studentEmail,
      ["parentName"]: item.parentName,
      ["grade"]: item.grade,
      ["number"]: item.contactNo.toString(),
      ["bookingDate"]: new Date(item.bookingDate),
      ["bookingTime"]: item.bookingTime,
    });
    setIsAddUserClicked(true);
    setIsEditBookingBtnClicked(true);
  };
  const handleEditUser = () => {
    fetch(`${base_route}/admin/s-booking/updateBooking`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        studentEmail: newUser.studentEmail,
        name: newUser.name,
        parentName: newUser.parentName,
        grade: newUser.grade,
        number: newUser.number,
        bookingTime: newUser.bookingTime,
        bookingDate: newUser.bookingDate,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message) {
          setNewUser([
            {
              name: "",
              number: 0,
              StudentEmail: "",
              grade: 0,
              parentName: "",
              bookingDate: "",
              bookingTime: "",
            },
          ]);

          setMessage(resp.message);
          setType("success");
          setAlertBox(true);
          setIsAddUserClicked(false);
          setIsEditBookingBtnClicked(false);
          fetchNewBookings();
        } else {
          // alert(resp.error);
          setMessage(resp.error);
          setType("error");
          setAlertBox(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNewUser = () => {
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    fetch(`${base_route}/admin/s-booking/addNewBooking`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        studentEmail: newUser.studentEmail,
        name: newUser.name,
        parentName: newUser.parentName,
        grade: newUser.grade,
        number: newUser.number,
        bookingTime: newUser.bookingTime,
        bookingDate: newUser.bookingDate,
        personID,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message) {
          setNewUser([
            {
              name: "",
              number: 0,
              StudentEmail: "",
              grade: 0,
              parentName: "",
              bookingDate: "",
              bookingTime: "",
            },
          ]);

          // alert(resp.message);
          setMessage(resp.message);
          setType("success");
          setAlertBox(true);
          setIsAddUserClicked(false);
          setIsEditBookingBtnClicked(false);
          fetchNewBookings();
        } else {
          setMessage(resp.errror);
          setType("error");
          setAlertBox(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOnRowClick = (i, student, mode) => {
    history.push({
      pathname: "/dashboard/os-registration",
      state: { student: student, mode: mode },
    });
    setclickedIndex(i);
  };
  const handleAlert = () => {
    setAlertBox(true);
  };
  console.log(alertBox);
  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    return new Date(secondsSinceEpoch * 1000).toDateString().substring(4, 15);
  };
  const handleDropdown = (field, selected) => {
    setNewUser({ ...newUser, [field]: selected });
  };
  const handleOnChange = (field) => (event) => {
    setNewUser({ ...newUser, [field]: event.target.value });
  };

  return (
    <Base>
      <div className="s-bookingNew">
        <div className="heading">
          <BackNavigation />
        </div>
        <Header
          mode={"New"}
          setIsAddUserClicked={setIsAddUserClicked}
          onUpload={onUpload}
          setSearchFeild={setSearchFeild}
          searchFeild={searchFeild}
          search={search}
          setSearch={setSearch}
          recordsPerPage={recordsPerPage}
          onRecordPerPageClick={onRecordPerPageClick}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          indexOfFirstRecord={indexOfFirstRecord}
          indexOfLastRecord={indexOfLastRecord}
          data={students}
          title={"New Bookings"}
        />
        {alertBox && <AlertBox message={message} type={type} />}
        {isAddUserClicked && (
          <div className="new_booking_add_user_container d-flex flex-column ">
            <form>
              <div className="form-row">
                <div class="form-group col">
                  <label for="name">Student Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="student"
                    name="name"
                    onChange={handleOnChange("name")}
                    value={newUser.name}
                    placeholder="Student name"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label htmlFor="parentName">Parent Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="parent"
                    name="parentName"
                    placeholder="Parent Name"
                    onChange={handleOnChange("parentName")}
                    value={newUser.parentName}
                  />
                </div>
              </div>
              <div className="form-row">
                <div class="form-group col-md-6">
                  <label htmlFor="number">Phone No.</label>
                  <PhoneInput
                    className="phone w-25"
                    placeholder="Enter Phone no."
                    value={newUser.number}
                    onChange={(value) =>
                      setNewUser({ ...newUser, ["number"]: value })
                    }
                    defaultCountry="IN"
                    name="number"
                    autoComplete="on"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="studentEmail">Email</label>
                  <input
                    type="email"
                    name="studentEmail"
                    class="form-control"
                    id="inputEmail4"
                    placeholder="Email"
                    onChange={handleOnChange("studentEmail")}
                    value={newUser.studentEmail}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 ">
                  <TkDropDown
                    variant="light"
                    label="Grade *"
                    placeholder="Grade"
                    style={{ width: "100%" }}
                    _onClick={(selected) => handleDropdown("grade", selected)}
                    value={newUser.grade}
                    items={[
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "11",
                      "12",
                    ]}
                  />
                </div>
                <div className="form-group col-md-6 ">
                  <label for="bookinDate">Trial Date</label>
                  <input
                    type="date"
                    name="bookingDate"
                    class="form-control"
                    id="inputEmail4"
                    placeholder="Date"
                    onChange={handleOnChange("bookingDate")}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <TkDropDown
                    label="Trial Time"
                    style={{ width: "90%", marginTop: "3px" }}
                    placeholder="Time"
                    _onClick={(selected) =>
                      handleDropdown("bookingTime", selected)
                    }
                    value={newUser.bookingTime}
                    items={[
                      "06:00 AM",
                      "07:00 AM",
                      "08:00 AM",
                      "09:00 AM",
                      "10:00 AM",
                      "11:00 AM",
                      "12:00 PM",
                      "1:00 PM",
                      "2:00 PM",
                      "3:00 PM",
                      "4:00 PM",
                      "5:00 PM",
                      "6:00 PM",
                      "7:00 PM",
                      "8:00 PM",
                      "9:00 PM",
                      "10:00 PM",
                      "11:00 PM",
                      "12:00 AM",
                      "01:00 AM",
                      "02:00 AM",
                      "03:00 AM",
                      "04:00 AM",
                      "05:00 AM",
                    ]}
                  />
                </div>
              </div>
            </form>
            <span
              className="new_booking_add_user_close_btn"
              onClick={() => handleClose()}
            >
              <CloseIcon fontSize="large"></CloseIcon>
            </span>
            {isEditBookingBtnClicked ? (
              <button
                className="new_booking_add_user_submit_btn"
                onClick={() => handleEditUser()}
              >
                EDIT
              </button>
            ) : (
              <button
                className="new_booking_add_user_submit_btn btn btn-success"
                onClick={() => handleNewUser()}
              >
                ADD
              </button>
            )}
          </div>
        )}
        <div className="newTable">
          <table id="customers">
            <thead>
              <tr>
                <th>Sr.No</th>
                <th
                  onClick={() => {
                    handleAlert();
                  }}
                >
                  Lead Date
                </th>
                {role === "ADMIN" ? <th>PersonID</th> : <></>}
                <th>Parent's Name</th>
                <th>Student's Name</th>
                <th>Grade</th>
                <th>Country</th>
                <th>Phone No.</th>
                <th>Trial Date</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              // <div>currentRecords[0].studentEmail</div>
              currentRecords.map((item, index) => {
                return (
                  <>
                    <tr
                      key={item.studentEmail}
                      className={index === clickedIndex && `selected_row`}
                    >
                      <td>{indexOfFirstRecord + index + 1}</td>
                      <td>{getRegDate(item._id)}</td>
                      {role === "ADMIN" ? <td>{item.personID}</td> : null}
                      <td>{item.parentName}</td>
                      <td onClick={() => handleOnRowClick(index, item, "new")}>
                        {item.studentName}
                      </td>
                      <td>{item.grade}</td>

                      <td>
                        {findCountry(
                          `+${item.contactNo.toString().substring(0, 2)}`
                        )}
                      </td>
                      <td>{item.contactNo.toString().substring(2, 12)}</td>
                      <td>{item.bookingDate + " " + item.bookingTime}</td>
                      <td>{item.studentEmail}</td>
                      <td className="">
                        <button
                          className="edit_btn btn btn-success "
                          onClick={() => handleBookingEdit(item)}
                        >
                          Edit
                        </button>
                        {role === "ADMIN" ? (
                          <button
                            className="delete_buttton"
                            onClick={() => handleDelete("new", item._id)}
                          >
                            Delete
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  </>
                );
              })
            )}
          </table>
        </div>
      </div>
    </Base>
  );
};

import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import "../../styles/booking.css";
import "./styles/s-bookingTable.css";
import { searchAlgo, findCountry } from "../../Utils/Helper.js";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import TkDropDown from "../../Common/Components/DropDown";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import BackNavigation from "../../Common/Components/BackNavigation";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Feedback from "../student/StudentFeedback";
import PhoneInput from "react-phone-number-input";
import { base_route } from "../../backend";
import Base from "../../Common/Components/Base";
const BookingsInfo = (props) => {
  const [students, setStudent] = useState([]);
  const [loading, setLoadig] = useState(false);
  const [search, setSearch] = useState("");
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [currentRecords, setcurrentRecords] = useState([]);
  const [isFeedbackClicked, setIsFeedbackClicked] = useState(false);
  const history = useHistory();
  const [clickedName, setClickedName] = useState("");
  const [clickedFeedback, setClickedFeedback] = useState([]);
  const location = useLocation();
  const inputFile = useRef(null);
  const [render, setRender] = useState(0);
  const [file, setFile] = useState(null);
  const [isAddUserClicked, setIsAddUserClicked] = useState(false);
  const [isEditBookingBtnClicked, setIsEditBookingBtnClicked] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    parentName: "",
    grade: "",
    number: "",
    studentEmail: "",
    bookingDate: "",
    bookingTime: "",
  });

  const {
    studentEmail,
    name,
    parentName,
    grade,
    number,
    bookingTime,
    bookingDate,
  } = newUser;
  // get query from previous page location.state.query

  const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;

  // index of last n first record for pagination
  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  useEffect(() => {
    // to show limited records
    setcurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage, students]);

  // for search functionality
  useEffect(() => {
    console.log("field", search, searchFeild);
    searchFeild && search != ""
      ? setcurrentRecords(searchAlgo(search, students))
      : setcurrentRecords(
          students.slice(indexOfFirstRecord, indexOfLastRecord)
        );
  }, [search, searchFeild]);

  useEffect(() => {
    loadStudent();
  }, []);

  const onUpload = async () => {
    if (!file) {
      alert("Select .csv file");
      return;
    }
    const data = new FormData();
    data.append("file", file);
    data.append("type", "newbooking");
    await fetch(`${base_route}/booking/upload`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.error) {
          alert(resp.error);
        } else {
          setFile(null);
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  };

  const loadStudent = async () => {
    setLoadig(true);
    var t = location.state.query;
    var URL;
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
    if (t == "new") {
      URL = `${base_route}/admin/s-booking/newBookings?role=${role}&personID=${personID}`;
    } else if (t == "no-laptop") {
      URL = `${base_route}/admin/s-booking/noLaptop?role=${role}&personID=${personID}`;
    } else if (t == "trial-done") {
      URL = `${base_route}/admin/s-booking/trialDone?role=${role}&personID=${personID}`;
    } else if (t == "trail-booked") {
      URL = `${base_route}/admin/s-booking/trialBooked?role=${role}&personID=${personID}`;
    } else if (t == "s-converted") {
      URL = `${base_route}/admin/s-booking/converted?role=${role}&personID=${personID}`;
    }
    // else if (t == "reschedule") {
    //   URL = `${base_route}/booking/rescheduledBookings?role=${role}&personID=${personID}`;
    // } else if (t == "cancelledSlots") {
    //   URL = `${base_route}/admin/cancelledSlots?role=${role}&personnID=${personID}`;
    // }
    else {
      URL = `${base_route}/adminMain/s-booking/successfullTrail?role=${role}&personID=${personID}`;
    }
    console.log(URL);
    const response = await fetch(URL, {
      method: "GET",
    });
    const student = await response.json();
    setStudent(student.reverse());
    setLoadig(false);
  };
  const handleDelete = async (type, ID) => {
    fetch(`${base_route}/admin/s-booking/deleteData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type, ID }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          alert(res.error);
          return;
        } else {
          window.location.reload();
          return;
        }
      });
  };

  const handleBookingEdit = (item) => {
    console.log("item", item);
    setNewUser({
      ...newUser,
      ["name"]: item.studentName,
      ["studentEmail"]: item.studentEmail,
      ["parentName"]: item.parentName,
      ["grade"]: item.grade,
      ["number"]: item.contactNo.toString(),
      ["bookingDate"]: new Date(item.bookingDate),
      ["bookingTime"]: item.bookingTime,
    });
    setIsAddUserClicked(true);
    setIsEditBookingBtnClicked(true);
  };
  const handleEditUser = () => {
    console.log(
      JSON.stringify({
        studentEmail,
        name,
        parentName,
        grade,
        number,
        bookingTime,
        bookingDate,
      })
    );
    fetch(`${base_route}/admin/s-booking/updateBooking`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        studentEmail,
        name,
        parentName,
        grade,
        number,
        bookingTime,
        bookingDate,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message) {
          setNewUser([
            {
              name: "",
              number: 0,
              StudentEmail: "",
              grade: 0,
              parentName: "",
              bookingDate: "",
              bookingTime: "",
            },
          ]);

          alert(resp.message);
          setIsAddUserClicked(false);
          setIsEditBookingBtnClicked(false);
          loadStudent();
        } else {
          alert(resp.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnRowClick = (i, student, mode) => {
    console.log("Student at handle", i, student);
    history.push({
      pathname: "/dashboard/s-booking/info/student",
      state: { student: student, mode: mode },
    });
    setclickedIndex(i);
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };
  // set number of record to show on pagination
  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };
  const handleClick = (data, name) => {
    setClickedName(name);
    setClickedFeedback(data);
    setIsFeedbackClicked(true);
    console.log(data);
    history.push({
      pathname: "/dashboard/s-booking/info/studentfeedback",
      state: { name: name, data: data },
    });
  };

  const handleNewUser = () => {
    console.log(
      JSON.stringify({
        studentEmail,
        name,
        parentName,
        grade,
        number,
        bookingTime,
        bookingDate,
      })
    );
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    fetch(`${base_route}/admin/s-booking/addNewBooking`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        studentEmail,
        name,
        parentName,
        grade,
        number,
        bookingTime,
        bookingDate,
        personID,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message) {
          setNewUser([
            {
              name: "",
              number: 0,
              StudentEmail: "",
              grade: 0,
              parentName: "",
              bookingDate: "",
              bookingTime: "",
            },
          ]);

          alert(resp.message);
          setIsAddUserClicked(false);
          setIsEditBookingBtnClicked(false);
          loadStudent();
        } else {
          alert(resp.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setNewUser([
      {
        name: "",
        number: 0,
        StudentEmail: "",
        grade: 0,
        parentName: "",
        bookingDate: "",
        bookingTime: "",
      },
    ]);
    setIsAddUserClicked(false);
  };

  const showWindowName = (t) => {
    if (t == "new") {
      return "New Bookings";
    }
    if (t == "no-laptop") {
      return "No Laptop";
    }
    if (t == "trial-done") {
      return "Successful Trials";
    }
    if (t == "trail-booked") {
      return "Booked Trials";
    }
    if (t == "s-converted") {
      return "Paid Student's";
    }
    if (t == "reschedule") {
      return "Rescheduled";
    }
    if (t == "cancelledSlots") {
      return "Cancelled Slots";
    } else {
      return t;
    }
  };

  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    return new Date(secondsSinceEpoch * 1000).toDateString().substring(4, 15);
  };

  const handleDropdown = (field, selected) => {
    setNewUser({ ...newUser, [field]: selected });
  };
  const handleOnChange = (field) => (event) => {
    setNewUser({ ...newUser, [field]: event.target.value });
  };
  return (
    <Base>
      <div className="s-bookingTable">
        <BackNavigation />
        <h3>{showWindowName(location.state.query)}</h3>
        <div className="registration_actions">
          {location.state.query == "new" ? (
            <div
              className="registration_action_item"
              onClick={() => setIsAddUserClicked(true)}
            >
              <AddIcon style={{ color: "#36ff6b" }} />
              Add User
            </div>
          ) : (
            <></>
          )}

          <div className="registration_action_item">
            <AddIcon
              style={{ color: "#36ff6b" }}
              onClick={() => onButtonClick()}
            />
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
            {file != null ? file.originalname : ""}
            {location.state.query == "new" ? "Add File" : "Add"}
          </div>
          {file != null ? file.originalname : ""}
          <div className="">
            {location.state.query == "new" ? (
              <div>
                <button className="btn btn-primary" onClick={() => onUpload()}>
                  Upload
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* <div className="registration_action_item">
          <CloseIcon style={{ color: "#e82525" }} />
          Delete
        </div> */}
          <div
            className="registration_action_item"
            onClick={() => setSearchFeild(!searchFeild)}
          >
            <SearchIcon style={{ color: "#25a1e8" }} />
            Search
          </div>
          <div className="registration_action_item">
            <ArrowDropDownIcon />
            More Action
          </div>
        </div>
        {searchFeild && (
          <div className="d-flex align-items-center justify-content-center mt-3">
            <TextField
              label="Search by Anything"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}

        <div className="registration_tabel_action">
          <div className="d-flex">
            <span>Records per page</span>{" "}
            <TkDropDown
              style={{ width: "80px", height: "30px", marginLeft: "10px" }}
              _onClick={onRecordPerPageClick}
              value={recordsPerPage}
              items={["25", "50", "100", "150", "200"]}
            />
          </div>

          {/* Pagination UI */}
          <div className="d-flex ">
            <NavigateBeforeIcon
              onClick={() =>
                currentPage === 1 ? null : setCurrentPage(currentPage - 1)
              }
            />
            {indexOfFirstRecord + 1} - {indexOfLastRecord} / {students.length}
            <NavigateNextIcon
              onClick={() =>
                indexOfLastRecord > students.length
                  ? null
                  : setCurrentPage(currentPage + 1)
              }
            />
            <div className="registration_action_item" onClick={loadStudent}>
              <RefreshIcon style={{ color: "#25a1e8" }} />
              Refresh
            </div>
          </div>
          <div
            className="registration_action_item "
            onClick={() => setSearchFeild(!searchFeild)}
          >
            <SearchIcon style={{ color: "#25a1e8" }} />
            Search
          </div>
        </div>
        {isAddUserClicked && (
          <div className="new_booking_add_user_container">
            <span
              className="new_booking_add_user_close_btn"
              onClick={handleClose}
            >
              <CloseIcon fontSize="large"></CloseIcon>
            </span>

            <div className="new_booking_add_user_form_container">
              <fieldset>
                <label htmlFor="name">Student Name</label>
                <input
                  type="text"
                  name="name"
                  id=""
                  onChange={handleOnChange("name")}
                  value={newUser.name}
                />
                <br />
                <label htmlFor="number">Phone No.</label>
                <PhoneInput
                  placeholder="Enter Phone no."
                  value={newUser.number}
                  onChange={(value) =>
                    setNewUser({ ...newUser, ["number"]: value })
                  }
                  defaultCountry="IN"
                  name="number"
                  autoComplete="on"
                />

                <br />
                <label htmlFor="studentEmail">Email</label>
                <input
                  type="studentEmail"
                  name="studentEmail"
                  id=""
                  onChange={handleOnChange("studentEmail")}
                  value={newUser.studentEmail}
                />
              </fieldset>
              <fieldset>
                <label htmlFor="parentName">Parent's Name</label>
                <input
                  type="text"
                  name="parentName"
                  onChange={handleOnChange("parentName")}
                  value={newUser.parentName}
                />
                <br />

                <TkDropDown
                  label="Grade *"
                  placeholder="Grade"
                  style={{ width: "100%" }}
                  _onClick={(selected) => handleDropdown("grade", selected)}
                  value={newUser.grade}
                  items={[
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                  ]}
                />
                <br />
                <label htmlFor="bookingdate">Trial Date</label>
                <input
                  type="date"
                  name="bookingdate"
                  id=""
                  onChange={handleOnChange("bookingDate")}
                  // value={newUser.bookingDate === '' ? new Date(newUser.bookingDate)
                  //   .toISOString()
                  //   .substring(0, 10) : ''}
                  // value={""}
                />
                <br />

                <TkDropDown
                  label="Trial Time"
                  style={{ width: "90%", marginTop: "3px" }}
                  placeholder="Time"
                  _onClick={(selected) =>
                    handleDropdown("bookingTime", selected)
                  }
                  value={newUser.bookingTime}
                  items={[
                    "06:00 AM",
                    "07:00 AM",
                    "08:00 AM",
                    "09:00 AM",
                    "10:00 AM",
                    "11:00 AM",
                    "12:00 PM",
                    "1:00 PM",
                    "2:00 PM",
                    "3:00 PM",
                    "4:00 PM",
                    "5:00 PM",
                    "6:00 PM",
                    "7:00 PM",
                    "8:00 PM",
                    "9:00 PM",
                    "10:00 PM",
                    "11:00 PM",
                    "12:00 AM",
                    "01:00 AM",
                    "02:00 AM",
                    "03:00 AM",
                    "04:00 AM",
                    "05:00 AM",
                  ]}
                />
              </fieldset>
            </div>

            {isEditBookingBtnClicked ? (
              <button
                className="new_booking_add_user_submit_btn"
                onClick={handleEditUser}
              >
                EDIT
              </button>
            ) : (
              <button
                className="new_booking_add_user_submit_btn"
                onClick={handleNewUser}
              >
                ADD
              </button>
            )}
          </div>
        )}

        {/* {
        isEditBookingBtnClicked && <div className='new_booking_edit_container'>
        
        </div>
      } */}

        <table id="customers">
          <thead>
            <tr>
              <th>Sr.No</th>
              {location.state.query == "trial-done" ||
              location.state.query == "trail-booked" ? (
                <th>Trial Date</th>
              ) : (
                <th>Lead Date</th>
              )}

              {location.state.query == "new" && role === "ADMIN" ? (
                <th>PersonID</th>
              ) : (
                <></>
              )}

              <th>Parent's Name</th>
              <th>Student's Name</th>
              <th>Grade</th>
              <th>Country</th>
              <th>Phone No.</th>
              {location.state.query == "new" ? <th>Trial Date</th> : <></>}
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>

          {loading || currentRecords.lenght > 1 ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            currentRecords.map((item, index) => {
              if (location.state.query == "no-laptop") {
                return (
                  <tr
                    key={item.uid}
                    className={index === clickedIndex && `selected_row`}
                  >
                    <td>{indexOfFirstRecord + index + 1}</td>
                    <td>{getRegDate(item._id)}</td>
                    <td>{item.parentName}</td>
                    <td>{item.name}</td>

                    <td>{item.grade}</td>
                    <td>{findCountry(`+${item?.parentPhone?.countryCode}`)}</td>
                    <td>{item.parentPhone.number}</td>

                    <td>{item.email}</td>
                    <td>
                      <button
                        className="delete_buttton"
                        onClick={() => handleDelete("Nolaptop", item._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              } else if (
                location.state.query == "new" ||
                location.state.query == "reschedule" ||
                location.state.query == "cancelledSlots"
              ) {
                return (
                  <tr
                    key={item.uid}
                    className={index === clickedIndex && `selected_row`}
                  >
                    <td>{indexOfFirstRecord + index + 1}</td>
                    <td>{getRegDate(item._id)}</td>
                    {role === "ADMIN" ? <td>{item.personID}</td> : null}
                    <td>{item.parentName}</td>
                    <td onClick={() => handleOnRowClick(index, item, "new")}>
                      {item.studentName}
                    </td>
                    <td>{item.grade}</td>

                    <td>
                      {findCountry(
                        `+${item.contactNo.toString().substring(0, 2)}`
                      )}
                    </td>
                    <td>{item.contactNo.toString().substring(2, 12)}</td>
                    <td>{item.bookingDate + " " + item.bookingTime}</td>
                    <td>{item.studentEmail}</td>
                    <td>
                      <button
                        className="edit_btn"
                        onClick={() => handleBookingEdit(item)}
                      >
                        Edit
                      </button>
                      {role === "ADMIN" ? (
                        <button
                          className="delete_buttton"
                          onClick={() => handleDelete("new", item._id)}
                        >
                          Delete
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              } else if (location.state.query == "trail-booked") {
                return (
                  <tr
                    key={item.uid}
                    className={index === clickedIndex && `selected_row`}
                  >
                    <td>{indexOfFirstRecord + index + 1}</td>
                    <td>{item.slot}</td>
                    <td>{item.studentName}</td>
                    <td>{item.parentName}</td>
                    <td>{item.grade}</td>
                    <td>{findCountry(`+${item.parentPhone.countryCode}`)}</td>
                    <td>{item.parentPhone.number}</td>
                    <td>{item.studentEmail}</td>
                    <td>
                      {role === "ADMIN" ? (
                        <button
                          className="delete_buttton"
                          onClick={() => handleDelete("bookedTrail", item._id)}
                        >
                          Delete
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              } else if (location.state.query == "trial-done") {
                return (
                  <tr
                    key={item.uid}
                    className={index === clickedIndex && `selected_row`}
                  >
                    <td>{indexOfFirstRecord + index + 1}</td>
                    <td>{getRegDate(item._id)}</td>
                    <td>{item.parentName}</td>
                    <td>{item.studentName}</td>

                    <td>{item.grade}</td>
                    <td>{findCountry(`+${item.parentPhone.countryCode}`)}</td>
                    <td>{item.parentPhone.number}</td>

                    <td>{item.studentEmail}</td>
                    <td>
                      <button
                        className="feedback_buttton btn btn-dark"
                        onClick={() =>
                          handleClick(item.feedback, item.studentName)
                        }
                      >
                        Feedback
                      </button>
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr
                    key={item.uid}
                    className={index === clickedIndex && `selected_row`}
                  >
                    <td>{indexOfFirstRecord + index + 1}</td>
                    <td>{getRegDate(item._id)}</td>
                    <td>{item.parentName}</td>
                    <td>{item.name}</td>

                    <td>{item.grade}</td>
                    <td>{findCountry(`+${item.parentPhone.countryCode}`)}</td>
                    <td>{item.parentPhone.number}</td>

                    <td>{item.email}</td>
                    <td>
                      <button
                        className="s_converted_edit_button"
                        onClick={() => handleOnRowClick(index, item, "edit")}
                      >
                        Edit
                      </button>
                      <button
                        className="s_converted_feedback_button"
                        onClick={() =>
                          history.push({
                            pathname:
                              "/dashboard/s-booking/info/student/feedback",
                            state: { student: item },
                          })
                        }
                        style={{ backgroundColor: "#43dcff" }}
                      >
                        View Feedback
                      </button>
                    </td>
                  </tr>
                );
              }
            })
          )}
        </table>
        <div className="feedback">
          {isFeedbackClicked ? (
            // <Feedback
            //   setIsFeedbackClicked={setIsFeedbackClicked}
            //   clickedName={clickedName}
            //   clickedFeedback={clickedFeedback}
            // />
            <></>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Base>
  );
};

export default BookingsInfo;

import { base_route } from '../../backend'

export const createTicket = (data, token) => {
  return fetch(`${base_route}/ticket/new`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'bearer-token': `${token}`,
    },
    body: data,
  })
    .then((resp) => {
      return resp.json()
    })
    .catch((err) => console.log(err))
}

export const getTickets = async (token) => {
  return await fetch(`${base_route}/ticket/userticket`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'bearer-token': `${token}`,
    },
  })
    .then((resp) => {
      return resp.json()
    })
    .catch((err) => console.log(err))
}

import React, { useState, useEffect } from "react";
import Base from "../../Common/Components/Base";
import { useHistory } from "react-router-dom";
import "../../styles/profile.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TkDropDown from "../../Common/Components/DropDown";
import PhoneInput from "react-phone-input-2";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import "react-phone-input-2/lib/style.css";
import SearchDetails from "../SearchInfo";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useLocation } from "react-router-dom";
import { Button, IconButton, Input } from "@material-ui/core";
import { base_route } from "../../backend";

const ProfileEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const [profile, setProfile] = useState(
    location.state.data.profile != undefined ? location.state.data.profile : ""
  );

  const [AccessList, setAccessList] = useState(
    location.state.data.access != undefined ? location.state.data.access : []
  );

  const [access, setAccess] = useState("");

  // console.log(location.state)
  const handleAccessList = (event, access) => {
    event.preventDefault();
    console.log("acces", access);
    if (access != null) {
      setAccess(access);
      setAccessList((oldArr) => [...oldArr, access]);
    }
  };

  const popAccessItem = (index) => {
    setAccessList(AccessList.filter((item, i) => i !== index));
  };
  const handleSave = () => {
    if (!profile) {
      alert("Enter profile");
      return;
    }
    if (AccessList.length <= 0) {
      alert("Enter Access");
      return;
    }
    if (location.state.type != "edit") {
      fetch(`${base_route}/adminMain/settings/profileAccess/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ profile, AccessList }),
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.error) {
            alert(resp.error);
            return;
          }
          alert(resp.message);
          history.push("/dashboard/profile/access");
          console.log(resp);
        })
        .catch((err) => console.log(err));
    } else {
      fetch(`${base_route}/adminMain/settings/profileAccess/update`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ profile, AccessList }),
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.error) {
            alert(resp.error);
            return;
          }
          alert(resp.message);
          history.push("/dashboard/profile/access");
          console.log(resp);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Base>
      <div className="container mt-5">
        <IconButton onClick={() => history.goBack()}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <div className="d-flex  w-1">
          <div className="">
            {/*<div className="user_details">
              <div>
                {/* <label htmlFor="name">Profile</label>
                <input
                  type="text"
                  name="name"
                  id=""
                  value={profile}
                  readOnly={location.state.type == "edit" ? true : false}
                  onChange={(e) => {
                    if (location.state.type != "edit")
                      setProfile(e.target.value);
                  }}
                /> 
                <br />
              </div>
            </div> */}
            <div>
              <Input
                style={{ marginTop: "4rem" }}
                value={profile}
                placeholder="Profile"
                readOnly={location.state.type == "edit" ? true : false}
                onChange={(e) => {
                  if (location.state.type != "edit") setProfile(e.target.value);
                }}
              />
            </div>
            <br />
            <Button
              className="btn btn-success save_button"
              onClick={() => handleSave()}
              color="primary"
              style={{ textAlign: "center", color: "white" }}
              variant="contained"
            >
              Save
            </Button>
          </div>
          <div className="d-flex w-100 double_drop">
            <div className="profile_info">
              <div class="profile_form_group d-flex flex-column">
                <Autocomplete
                  id="combo-box-demo"
                  options={[
                    "Dashboard",
                    "S-Booking",
                    "S-Registration",
                    "OS-Registration",
                    "S-Curriculum",
                    "S-Free Video",
                    "S-Feedback",
                    "Student User",
                    "S_New",
                    "S_Trial_Booked",
                    "S_Laptop",
                    "S_Trial_Done",
                    "S_Converted",
                    "S_Rescheduled",
                    "S_CancelSlot",
                    "S_HalfDone",
                    "Teacher",
                    "T-Slot",
                    "Teacher Slot Time",
                    "P-Slot",
                    "T-Registration",
                    "T-Curriculum",
                    "T-Earning",
                    "T-Leave",
                    "T-Feedback",
                    "Refer & Earn",
                    "Refer & Earn Individual",
                    "Coupon Code",
                    "Ticket",
                    "Notification",
                    "Setting",
                    "Edit Earning",
                    "Student Booking Info",
                    "Student Info",
                    "Feedback On Paid Student",
                    "User",
                    "User Info",
                    "Profile Access",
                    "Edit Profile Access",
                  ]}
                  onChange={(event, newValue) => {
                    handleAccessList(event, newValue);
                  }}
                  getOptionLabel={(option) => option}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Access"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className="d-flex flex-wrap mt-2 ">
                {AccessList.map((item, index) => {
                  return (
                    <span className=" badge_item" key={index}>
                      <span className="mr-1"> {item}</span>
                      <CloseIcon
                        style={{ fontSize: "15px" }}
                        onClick={() => popAccessItem(index)}
                      />
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default ProfileEdit;

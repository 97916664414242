import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Base from "../Common/Components/Base";
import axios from "axios";
import { base_route } from "../backend";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import moment from "moment";
import "./TableContent.css"

const TableContent = () => {
  const [users, setUsers] = useState([]);
  
  useEffect(() => {
    axios
    .get(`${base_route}/counselling/counsellors`)
    .then((response) => {
      setUsers(response.data.data);
      // console.log(response.data.data.createdAt);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);
  
  
  const [selectedDate, setSelectedDate] = useState(null); 
  const   filteredData = users.filter(item => new Date(item.createdAt) > new Date(selectedDate));
  
  return (
    <Base>
      <div className="tablecont_calendar  pt-5 w-25 d-flex  justify-content-end">
							<input
								style={{ background: "#3f51b5", color : "white" }}
								type="date"
								value={selectedDate}
								onChange={(e) => setSelectedDate(e.target.value)}
							></input>
						</div>
       <table>
        <thead>
          <th className="border-2 tableh">Assigned Name</th>
          <th className="border-2 tableh">Parent's Name</th>
          <th className="border-2 tableh">Student Grade</th>
          <th className="border-2 tableh">Mobile Number</th> 
          <th className="border-2 tableh">Email</th>
          <th className="border-2 tableh">Counselling Date</th>
          <th className="border-2 tableh">Counselling Time</th>
          <th className="border-2 tableh">Timer start time</th>
          <th className="border-2 tableh">Timer end time / ongoing</th>
          <th className="border-2 tableh">Type</th>
          <th className="border-2 tableh">Amount</th>
          <th className="border-2 tableh">Plan/date</th>
        </thead>
        <tbody>

    
        
        {filteredData.reverse().map((item, id) => {
        const {
          counsellorName,
          parentName,
          grade,
          parentPhone,
          parentEmail,
          counsellingDateAndTime,
          startTime,
          endTime,
          type,
          amount,
          plan,
          date,
          createdAt
        } = item;
     

        const dateFromBackend = counsellingDateAndTime;
        const dateObject = new Date(dateFromBackend);
        const formattedDate = moment(dateObject).format("DD/MM/YYYY");

        const dateFromBackendP = date;
        const dateObjectP = new Date(dateFromBackendP);
        const formattedDateP = moment(dateObjectP).format("DD/MM/YYYY");

        const dateString = counsellingDateAndTime;
        const dateObj = new Date(dateString);
        const timeStr = dateObj.toLocaleTimeString(); // or dateObj.toTimeString()

        //Timer start time
        const dateStrings = startTime;
        const dateObjs = new Date(dateStrings);
        const timeStrs = dateObjs.toLocaleTimeString(); // or dateObj.toTimeString()

        //Timer end time
        const dateStringe = endTime;
        const dateObje = new Date(dateStringe);
        const timeStre = dateObje.toLocaleTimeString(); // or dateObj.toTimeString()
        

          return (
            <tr key={id}>
              <td className="border-1">
                {counsellorName ? counsellorName : "Not Available"}
              </td>
              <td className="border-1">
                {parentName ? parentName : "Not Available"}
              </td>
              <td className="border-1">{grade ? grade : "Not Available"}</td>
              <td className="border-1">
                {parentPhone.number ? parentPhone.number : "Not Available"}
              </td>
              <td className="border-1">
                {parentEmail ? parentEmail : "Not Available"}
              </td>
              {/* <td className="border-1">{counsellingDateAndTime}</td> */}
              <td className="border-1">
                {formattedDate == "Invalid date"
                  ? "Not Available"
                  : formattedDate}
              </td>
              <td className="border-1">
              {timeStr == "Invalid Date"
                  ? "Not Available"
                  : timeStr}
  
              </td>
              <td className="border-1">
              {timeStrs == "Invalid Date"
                  ? "Not Available"
                  : timeStrs}
  
              </td>
              <td className="border-1">
              {timeStre == "Invalid Date"
                  ? "Ongoing"
                  : timeStre}
  
              </td>
              <td className="border-1">{type ? type : "Not Available"}</td>
              <td className="border-1">{amount ? "₹ " +amount : "Not Available"}</td>
              {/* <td className="border-1">{type ? type : "Not Available"}</td> */}
              <td className="border-1">
              {(formattedDateP == "Invalid date") || (formattedDateP == "01/01/1970")
                  ? ("Not Available")
                  : formattedDateP }

                  {/* {if(formattedDateP ==)} */}
              </td>
            </tr>
          );
      })}
        </tbody>
      </table>
    </Base>
  );
};

export default withRouter(TableContent);

import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileInput from "../ProfileInput";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import TkDropDown from "../../Common/Components/DropDown";
import BackNavigation from "../../Common/Components/BackNavigation";
import "../../styles/studentInfo.css";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Base from "../../Common/Components/Base";
import { base_route } from "../../backend";

const StudentInfo = () => {
  const location = useLocation();
  // get email by => location.state.email
  const student = location.state.student;
  const mode = location.state.mode;
  const history = useHistory();
  // console.log("mode", mode);
  const [teacherList, setteacherList] = useState([]);
  const [slotList, setSlotList] = useState([]);
  const [time, setTime] = useState("06:00 AM");
  const [day, setDay] = useState("FRI");
  const [hasSubmit, setHasSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const [empcode, setEmpcode] = useState("");
  const [values, setValues] = useState({
    studentName: student.studentName ? student.studentName : student.name,
    parentName: student.parentName,
    parentContact: student.contactNo
      ? student.contactNo.toString()
      : "+" +
        student.parentPhone.countryCode.toString() +
        student.parentPhone.number.toString(),
    alternateContact: "",
    parentsEmailId: student.studentEmail ? student.studentEmail : student.email,
    grade: student.grade,
    schoolName: student.school ? student.school : "",
    trialDate: student.bookingDate,
    trialTime: student.bookingTime,
    level: student.level ? student.level : 0,
    planPackage: student.package ? student.package : "",
    billAmount: student.billAmount ? student.billAmount : "",
    teacherCode: "",
    classCompleted: 0,
    remainingClasses: 0,
    paidClassDay: "",
    paidClassTime: "",
    salePersonName: student.saleperson ? student.saleperson : "",
  });
  const {
    studentName,
    parentName,
    parentContact,
    alternateContact,
    parentsEmailId,
    grade,
    schoolName,
    trialDate,
    trialTime,
    level,
    planPackage,
    classCompleted,
    remainingClasses,
    billAmount,
    teacherCode,
    paidClassDay,
    paidClassTime,
    salePersonName,
  } = values;

  useEffect(() => {
    console.log("classCompleted", classCompleted);
    if (planPackage == "Beginner") {
      setValues({ ...values, remainingClasses: 8 - classCompleted });
    } else if (planPackage == "Advance") {
      setValues({ ...values, remainingClasses: 48 - classCompleted });
    } else if (planPackage == "Master") {
      setValues({ ...values, remainingClasses: 144 - classCompleted });
    } else {
      setValues({ ...values, remainingClasses: 300 - classCompleted });
    }
  }, [level, planPackage, classCompleted]);

  const handleOnChange = (field) => (event) => {
    console.log(event.target.value);
    if (field == "classCompleted") {
      if (planPackage == "Beginner") {
        setValues({ ...values, remainingClasses: 8 - classCompleted });
      } else if (planPackage == "Advance") {
        setValues({ ...values, remainingClasses: 48 - classCompleted });
      } else if (planPackage == "Master") {
        setValues({ ...values, remainingClasses: 144 - classCompleted });
      } else {
        setValues({ ...values, remainingClasses: 300 - classCompleted });
      }
    }
    setValues({ ...values, [field]: event.target.value });
  };

  const handleDropdown = (field, selected) => {
    setValues({ ...values, [field]: selected });
  };
  const handleSave = () => {
    setHasSubmit(true);
    setMessage("Please Wait..");
    // if (
    //   slotList.length < 1 ||
    //   !billAmount ||
    //   !planPackage ||
    //   !remainingClasses ||
    //   level === 0 ||
    //   !schoolName
    // ) {
    //   alert("Enter All the fields");
    //   setHasSubmit(false);
    //   setMessage("");
    //   return;
    // }

    if (remainingClasses < 0) {
      alert("Remaining Classes can'nt be Negative");
      return;
    }
    var tCode;
    if (teacherCode == "") {
      tCode = empcode;
    } else {
      tCode = teacherCode;
    }

    console.log(
      studentName,
      parentName,
      parentContact,
      alternateContact,
      parentsEmailId,
      grade,
      schoolName,
      trialDate,
      trialTime,
      level,
      planPackage,
      billAmount,
      classCompleted,
      remainingClasses,
      tCode,
      salePersonName,
      slotList
    );
    var slots = Array.from(new Set(slotList));
    if (mode != "edit") {
      fetch(`${base_route}/admin/newstudent`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studentName,
          parentName,
          parentContact,
          alternateContact,
          parentsEmailId,
          grade,
          schoolName,
          trialDate,
          trialTime,
          level,
          planPackage,
          classCompleted,
          remainingClasses,
          billAmount,
          teacherCode,
          salePersonName,
          slots,
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.message) {
            setValues([]);
            alert(resp.message);
            setHasSubmit(false);
            setMessage("");
            history.goBack();
          } else {
            alert(resp.error);
            setHasSubmit(false);
            setMessage("");
          }
        })
        .catch((err) => {
          toast("Error");
        });
    } else {
      fetch(`${base_route}/admin/editstudent`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studentName,
          parentName,
          parentContact,
          alternateContact,
          parentsEmailId,
          grade,
          schoolName,
          trialDate,
          trialTime,
          level,
          planPackage,
          classCompleted,
          remainingClasses,
          billAmount,
          tCode,
          salePersonName,
          slots,
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.message) {
            setValues([]);
            alert(resp.message);
            setHasSubmit(false);
            setMessage("");
            history.goBack();
          } else {
            alert(resp.error);
            setHasSubmit(false);
            setMessage("");
          }
        })
        .catch((err) => {
          toast("Error");
        });
    }
  };

  const handleDayList = (day) => {
    setDay(day);
  };
  const handleTimeClick = (time) => {
    setTime(time);
  };
  const handleAddBtnClick = () => {
    const slot = {
      day: day,
      time: time,
    };
    setSlotList((oldArr) => [...oldArr, slot]);
  };

  const popSlotItem = (index) => {
    setSlotList(slotList.filter((item, i) => i !== index));
  };
  const hasClicked = () => {
    if (hasSubmit) {
      return "save_button_deactive";
    } else {
      return "save_button";
    }
  };

  useEffect(() => {
    if (mode == "edit") {
      fetch(`${base_route}/admin/paidclassescompleted`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          parentsEmailId,
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp);
          setEmpcode(resp.teacherAssigned);
          setSlotList(resp.slotData);
          setValues({
            ...values,
            ["classCompleted"]: resp.count,
          });

          if (planPackage == "Beginner") {
            setValues({ ...values, remainingClasses: 8 - resp.count });
          } else if (planPackage == "Advance") {
            setValues({ ...values, remainingClasses: 48 - resp.count });
          } else if (planPackage == "Master") {
            setValues({ ...values, remainingClasses: 144 - resp.count });
          } else {
            setValues({ ...values, remainingClasses: 300 - resp.count });
          }
        })
        .catch((err) => {
          toast("Error");
        });
    }
  }, [mode]);
  useEffect(async () => {
    await fetch(`${base_route}/teacher/allTeachers`)
      .then((res) => res.json())
      .then((result) => {
        setteacherList(result.map((item) => item.employeeCode));
      });
  }, []);

  return (
    <Base>
      <div className="profile_edit d-flex flex-sm-row flex-column w_90 mx-auto ">
        <BackNavigation />
        <div className="p_60 col-sm-6 col-12 d-flex align-items-start flex-column">
          <ProfileInput
            label="Student Name*"
            placeholder="Student Name"
            onChange={handleOnChange("studentName")}
            value={studentName}
          />
          <div className="profile_info">
            <label>Parent's Contact*</label>
            <PhoneInput
              value={parentContact}
              onChange={(value) =>
                setValues({ ...values, ["parentContact"]: value })
              }
              name="phone"
              autoComplete="on"
              maxlength="15"
            />
          </div>
          <div className="profile_info">
            <label>Alternate Contact</label>
            <PhoneInput
              value={alternateContact}
              onChange={(value) =>
                setValues({ ...values, ["alternateContact"]: value })
              }
              name="phone"
              autoComplete="on"
              maxlength="15"
              readonly
            />
          </div>

          <ProfileInput
            label="School Name*"
            placeholder="School Name"
            onChange={handleOnChange("schoolName")}
            value={schoolName}
          />
          <div className="profile_info">
            <TkDropDown
              label="Level"
              placeholder="Level"
              style={{ width: "100%" }}
              _onClick={(selected) => {
                if (mode != "edit") handleDropdown("level", selected);
                else return;
              }}
              value={level}
              items={["Level 1", "Level 2", "Level 3"]}
            />
          </div>

          <ProfileInput
            label="Class Completed *"
            placeholder="No of classes Completed"
            type="number"
            value={classCompleted}
            onChange={handleOnChange("classCompleted")}
          />

          <ProfileInput
            label="Remaining Classes *"
            placeholder="No. of Remaining Classes"
            type="number"
            readonly
            onChange={() => {
              return; /*handleOnChange("remainingClasses")*/
            }}
            value={remainingClasses}
          />
          <div className="profile_info">
            <label htmlFor="">Teacher's Employee Code</label>

            <Autocomplete
              id="combo-box-demo"
              options={teacherList}
              onInputChange={(event, newValue) => {
                setValues({ ...values, ["teacherCode"]: newValue });
              }}
              inputValue={teacherCode}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Teacher's Employee Code"
                  variant="outlined"
                />
              )}
            />
            {empcode}
          </div>
        </div>
        <div className="p_60 col-sm-6 col-12 d-flex align-items-start flex-column">
          <ProfileInput
            label="Sale person's Name"
            placeholder="Sales perso name"
            value={salePersonName}
            onChange={handleOnChange("salePersonName")}
          />
          <ProfileInput
            label="Parent's Email Id*"
            placeholder="Parent's Email Id*"
            value={parentsEmailId}
            readonly={true}
            //onChange={handleOnChange("parentsEmailId")}
          />
          <div className="profile_info">
            <TkDropDown
              label="Select grade/class*"
              placeholder="Grade"
              style={{ width: "100%" }}
              _onClick={(selected) => handleDropdown("grade", selected)}
              value={grade}
              items={[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
              ]}
            />
          </div>
          <div className="d-flex w-100 double_drop">
            <div className="profile_info">
              <TkDropDown
                label="Trial Date & Time"
                placeholder="Date"
                style={{ width: "90%" }}
                _onClick={(selected) => handleDropdown("trialDate", selected)}
                value={trialDate}
                items={[trialDate]}
              />
            </div>
            <div className="profile_info">
              <TkDropDown
                style={{ width: "90%", marginTop: "3px" }}
                placeholder="Time"
                _onClick={(selected) => handleDropdown("trialTime", selected)}
                value={trialTime}
                items={[trialTime]}
              />
            </div>
          </div>
          <div className="profile_info">
            <TkDropDown
              label="Plan Package"
              placeholder="Package"
              style={{ width: "100%" }}
              _onClick={(selected) => {
                if (mode != "edit") handleDropdown("planPackage", selected);
                else return;
              }}
              value={planPackage}
              items={["Beginner", "Advance", "Master", "Ultimate"]}
            />
          </div>
          <ProfileInput
            label="Bill Amount"
            placeholder="Bill Amount"
            value={billAmount}
            onChange={handleOnChange("billAmount")}
          />
          <div className="d-flex w-100 double_drop">
            <div className="profile_info ">
              <label htmlFor="">Paid Class : </label>
              <TkDropDown
                label="Day"
                style={{ width: "90%" }}
                placeholder="Day"
                _onClick={handleDayList}
                value={day}
                items={["FRI", "SAT", "SUN", "MON", "TUE", "WED", "THU"]}
              />
            </div>
            <div className="profile_info">
              <label htmlFor="">&nbsp; </label>

              <TkDropDown
                label="Time"
                style={{ width: "90%", marginTop: "3px" }}
                placeholder="Time"
                _onClick={handleTimeClick}
                value={time}
                items={[
                  "06:00 AM",
                  "07:00 AM",
                  "08:00 AM",
                  "09:00 AM",
                  "10:00 AM",
                  "11:00 AM",
                  "12:00 PM",
                  "1:00 PM",
                  "2:00 PM",
                  "3:00 PM",
                  "4:00 PM",
                  "5:00 PM",
                  "6:00 PM",
                  "7:00 PM",
                  "8:00 PM",
                  "9:00 PM",
                  "10:00 PM",
                  "11:00 PM",
                  "12:00 AM",
                  "01:00 AM",
                  "02:00 AM",
                  "03:00 AM",
                  "04:00 AM",
                  "05:00 AM",
                ]}
              />
            </div>
            <div className="profile_info">
              <label htmlFor="">&nbsp;</label>
              <div className="profile_info_add">
                <span
                  className="profile_info_add_btn"
                  onClick={handleAddBtnClick}
                >
                  <AddCircleIcon fontSize="large"></AddCircleIcon>
                </span>
              </div>
            </div>
          </div>
          <div className="profile_info_slot_list">
            {slotList.map((item, index) => {
              return (
                <div style={{ marginTop: "0.6rem" }}>
                  <span className="badge_item" key={index}>
                    <span className="mr-1">
                      {" "}
                      {item.day} - {item.time}
                    </span>
                    <RemoveCircleIcon
                      style={{ fontSize: "20px", color: "orange" }}
                      onClick={() => popSlotItem(index)}
                    />
                  </span>{" "}
                  <br />
                </div>
              );
            })}
          </div>
          <button
            onClick={() => handleSave()}
            disabled={hasSubmit}
            className={hasClicked()}
          >
            Save
          </button>
          <h6>{message}</h6>
        </div>
      </div>
    </Base>
  );
};

export default StudentInfo;

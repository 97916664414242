import React from "react";
import "../styles/common/styles.css"


const ProfileInput = ({ label, type, placeholder, value, attr, onChange }) => {
  const inputType = () => {
    switch (type) {
      case "textarea":
        return (
          <textarea
            rows="10"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          ></textarea>
        );
      case "readonly":
        return (
          <input type={type} placeholder={placeholder} value={value} readOnly />
        );

      default:
        return (
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        );
    }
  };

  return (
    <div className="profile_info">
      <label>
        {label} 
      </label>
      {inputType()}
    </div>
  );
};

export default ProfileInput;

import React, { useEffect, useState } from "react";
import Base from "../../Common/Components/Base";
import { useHistory } from "react-router";
import "../../styles/t_earning.css";
import calendarIcon from "../../Assests/paidCalendar.png";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { base_route } from "../../backend";
import { CircularProgress } from "@material-ui/core";
import { result } from "lodash";
const Earning = () => {
  const history = useHistory();
  const [teacher, setteacherList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [empcode, setEmpcode] = useState("");
  const [isItemClicked, setIsItemClicked] = useState(false);
  const [itemSelected, setItemSelected] = useState("trailCompleted");
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [data, setData] = useState({
    trialTaken: 0,
    trialConverted: 0,
    trialCancelled: 0,
    PaidClassINR: 0,
    PaidClassEINR: 0,
    panalty: 0,
  });

  const onEdit = (empcode, date) => {
    history.push({
      pathname: "/dashboard/t-earning/edit/",
      state: { empcode: empcode, date: date },
    });
  };
  useEffect(async () => {
    if (empcode != "") {
      console.log(empcode);
      await fetch(`${base_route}/admin/teacherearningsbydate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date, empcode }),
      })
        .then((res) => {
          console.log(res);
          res.json().then((resp) => {
            console.log(resp);
            setData(resp);
          });
        })
        .catch((err) => console.log(err));
    }
  }, [date, empcode]);
  useEffect(async () => {
    await fetch(`${base_route}/teacher/allTeachers`)
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        setteacherList(result);
      });
  }, []);

  useEffect(() => {
    //   console.log(empcode, data, itemSelected)
    async function fetchItem() {
      if (empcode !== "") {
        setLoading(true);
        const data = await fetch(`${base_route}/slot/slotbydate-and-type`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bearer-token": JSON.parse(sessionStorage.getItem("tk_jwt")).token,
          },
          body: JSON.stringify({
            date: date,
            empcode: empcode,
            type: itemSelected,
          }),
        }).then((result) => result.json());
        setItemData(data);
        setLoading(false);
      }
    }
    fetchItem();
  }, [itemSelected, date, empcode]);

  //   const ()=>{setIsItemClicked(true) setItemSelected('')} = (e) => {
  //     setIsItemClicked(true)
  //     setItemSelected(e.target.value)
  //     console.log('value', e.target.value)
  //     console.log('item', itemSelected)
  //   }

  return (
    <Base>
      <div className="earning">
        {isItemClicked && (
          <div className="earning_popup_container">
            <span
              className="earning_popup_close_btn"
              onClick={() => setIsItemClicked(false)}
            >
              <HighlightOffIcon fontSize="large"></HighlightOffIcon>
            </span>
            <h3>{selectedItemName}</h3>
            <ul>
              {loading ? (
                <div className="loading">
                  <CircularProgress></CircularProgress>
                </div>
              ) : (
                itemData.map((item) => {
                  return (
                    <div>
                      <li className="box_inside d-flex">
                        <span>{item.studentName}</span>{" "}
                        <span>Class: {item.remark}</span>{" "}
                        <span>
                          <img src={calendarIcon} alt="calenderIcon" />
                          {item.slot}
                        </span>
                      </li>
                    </div>
                  );
                })
              )}
            </ul>
          </div>
        )}
        <form>
          <div class="form-group row">
            <label for="exampleFormControlSelect1" class="col col-form-label">
              Teacher EmpCode
            </label>
            <div class="col-xs-4">
              <select
                class="form-control w-50 t_earning_input"
                id="exampleFormControlSelect1"
                value={empcode}
                onChange={(e) => setEmpcode(e.target.value)}
              >
                <option value="" disabled selected>
                  Select Employee Code
                </option>
                {teacher.map((item) => {
                  return (
                    <option value={item.employeeCode}>
                      {item.employeeCode}
                    </option>
                  );
                })}
              </select>
            </div>
            <button
              className="btn btn-info edit_button w-25"
              onClick={() => onEdit(empcode, date)}
            >
              Edit
            </button>
          </div>

          <div class="form-group row">
            <label for="date" class="col-sm-2  col-form-label">
              Select Month
            </label>

            <div class="col-xs-4 ">
              <input
                type="month"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="form-control-plaintext w-50 t_earning_input_date"
                id="date"
                placeholder="select month"
              ></input>
            </div>
          </div>
        </form>
        <hr className="w-50"></hr>
        <div className="earn_type">
          <div
            className="earn_type_item"
            onClick={(e) => {
              setIsItemClicked(true);
              setItemSelected("trailCompleted");
              setSelectedItemName("Trial Taken");
            }}
          >
            <span className="earn_text">Trial Taken</span>
            <span className="earn_price">{data.trialTaken}</span>
          </div>
          <div
            className="earn_type_item"
            onClick={() => {
              setIsItemClicked(true);
              setItemSelected("trailConverted");
              setSelectedItemName("Trial Converted");
            }}
          >
            <span className="earn_text">Trial Converted</span>
            <span className="earn_price">{data.trialConverted}</span>
          </div>
          <div
            className="earn_type_item"
            onClick={() => {
              setIsItemClicked(true);
              setItemSelected("paidClassEINR");
              setSelectedItemName("Int Paid Class");
            }}
          >
            <span className="earn_text">Int Paid Class</span>
            <span className="earn_price">{data.PaidClassEINR}</span>
          </div>
          <div
            className="earn_type_item"
            onClick={() => {
              setIsItemClicked(true);
              setSelectedItemName("Paid Classes");
              setItemSelected("paidClassINR");
            }}
          >
            <span className="earn_text">Paid Classes</span>
            <span className="earn_price">{data.PaidClassINR}</span>
          </div>
          <div
            className="earn_type_item"
            onClick={() => {
              setIsItemClicked(true);
              setSelectedItemName("Trial Cancelled");
              setItemSelected("cancelled");
            }}
          >
            <span className="earn_text">Trial Cancelled</span>
            <span className="earn_price">{data.trialCancelled}</span>
          </div>
          <div
            className="earn_type_item"
            onClick={() => {
              setIsItemClicked(true);
              setSelectedItemName("Penalty");
              setItemSelected("panalty");
            }}
          >
            <span className="earn_text">Penalty</span>
            <span className="earn_price">{data.penalty}</span>
          </div>
        </div>
        <div className="earn_type in_rupees">
          <div
            className="earn_type_item"
            // onClick={() => {
            //   setIsItemClicked(true)
            //   setItemSelected('')
            // }}
          >
            <span className="earn_text">Trial Taken</span>
            <span className="earn_price">
              {"\u20A8 "}
              {data.trialTaken * 50}
            </span>
          </div>
          <div
            className="earn_type_item"
            // onClick={() => {
            //   setIsItemClicked(true)
            //   setItemSelected('')
            // }}
          >
            <span className="earn_text">Trial Converted</span>
            <span className="earn_price">
              {"\u20A8 "}
              {data.trialConverted * 200}
            </span>
          </div>
          <div
            className="earn_type_item"
            // onClick={() => {
            //   setIsItemClicked(true)
            //   setItemSelected('')
            // }}
          >
            <span className="earn_text">Int Paid Class</span>
            <span className="earn_price">
              {"\u20A8 "}
              {data.PaidClassEINR * 250}
            </span>
          </div>
          <div
            className="earn_type_item"
            // onClick={() => {
            //   setIsItemClicked(true)
            //   setItemSelected('')
            // }}
          >
            <span className="earn_text">Paid Classes</span>
            <span className="earn_price">
              {"\u20A8 "}
              {data.PaidClassINR * 150}
            </span>
          </div>
          <div
            className="earn_type_item"
            // onClick={() => {
            //   setIsItemClicked(true)
            //   setItemSelected('')
            // }}
          >
            <span className="earn_text">Penalty</span>
            <span className="earn_price">
              {"\u20A8 "} {data.penalty * 200}
            </span>
          </div>
        </div>
        <div className="month_total">
          <span className="month_text">This Month Total</span>
          <span className="month_price">
            {"\u20B9 "} &nbsp;
            {data.trialTaken * 50 +
              data.trialConverted * 200 +
              data.PaidClassEINR * 250 +
              data.PaidClassINR * 150 -
              data.penalty * 200}
          </span>
        </div>
      </div>
    </Base>
  );
};

export default Earning;

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import BackNavigation from "../../Common/Components/BackNavigation";
import TkDropDown from "../../Common/Components/DropDown";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { base_route } from "./../../backend";
import "./styles/availTable.css";
import Base from "../../Common/Components/Base";

export const AvailableTable = () => {
  const location = useLocation();
  const history = useHistory();
  // const [date, setDate] = useState(new Date());
  const [teacherID, setTeacherID] = useState(location.state.teacherID);
  const [Grade, setGrade] = useState(location.state.Grade);
  const [teacherName, setTeacherName] = useState(location.state.teacherName);
  const [teachers, setTeachers] = useState(location.state.teachers);
  const [teachersOBJ, setTeachersOBJ] = useState(location.state.teachersOBJ);
  const [rdate, setRdate] = useState(location.state.rdate);
  const [slotInfo, setSlotInfo] = useState([
    {
      teacherName: "Loading...",
      studentName: "Loading..",
      studentEmail: "Loading...",
      slot: "Loading..",
      teacherID: { name: "Loading.." },
    },
  ]);
  useEffect(async () => {
    if (!rdate) {
      setRdate(new Date());
    }
    if (!teacherID) {
      setTeacherID(0);
    }
    if (!teacherName) {
      setTeacherName("None");
    }
    await fetch(`${base_route}/adminMain/t-slots/slotByDateAvaliable`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rdate: new Date(rdate).toDateString(),
        grade: Grade,
        teacherID: teacherID,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result", result);
        setSlotInfo(result);
        let newTeachers = [];
        result.forEach((c) => {
          if (!newTeachers.includes(c.teacherName))
            newTeachers.push(c.teacherName);
        });
        newTeachers.unshift("All");
        setTeachers(newTeachers);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [rdate, teacherID]);
  const handleTeacherClick = (teacher) => {
    setTeacherName(teacher);
    teachersOBJ.forEach((c) => {
      if (c.name == teacher) {
        setTeacherID(c.id);
      }
    });
  };
  const handleGradeClick = (grade) => {
    setGrade(grade);
  };
  return (
    <Base>
      <div className="mainAvail d-flex flex-column">
        <div className="threeAvail d-flex">
          <h2
            className="dateAvail m-2 mt-4"
            // style={{ position: "relative", left: "10%" }}
          >
            <ArrowBackIcon
              className="slot_date_controller "
              onClick={() => {
                setRdate(new Date(new Date().setDate(rdate.getDate() - 1)));
              }}
            ></ArrowBackIcon>{" "}
            {new Date(rdate).toDateString()}{" "}
            <ArrowForwardIcon
              className="slot_date_controller "
              onClick={() => {
                setRdate(new Date(new Date().setDate(rdate.getDate() + 1)));
              }}
            ></ArrowForwardIcon>
          </h2>
          <div className="twoAvail d-flex">
            <div className="gradeDiv d-flex">
              <div className="m-2 ">
                <TkDropDown
                  variant="warning"
                  label="Grade"
                  style={{ width: "100px" }}
                  _onClick={handleGradeClick}
                  value={Grade}
                  items={["All", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                />
              </div>
              <div className="m-2">
                <TkDropDown
                  variant="info"
                  label="Teachers"
                  style={{ width: "100px" }}
                  _onClick={handleTeacherClick}
                  value={teacherName}
                  items={teachers}
                />
              </div>
            </div>
            <div
              className="slot_calendar d-flex flex-column mt-2"
              // style={{ position: "relative", left: "40%" }}
            >
              <label htmlFor="">Date</label>
              <input
                type="date"
                value={rdate}
                onChange={(e) => setRdate(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
        <div className="back w-25">
          <div
            className="btn btn-dark m-2"
            onClick={() => {
              history.push({
                pathname: "/dashboard/t-slot",
                state: {
                  rdate: rdate,
                  teacherName: teacherName,
                  grade: Grade,
                  teacherID: teacherID,
                  teachersOBJ: teachersOBJ,
                  teachers: teachers,
                },
              });
            }}
          >
            Back
          </div>
        </div>
        <div className=" availTable h1 container w-100">
          <table class="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">Teacher Name</th>
                <th scope="col">Slot Time</th>
              </tr>
            </thead>
            <tbody>
              {slotInfo.map((item, index) => (
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td>{item.teacherName}</td>
                  <td>{item.slot.slice(16)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Base>
  );
};

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
import ProfileInput from "../ProfileInput";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import TkDropDown from "../../Common/Components/DropDown";
import BackNavigation from "../../Common/Components/BackNavigation";
import "../../styles/studentInfo.css";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Base from "../../Common/Components/Base";
import { base_route } from "../../backend";
import "./styles/os-registration.css";

const OldStudentInfo = () => {
  const location = useLocation();
  let currentStudentEmail;
  let currentMode;
  if (location.state && location.state.student) {
    currentMode = location.state.mode;
    currentStudentEmail = location.state.student.email;
  }
  const [mode, setMode] = useState(currentMode);
  const [teacherList, setteacherList] = useState([]);
  const [slotList, setSlotList] = useState([]);
  const [time, setTime] = useState("06:00 AM");
  const [trialDate, setTrialDate] = useState();
  const [trialTime, setTrialTime] = useState();
  const [day, setDay] = useState("FRI");
  const [hasSubmit, setHasSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("91");
  const [loading, setLoading] = useState(true);
  const [studentList, setStudentList] = useState([]);
  const [studentEmail, setStudentEmail] = useState(currentStudentEmail);
  const [values, setValues] = useState({
    studentName: "",
    contact: "91",
    email: "",
    grade: 0,
    classCompleted: 0,
    level: "",
    packageName: "",
    remainingClasses: 0,
    teacherCode: "",
    parentName: "",
    studentEmail: "",
    schoolName: "",
    packageAmount: "",
    counsellorName: "",
  });
  const {
    classCompleted,
    remainingClasses,
    teacherCode,
    parentName,
    schoolName,
    counsellorName,
    studentName,
    grade,
    packageName,
    level,
    packageAmount,
  } = values;
  useEffect(() => {
    fetchDetails(studentEmail);
  }, []);
  useEffect(() => {
    if (packageName == "Beginner") {
      setValues({ ...values, remainingClasses: 8 - classCompleted });
    } else if (packageName == "Advance") {
      setValues({ ...values, remainingClasses: 48 - classCompleted });
    } else if (packageName == "Master") {
      setValues({ ...values, remainingClasses: 144 - classCompleted });
    } else {
      setValues({ ...values, remainingClasses: 8 - classCompleted });
    }
  }, [packageName, classCompleted]);

  const handleOnChange = (field) => (event) => {
    setValues({ ...values, [field]: event.target.value });
  };

  const handleDropdown = (field, selected) => {
    setValues({ ...values, [field]: selected });
  };

  const handleSave = () => {
    setHasSubmit(true);
    setMessage("Please Wait..");
    // console.log(slotList,packageName,level,teacherCode,remainingClasses,classCompleted,)
    // if (
    //   slotList.length < 1 ||
    //   !packageName ||
    //   !level ||
    //   !teacherCode ||
    //   !remainingClasses ||
    //   !packageAmount ||
    //   !counsellorName ||
    //   !schoolName ||
    //   !grade ||
    //   !studentName ||
    //   !parentName
    // ) {
    //   alert("Enter All the fields");
    //   setHasSubmit(false);
    //   setMessage("");
    //   return;
    // }
    if (remainingClasses < 0) {
      alert("Remaining Classes can't be Negative");
      return;
    }
    var slots = Array.from(new Set(slotList));
    console.log(
      studentName,
      contact,
      studentEmail,
      grade,
      classCompleted,
      level,
      packageName,
      remainingClasses,
      packageAmount,
      schoolName,
      counsellorName,
      teacherCode,
      trialDate,
      time,
      slots,
      parentName
    );
    let URL = `${base_route}/adminMain/os-registration/addUser`;
    if (mode == "edit") {
      URL = `${base_route}/adminMain/os-registration/updateUser`;
    }
    fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        studentName,
        contact,
        studentEmail,
        grade,
        classCompleted,
        level,
        packageName,
        remainingClasses,
        packageAmount,
        schoolName,
        counsellorName,
        teacherCode,
        trialDate,
        trialTime: time,
        slots,
        parentName,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.message) {
          setValues([
            {
              studentName: "",
              contact: 0,
              email: "",
              grade: 0,
              classCompleted: 0,
              level: "",
              packageName: "",
              remainingClasses: 0,
              teacherCode: "",
              parentName: "",
            },
          ]);
          setSlotList([]);
          setContact("");
          alert(resp.message);
          setHasSubmit(false);
          setMessage("");
        } else {
          alert(resp.error);
          setHasSubmit(false);
          setMessage("");
        }
      })
      .catch((err) => {
        toast("Error");
      });
  };

  const handleDayList = (day) => {
    setDay(day);
  };
  const handleTimeClick = (time) => {
    setTime(time);
  };
  const handleTrialTimeClick = (time) => {
    setTrialTime(time);
  };
  const handleAddBtnClick = () => {
    const slot = {
      day: day,
      time: time,
    };
    setSlotList((oldArr) => [...oldArr, slot]);
  };

  const popSlotItem = (index) => {
    setSlotList(slotList.filter((item, i) => i !== index));
  };
  const hasClicked = () => {
    if (hasSubmit) {
      return "save_button_deactive";
    } else {
      return "save_button";
    }
  };
  useEffect(async () => {
    setLoading(true);
    await fetch(`${base_route}/teacher/allTeachers`)
      .then((res) => res.json())
      .then((result) => {
        setteacherList(result.map((item) => item.employeeCode));
      });
    await fetch(`${base_route}/teacher/allStudents`)
      .then((res) => res.json())
      .then((result) => {
        let emails = result.map((item) => item.email);
        emails = [...new Set(emails)];
        setStudentList(emails);
      });
    setLoading(false);
  }, []);
  const fetchDetails = async (email) => {
    setLoading(true);
    if (!email) {
      setValues({
        studentName: "",
        contact: 0,
        email: "",
        grade: 0,
        classCompleted: 0,
        level: "",
        packageName: "",
        remainingClasses: 0,
        teacherCode: "",
        parentName: "",
        studentEmail: "",
        schoolName: "",
        packageAmount: "",
        counsellorName: "",
      });
      setContact("");
      return;
    }
    await fetch(`${base_route}/adminMain/os-registration/getUser/${email}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log(resp);
        if (resp.error) alert(resp.error);
        else {
          let {
            studentName,
            studentEmail,
            parentName,
            contact,
            schoolName,
            slot,
            grade,
            packageName,
            level,
            packageAmount,
            teacherCode,
            counsellorName,
            remainingClasses,
            classCompleted,
          } = resp.student;
          setStudentEmail(resp.student.studentEmail);
          setContact(resp.student.contact);
          setTrialTime(resp.student.time);
          setTrialDate(resp.student.date);
          setSlotList(resp.student.slotList);
          setValues({
            ...values,
            studentName,
            parentName,
            contact,
            schoolName,
            slot,
            grade,
            packageName,
            level,
            packageAmount,
            teacherCode,
            counsellorName,
            classCompleted,
            remainingClasses,
          });
        }
      });
    setLoading(false);
  };
  return (
    <Base>
      {/* {loading ? (
        <div style={{ position: "fixed", top: "50%", left: "50%" }}>
          <CircularProgress size={150} />
        </div>
      ) : ( */}
      <>
        <BackNavigation />
        <form>
          <div className="p_60  d-flex flex-column">
            <div className="form-row flexbox d-flex">
              <div class="form-group os-reg col ">
                {/* <label for="studentEmail">Email</label>
              <input
                type="email"
                name="studentEmail"
                class="form-control"
                id="inputEmail4"
                placeholder="Email"
                value={email}
                onChange={handleOnChange("email")}
              /> */}
                <div className="profile_info">
                  <Autocomplete
                    id="combo-box-demo"
                    options={studentList}
                    onInputChange={(event, newValue) => {
                      setStudentEmail(newValue);
                      // setValues({ ...values, ["studentEmail"]: newValue });
                    }}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      fetchDetails(newValue);
                    }}
                    inputValue={studentEmail}
                    getOptionLabel={(option) => option}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student Email"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
              <div class="form-group os-reg col ">
                <label for="studentEmail">Student Name</label>
                <input
                  type="text"
                  name="studentName"
                  class="form-control"
                  placeholder="Student Name"
                  value={studentName}
                  onChange={handleOnChange("studentName")}
                />
              </div>
            </div>
            <div className="form-row flexbox d-flex">
              <div class="form-group os-reg col ">
                <label for="studentEmail">Parent Name</label>
                <input
                  type="text"
                  name="parentName"
                  class="form-control"
                  id="inputEmail4"
                  placeholder="Parent Name"
                  onChange={handleOnChange("parentName")}
                  value={parentName}
                />
              </div>
              <div class="form-group os-reg col ">
                <label>Parent's Contact </label>
                <PhoneInput
                  value={values.contact}
                  onChange={(value) => {
                    setValues({ ...values, ["contact"]: value });
                    setContact(value);
                  }}
                  name="phone"
                  autoComplete="on"
                  maxlength="15"
                  defaultCountry="IN"
                  name="number"
                  autoComplete="on"
                  className="w-25 phoneOS"
                />
              </div>
            </div>
            <div className="form-row flexbox d-flex">
              <div class="form-group os-reg col ">
                <label for="studentEmail">School Name</label>
                <input
                  type="text"
                  name="schoolName"
                  class="form-control"
                  id="inputEmail4"
                  placeholder="School Name"
                  value={schoolName}
                  onChange={handleOnChange("schoolName")}
                />
              </div>
              <div class="form-group os-reg col ">
                <label for="studentEmail">Sales Counsellor Name</label>
                <input
                  type="text"
                  name="counsellorName"
                  class="form-control"
                  placeholder="Sales Counsellor Name"
                  value={counsellorName}
                  onChange={handleOnChange("counsellorName")}
                />
              </div>
            </div>
            <div className="form-row flexbox d-flex">
              <div class="form-group os-reg col ">
                <label>Trail Date </label>
                <input
                  type="date"
                  name="classCompleted"
                  class="form-control"
                  value={trialDate}
                  onChange={(event) => {
                    console.log(event.target.value);
                    setTrialDate(event.target.value);
                  }}
                  id="inputEmail4"
                  placeholder="Trail Date"
                  readonly
                />
              </div>
              <div class="form-group os-reg col ">
                <div className="profile_info">
                  <TkDropDown
                    variant="light"
                    label="Trial Time"
                    style={{ width: "90%", marginTop: "3px" }}
                    placeholder="Trial Time"
                    _onClick={handleTrialTimeClick}
                    value={trialTime}
                    items={[
                      "06:00 AM",
                      "07:00 AM",
                      "08:00 AM",
                      "09:00 AM",
                      "10:00 AM",
                      "11:00 AM",
                      "12:00 PM",
                      "1:00 PM",
                      "2:00 PM",
                      "3:00 PM",
                      "4:00 PM",
                      "5:00 PM",
                      "6:00 PM",
                      "7:00 PM",
                      "8:00 PM",
                      "9:00 PM",
                      "10:00 PM",
                      "11:00 PM",
                      "12:00 AM",
                      "01:00 AM",
                      "02:00 AM",
                      "03:00 AM",
                      "04:00 AM",
                      "05:00 AM",
                    ]}
                  />
                </div>
              </div>
            </div>

            <div className="form-row flexbox d-flex ">
              <div class="form-group os-reg w-25 d-flex">
                <div className="profile_info">
                  <TkDropDown
                    variant="light"
                    label="Grade"
                    placeholder="Grade"
                    style={{ width: "100%" }}
                    _onClick={(selected) => handleDropdown("grade", selected)}
                    value={grade}
                    items={[
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "11",
                      "12",
                    ]}
                  />
                </div>
              </div>
              <div class="form-group os-reg d-flex w-25">
                <div className="profile_info">
                  <TkDropDown
                    variant="light"
                    label="Package Name"
                    placeholder="Package Name"
                    style={{ width: "100%" }}
                    _onClick={(selected) =>
                      handleDropdown("packageName", selected)
                    }
                    value={packageName}
                    items={["Beginner", "Advance", "Master", "Ultimate"]}
                  />
                </div>
              </div>
              <div class="form-group os-reg col w-25">
                <div className="profile_info">
                  <TkDropDown
                    variant="light"
                    label="Level"
                    placeholder="Level"
                    style={{ width: "100%" }}
                    _onClick={(selected) => handleDropdown("level", selected)}
                    value={level}
                    items={["Level 1", "Level 2", "Level 3"]}
                  />
                </div>
              </div>
            </div>
            <div className="form-row flexbox d-flex">
              <div class="form-group os-reg col">
                <label for="studentEmail">Class Completed</label>
                <input
                  type="text"
                  name="classCompleted"
                  class="form-control"
                  placeholder="Class Completed"
                  value={classCompleted}
                  onChange={handleOnChange("classCompleted")}
                />
              </div>
              <div class="form-group os-reg col ">
                <label>Remaining Classes</label>
                <input
                  type="text"
                  name="classCompleted"
                  class="form-control"
                  placeholder="Remaining Classes"
                  readonly
                  value={remainingClasses}
                  onChange={() => {
                    return; /*handleOnChange("remainingClasses")*/
                  }}
                />
              </div>
            </div>
            <div className="form-row flexbox d-flex">
              <div class="form-group os-reg col ">
                <label>Package Amount</label>
                <input
                  type="text"
                  name="classCompleted"
                  class="form-control"
                  placeholder="Package Amount"
                  readonly
                  value={packageAmount}
                  onChange={handleOnChange("packageAmount")}
                />
              </div>
              <div class="form-group os-reg col  d-flex">
                <div className="d-flex flex-column justify-content-end align-items-start">
                  <div>Bill Attachment</div>
                  <label
                    for="billAttachment"
                    style={{
                      border: "2px solid",
                      background: "rgb(187, 185, 185)",
                    }}
                  >
                    ADD file here
                  </label>
                  <input
                    type="file"
                    name="billAttachment"
                    id="billAttachment"
                    multiple
                  />
                </div>
              </div>
            </div>
            <div className="form-row flexbox d-flex">
              <div class="form-group os-reg col ">
                <div className="d-flex  double_drop">
                  <div className="profile_info ">
                    <label htmlFor="">Paid Class:</label>
                    <TkDropDown
                      variant="info"
                      label="Day"
                      style={{ width: "90%" }}
                      placeholder="Day"
                      _onClick={handleDayList}
                      value={day}
                      items={["FRI", "SAT", "SUN", "MON", "TUE", "WED", "THU"]}
                    />
                  </div>
                  <div className="profile_info">
                    <label htmlFor="">&nbsp; </label>

                    <TkDropDown
                      variant="info"
                      label="Time"
                      style={{ width: "90%", marginTop: "3px" }}
                      placeholder="Time"
                      _onClick={handleTimeClick}
                      value={time}
                      items={[
                        "06:00 AM",
                        "07:00 AM",
                        "08:00 AM",
                        "09:00 AM",
                        "10:00 AM",
                        "11:00 AM",
                        "12:00 PM",
                        "1:00 PM",
                        "2:00 PM",
                        "3:00 PM",
                        "4:00 PM",
                        "5:00 PM",
                        "6:00 PM",
                        "7:00 PM",
                        "8:00 PM",
                        "9:00 PM",
                        "10:00 PM",
                        "11:00 PM",
                        "12:00 AM",
                        "01:00 AM",
                        "02:00 AM",
                        "03:00 AM",
                        "04:00 AM",
                        "05:00 AM",
                      ]}
                    />
                  </div>
                  <div className="profile_info">
                    <label htmlFor="">&nbsp;</label>
                    <div className="profile_info_add">
                      <span
                        className="profile_info_add_btn"
                        onClick={handleAddBtnClick}
                      >
                        <AddCircleIcon fontSize="large"></AddCircleIcon>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="profile_info_slot_list">
                  {slotList.map((item, index) => {
                    return (
                      <div style={{ marginTop: "0.6rem" }}>
                        <span className="badge_item" key={index}>
                          <span className="mr-1">
                            {" "}
                            {item.day} - {item.time}
                          </span>
                          <RemoveCircleIcon
                            style={{ fontSize: "30px", color: "orange" }}
                            onClick={() => popSlotItem(index)}
                          />
                        </span>{" "}
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div class="form-group os-reg col ">
                <div className="profile_info">
                  <TkDropDown
                    variant="light"
                    label="Teacher's Employee Code"
                    style={{ width: "100%" }}
                    _onClick={(selected) =>
                      handleDropdown("teacherCode", selected)
                    }
                    value={teacherCode}
                    items={teacherList}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mb-5">
              <div
                className=" btn btn-success "
                onClick={() => handleSave()}
                disabled={hasSubmit}
                type="submit"
                // className={hasClicked()}
              >
                Save Changes
              </div>
            </div>
          </div>
        </form>
      </>
      {/* )} */}
    </Base>
  );
};

export default OldStudentInfo;

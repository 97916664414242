import React, { Fragment, useEffect, useState } from 'react'
import HomeComponent from './components/Home'
import { BrowserRouter as Router } from 'react-router-dom'
import PrivateRoutes from './routes/PrivateRoutes'
import PublicRoutes from './routes/PublicRoutes'
import { getProfile, isAuthenticated } from './components/Login/LoginAPICalls'

const App = () => {
  return (
    // <HomeComponent/>
 
    <Router>
      <PublicRoutes />
      <PrivateRoutes />
    </Router>
  )
}

export default App

import { React, useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState, ContentState, convertToRaw } from "draft-js";
// import {Editor, EditorState, AtomicBlockUtils} from 'draft-js';
import { convertToHTML } from "draft-convert";
import Snackbar from "@material-ui/core/Snackbar";
import "./blog.css";
import MuiAlert from "@material-ui/lab/Alert";
import { CategorySharp } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { set } from "lodash";
import JoditEditor from "jodit-react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
// import XMLHttpRequest from "xhr2";
// import Editor from '@draft-js-plugins/editor';

import createImagePlugin from "@draft-js-plugins/image";
const imagePlugin = createImagePlugin();

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}
function Blog(props) {
	const classes = useStyles();

	const editor = useRef(null);
	const [content, setContent] = useState("");

	const [title, setTitle] = useState("");
	const [category, setCategory] = useState([]);
	const [catgoryOption, setCategoryOption] = useState([]);
	const [author, setAuthor] = useState("");
	// const [seoTitle, setSeoTitle] = useState("");
	const [seoTitle, setSeoTitle] = useState("");
	const [metaDis, setMetaDis] = useState("");
	const [shortDis, setShortDis] = useState("");
	// const [seoDis]
	const [link, setLink] = useState("");
	const [readTime, setReadTime] = useState("");
	const [tags, setTag] = useState("");
	const [file, setFile] = useState(null);
	const [links, setLinks] = useState([]);
	const [submit, setSubmit] = useState(false);
	// const [content, setContent] = useState("");
	// const [description, setDescription] = useState({ content: "" });
	// let lol = stateFromHTML(content);
	const [description, setDescription] = useState({
		content: EditorState.createEmpty(),
	});
	// const [authorImage, setAuthorImage] = useState(null);
	const [authorImage, setAuthorImage] = useState("");
	const [open, setOpen] = useState(false);
	const [date, setDate] = useState("");
	const [attachment, setAttachment] = useState(null);
	const [edit, setEdit] = useState(false);
	const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
	};

	const theme = useTheme();

	let { id } = useParams();

	function uploadImageCallBack(file) {
		return new Promise((resolve, reject) => {
			var xhr = new XMLHttpRequest();

			const data = new FormData();
			data.append("image", file);
			axios
				.post("/blog/uploadimg", data)

				.then((res) => {
					console.log(JSON.parse(res.data));
					const response = JSON.parse(xhr.responseText);
					console.log(response);
					resolve(response);

					console.log(data);
					console.log(res);
				})
				.catch((err) => console.log(err));
		});
	}
	// let checkEdit
	useEffect(() => {
		let checkEdit = id ? true : false;
		console.log(id, checkEdit);
		console.log(checkEdit, "editing check");
		setEdit(checkEdit);
		if (checkEdit) {
			console.log("wwwwwwwwww");
			axios
				.get(`/blog/viewById?id=${id}`)
				.then((res) => {
					// let {
					// 	title,
					// 	description,
					// 	attachment,
					// 	readTime,
					// 	authorImage,
					// 	author,
					// } = res.data;
					let Title = res.data.title;
					let Description = res.data.description;
					let Attachment = res.data.attachment;
					let ReadTime = res.data.readTime;
					let Author = res.data.author;
					let AuthorImage = res.data.authorImage;
					let Category = res.data.category;
					let short = res.data.short_discription;
					let link = res.data.link;
					let seo_title = res.data.seo_title;
					let meta_dis = res.data.seo_metadiscription;
					console.log(res.data);
					setTitle(Title);
					setContent(Description);
					// setDescription({
					// 	content: EditorState.createWithContent(stateFromHTML(Description)),
					// });
					// setContent();
					// setContent("<p>sdssdds</p>");
					let data = [];
					let categoryIds = [];
					Category.forEach((item) => {
						// let temp = catgoryOption.filter((lol) => {
						// 	if (lol.name === item) return lol.name;
						// });
						// console.log(item);
						// catgoryOption.forEach(lol =>{
						// 	if(lol._id === item){
						// 		categoryIds.push(lol.name)
						// 	}
						// })
						data.push(
							...catgoryOption.filter((lol) => {
								// console.log(lol)
								if (lol._id === item) {
									console.log(lol);
									return lol;
								}
							})
						);
					});
					data.forEach((item) => {
						categoryIds.push(item.name);
					});
					console.log(categoryIds);
					setSeoTitle(seo_title);
					setLink(link);
					setShortDis(short);
					setDescription();
					setReadTime(ReadTime);
					setAuthor(Author);
					setAttachment(Attachment);
					setAuthorImage(AuthorImage);
					setCategory(categoryIds);
					setMetaDis(meta_dis);
				})
				.catch((err) => console.log(err));
		}

		axios
			.get("/category/viewall")
			.then((res) => {
				setCategoryOption(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	// console.log(category, "cotoryies");
	const handleOnEditorStateChange = (content) => {
		console.log("post click", content);

		setDescription({ ...description, content });
	};

	const onFileChange = (event) => {
		// Update the state
		authorImage(event.target.files[0]);
	};

	const handleChange = (e) => {
		// e.persist();
		// console.log(e.target.value, "cat val ");
		// // setCategory(e.target.value);
		// let data = category;
		// data.push(e.target.value);
		// console.log(data);
		// // console.log(typeof data);
		// let list = [];
		// data.forEach((item) => {
		// 	let temp = [];
		// 	temp = catgoryOption.filter((check) => {
		// 		if (item === check._id) return check.name;
		// 	});
		// 	list.push(temp);
		// });
		// setCategory(list);
		// console.log("object");
		// let data = catgoryOption.filter((item) => {
		// 	if (e.target.value === item.name) return item._id;
		// });
		setCategory(e.target.value);
	};
	const handleImageChange = (e) => {
		setFile(e.target.files[0]);
	};

	const handleImageUpload = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("image", file, file.name);
		axios
			.post("/blog/uploadimg", formData)
			.then((res) => {
				console.log(res.data);
				let data = links;
				data.push(res.data.imageurl);
				console.log(data);
				setLinks(data);
				// setLinks.append(res.data.imageurl);
			})
			.catch((err) => console.log(err));
	};

	// console.log(edit, "welc edit ing the ");
	const handleSubmit = (e) => {
		e.preventDefault();

		// const currentContentAsHTML = convertToRaw(
		// 	description.content.getCurrentContent()
		// );
		// const markup = draftToHtml(
		// 	currentContentAsHTML,
		// 	hashtagConfig,
		// 	directional,
		// 	customEntityTransform
		// );
		// const formData = new FormData();
		let data = [];
		let categoryIds = [];
		category.forEach((item) => {
			// let temp = catgoryOption.filter((lol) => {
			// 	if (lol.name === item) return lol.name;
			// });
			data.push(
				...catgoryOption.filter((lol) => {
					if (lol.name === item) return lol;
				})
			);
		});
		data.forEach((item) => {
			categoryIds.push(item._id);
		});
		let checkEdit = id ? true : false;

		if (checkEdit) {
			console.log("Edit Blog");
			const formData = {
				title,
				// description: currentContentAsHTML,
				seo_title: seoTitle,
				link,
				short_discription: shortDis,
				seo_metadiscription: metaDis,
				description: content,
				authorImage,
				attachment,
				category: categoryIds,
				readTime,
				author,
			};

			console.log("form", formData);
			axios
				.post(`/blog/editBlog?id=${id}`, formData)
				.then((res) => {
					console.log("res", res.data);
					// res.send(res.data);
					props.history.push("/dashboard/blog");
				})
				.catch((err) => {
					console.log(err);
				});
			// setDescription("");
			setCategory("");
			setAuthor("");
			setTitle("");
			setReadTime("");
		} else {
			const formData = {
				title,
				// description: currentContentAsHTML,
				seo_title: seoTitle,
				link,
				short_discription: shortDis,
				seo_metadiscription: metaDis,
				description: content,
				authorImage,
				attachment,
				category: categoryIds,
				readTime,
				author,
			};
			// console.log("form", formData);

			// console.log(attachment, "atta");
			// const formData = {
			//   title,
			//   description: currentContentAsHTML,

			//   category: category._id,
			//   readTime,
			//   author,
			// };
			// console.log(typeof formData);
			// console.log(categoryIds);
			// console.log("form", formData);
			axios
				.post("/blog/createblog", formData)
				.then((res) => {
					console.log("res", res.data);
					// res.send(res.data);
					props.history.push("/dashboard/blog");
				})
				.catch((err) => {
					console.log(err);
				});
			setDescription("");
			setCategory("");
			setAuthor("");
			setTitle("");
			setReadTime("");
		}
	};
	return (
		<div className="w_85 mx-auto">
			{/* {console.log("blog in ")} */}
			<div
				className="ticket_section "
				style={{ margin: "40px 10px 10px 280px" }}
			>
				<div className="ticket_top">
					<h1>{edit ? "Edit Blog" : "Create Blog"}</h1>
				</div>
				<div className="ticket_info">
					<form onSubmit={handleImageUpload}>
						<label for="image" className="imageBlog">
							Add Image(image to url converter)
						</label>
						<input
							onChange={handleImageChange}
							type="file"
							id="image"
							name="image"
							accept="image/png, image/gif, image/jpeg"
						/>
						{/* <button type="submit">Add Image</button> */}
						<input
							type="submit"
							className="blogSubmit"
							value="Add Image"
							style={{ marginTop: "30px" }}
						/>

						{links.length > 0 &&
							links.map((item) => (
								<div>
									<a href={item} target="_blank" rel="noopener noreferrer">
										{item}
									</a>
								</div>
							))}
					</form>

					<form onSubmit={handleSubmit}>
						{/* <form> */}
						<div className="form-group ticket_desc">
							<label className="blogInput">Author</label>
							<input
								type="text"
								name="author"
								placeholder="Enter Author"
								onChange={(e) => setAuthor(e.target.value)}
								value={author}
								required="True"
							/>
							<label className="blogInput">Title</label>
							<input
								type="text"
								name="title"
								placeholder="Enter Title"
								onChange={(e) => {
									let data = e.target.value;
									setTitle(data);

									data = data.replace(/\s+/g, "-").toLowerCase();
									// console.log(data.replace(" ", "-"));
									// console.log(data);
									setLink(data);
								}}
								value={title}
								required="True"
							/>

							<label className="blogInput">SEO Title</label>
							<input
								type="text"
								name="seo_title"
								placeholder="Enter SEO Title"
								onChange={(e) => setSeoTitle(e.target.value)}
								value={seoTitle}
								required="True"
							/>
							<label className="blogInput">
								Link generation(add "-" instead of space)
							</label>
							<input
								type="text"
								name="link"
								placeholder="Enter link"
								onChange={(e) => {
									// let temp = e.target.value;
									// let data = temp.filter((item) => {
									// 	if (item === " ") return "-";
									// });
									setLink(e.target.value);
								}}
								value={link}
								required="True"
							/>

							<label className="blogInput">Author Image</label>
							<input
								type="text"
								name="authorImage"
								id=""
								// onChange={(e) => setAuthorImage(e.target.files[0])}
								value={authorImage}
								onChange={(e) => setAuthorImage(e.target.value)}
								style={{ display: "block" }}
							/>
							{/* {edit && <p>Add author image even though added previously</p>}  */}
							<label className="blogInput">Thumbnail</label>
							<input
								type="text"
								name="attachment"
								id=""
								value={attachment}
								onChange={(e) => setAttachment(e.target.value)}
								// style={{wid }}
							/>
							<FormControl className={classes.formControl}>
								<InputLabel id="demo-mutiple-chip-label">Categories</InputLabel>
								<Select
									labelId="demo-mutiple-chip-label"
									id="demo-mutiple-chip"
									multiple
									value={category}
									onChange={handleChange}
									input={<Input id="select-multiple-chip" />}
									renderValue={(selected) => (
										<div className={classes.chips}>
											{selected.map((value) => (
												<Chip
													key={value}
													label={value}
													className={classes.chip}
												/>
											))}
										</div>
									)}
									MenuProps={MenuProps}
								>
									{catgoryOption.map((name) => (
										<MenuItem
											key={name}
											value={name.name}
											style={getStyles(name.name, category, theme)}
										>
											{name.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{/* <label className="blogInput">
								category
								<select
									name="category"
									value={category}
									onChange={handleChange}
								>
									<option value=""> Select </option>
									{catgoryOption.length > 0 &&
										catgoryOption.map((category) => {
											return (
												<option
													key={category._id}
													// onClick={(e) => {
													// 	let data = category;
													// 	data.push(e.target.value);
													// 	// console.log(data);
													// 	setCategory(data);
													// }}
													value={category._id}
												>
													{category.name}
												</option>
											);
										})}
								</select>
							</label>
							{category.map((item) => (
								<p>{item}</p>
							))} */}
							<label className="blogInput">SEO meta discription</label>
							<input
								type="text"
								name="seoDis"
								placeholder="Enter SEO Discription"
								onChange={(e) => setMetaDis(e.target.value)}
								value={metaDis}
								required="True"
							/>
							<label className="blogInput">Short Discription</label>
							<input
								type="text"
								name="shortDis"
								placeholder="Enter Short Discription"
								onChange={(e) => setShortDis(e.target.value)}
								value={shortDis}
								required="True"
							/>
							<label className="blogInput" style={{ borderRadius: "2px" }}>
								Content
								{/* <Editor
									editorState={description.content}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={handleOnEditorStateChange}
									plugins={[imagePlugin]}
									toolbar={{
										inline: { inDropdown: true },
										list: { inDropdown: true },
										textAlign: { inDropdown: true },
										link: { inDropdown: true },
										history: { inDropdown: true },
										image: {
											uploadCallback: uploadImageCallBack,
											alt: { present: true, mandatory: true },
										},
									}}
								/> */}
								<JoditEditor
									ref={editor}
									value={content}
									config={config}
									tabIndex={1} // tabIndex of textarea
									onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
									onChange={(newContent) => {}}
								/>
							</label>
							<br />
							<label className="blogInput">Read Time</label>
							{/* <input
								type="text"
								name="readTime"
								placeholder="Enter Read time"
								onChange={(e) => setReadTime(e.target.value)}
								value={readTime}
								// required="True"
							/> */}
							<select
								value={readTime}
								onChange={(e) => setReadTime(e.target.value)}
								className="blogDrop"
							>
								<option value="">None</option>
								<option value="5 Min Read">5 Min Read</option>
								<option value="6 Min Read">6 Min Read</option>
								<option value="7 Min Read">7 Min Read</option>
								<option value="8 Min Read">8 Min Read</option>
								<option value="9 Min Read">9 Min Read</option>
								<option value="10 Min Read">10 Min Read</option>
								<option value="11 Min Read">11 Min Read</option>
								<option value="12 Min Read">12 Min Read</option>
								<option value="13 Min Read">13 Min Read</option>
								<option value="14 Min Read">14 Min Read</option>
								<option value="15 Min Read">15 Min Read</option>
							</select>
						</div>
						<div className="blogSubmitContent">
							<input type="submit" className="blogSubmit" />
							<input
								type="button"
								value="Dummy_Submit"
								onClick={() => setSubmit(!submit)}
								className="blogSubmit"
							/>
							<Snackbar
								open={open}
								autoHideDuration={6000}
								onClose={handleClose}
							>
								<Alert onClose={handleClose} severity="success">
									This is a success message!
								</Alert>
							</Snackbar>
							<div
								className="blogOptions"
								style={{ display: submit ? "flex" : "none" }}
							>
								{/* <input type="button" /> */}
								<TextField
									id="datetime-local"
									label="Schedule"
									type="datetime-local"
									defaultValue=""
									// className={classes.textField}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<input type="button" value="Upload" />
								<input type="button" value="Save Draft" />
								{/* <input type="button" value="Submit" /> */}
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
export default withRouter(Blog);

import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import Base from "../Common/Components/Base";
import "../styles/notification.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { base_route } from "../backend";

const NotificationComponent = () => {
  const [title, setTitle] = useState("");
  const [des, setDes] = useState("");
  const [file, setFile] = useState(null);
  const [noti, setNoti] = useState([{ title: "", description: "", media: "" }]);

  const [render, setRender] = useState(0);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!title || !des) {
      alert("Enter all fields");
      return;
    }
    const data = new FormData();
    data.append("file", file);
    data.append("title", title);
    data.append("description", des);
    fetch(`${base_route}/notification/new`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp) {
          toast("Notification Added");
          setTitle("");
          setDes("");
          setFile(null);
          window.location.reload();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };
  const handleDownload = (link) => {
    if (link != "") {
      window.open(`${base_route}/${link}`, "_blank");
    }
  };

  const onDelete = async (ID) => {
    console.log(ID);
    await fetch(`${base_route}/notification/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ID }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.error) {
          toast(resp.error);
        } else {
          toast(resp.message);
          setRender((prev) => prev + 1);
        }
      })
      .catch((err) => console.log(err));
  };
  const getRegDate = (ID) => {
    if (ID != undefined) {
      var s = ID;
      var timehex = s.substring(0, 8);
      var secondsSinceEpoch = parseInt(timehex, 16);
      var t = new (secondsSinceEpoch * 1000).toString();
      return (
        new (secondsSinceEpoch * 1000).toDateString().substring(4, 10) +
        " " +
        "|" +
        " " +
        t.slice(15, 21)
      );
    } else return "";
  };
  useEffect(async () => {
    fetch(`${base_route}/notification/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        var data = resp.reverse();
        setNoti(data);
      })
      .catch((err) => console.log(err));
  }, [render]);

  return (
    <Base>
      <div className="w_85 mx-auto">
        <div className="ticket_section ">
          <div className="ticket_top">
            <h1>Teacher Notification</h1>
          </div>
          <div className="ticket_info">
            <form>
              <div className="form-group ticket_desc">
                <input
                  type="text"
                  value={title}
                  placeholder="Title Here"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group ticket_desc">
                <textarea
                  id=""
                  rows="10"
                  value={des}
                  placeholder="Description"
                  onChange={(e) => setDes(e.target.value)}
                />
              </div>
              <div className="form-group attach">
                <span>Attachments</span>
                <label>
                  Choose File
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    className="ticket_attach"
                  />
                </label>
              </div>
              <h6>{file ? file.name : null}</h6>
            </form>
          </div>
          <button
            onClick={(e) => handleSubmit(e)}
            className="btn btn-success m-3"
          >
            Submit
          </button>
        </div>
        <ToastContainer />
      </div>
      <>
        {noti.map((item, index) => (
          <div class="jumbotron jumbotron-fluid notification">
            <div class="container">
              <div className="noti_data">
                <div className="d-flex">
                  <h5 className="notiNo">{index + 1}</h5>
                  <h5> {item.title}</h5>
                </div>
                <span onClick={() => handleDownload(item.media)}>
                  {item.media != "" ? (
                    <>
                      <AttachFileIcon className="attach_file" />
                      File
                    </>
                  ) : (
                    <></>
                  )}
                </span>
                <div>
                  <h6>{getRegDate(item._id)}</h6>
                  <button
                    className="btn btn-danger"
                    onClick={() => onDelete(item._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>

              <div>
                <p class="lead noti_desc">{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </>
    </Base>
  );
};

export default NotificationComponent;

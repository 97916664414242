import Roles from "./Roles.js";
import Dashboard from "../components/Dashboard";
import StudentFeedback from "../components/student/StudentFeedback";
import StudentCurriculum from "../components/student/StudentCurriculum";
import StudentManager from "../components/Manager/StudentManager";
import TeacherManager from "../components/Manager/TeacherManager";
import StudentRegistration from "../components/student/StudentRegistration";
import TeacherRegistration from "../components/T-Registration/TeacherRegistration";
import Sales from "../components/Sales";
import Reminder from "../components/Reminder";
import Setting from "../components/Setting";
import Ticket from "../components/Ticket";
import Project from "../components/Project";
import Notification from "../components/Notification";
import User from "../components/User";
import ProfileAccess from "../components/ProfileAccessCom/ProfileAccess";
import UserInfo from "../components/UserInfo";
import ProfileAccessEdit from "../components/ProfileAccessCom/ProfileAccessEdit";
import StudentFreeVideo from "../components/student/StudentFeeVideo";
import TeacherSlot from "../components/T-Slot/TeacherSlot";
import TeacherSlotTime from "../components/T-Slot/TeacherSlotTime";
import StudentBookingInfo from "../components/S-Booking/StudentBookingInfo";
import StudentBooking from "../components/S-Booking/StudentBooking";
import StudentInfo from "../components/student/StudentInfo";
import TeacherCurriculum from "../components/T-Curriculum/TeacherCurriculum";
import ReferEarn from "../components/ReferEarn/ReferEarn";
import ReferEarnIndividual from "../components/ReferEarn/ReferEarnIndividual";
import Coupon from "../components/Coupon";
import TeacherEarning from "../components/T-Earning/TeacherEarning";
import TeacherEarningEdit from "../components/T-Earning/TeacherEarningEdit";
import TeacherLeave from "../components/T-Leave/TeacherLeave";
import OldStudentRegistration from "../components/os-registration/OldStudentInfo";
import FeedbackOnPaidStudent from "../components/FeedbackOnPaidStudent";
import ContactUs from "../components/Contact/ContactUs";
import ChangePassword from "../components/ChangePassword.js";
import TechSupport from "../components/TechSupport/TechSupport";
import TicketCreate from "../components/Ticket/TicketCreate";
import TicketSupport from "../components/Ticket/TicketSupport";
import Profile from "../components/Profile/Profile";
import PaidSlot from "../components/PaidSlot";
import ProfileEdit from "../components/Profile/ProfileEdit";
import Feedback from "../components/student/StudentFeedback";

//should be in prvt
import { BookedTable } from "../components/T-Slot/BookedTable";
import { AvailableTable } from "../components/T-Slot/AvailableTable";
import TableContent from "../components/TableContent.js";

const PublicRouteArray = [
  {
    component: Dashboard,
    path: "/",
    exact: true,
    title: "Dashboard",
    permission: [Roles.ADMIN, Roles.GUEST, "TESTER"],
  },
  // {
  //   component: Notification,
  //   path: "/notification",
  //   exact: true,
  //   title: "Notification",
  //   permission: [Roles.ADMIN, Roles.GUEST],
  // },
  {
    component: Setting,
    path: "/setting",
    exact: true,
    title: "Setting",
    permission: [Roles.ADMIN, Roles.GUEST, "TESTER", "SUPPORT"],
  },
  //   {
  //     component: Reminder,
  //     path: "/reminder",
  //     exact: true,
  //     title: "Reminder",
  //     permission: [Roles.ADMIN, Roles.GUEST],
  //   },

  //   {
  //     component: Project,
  //     path: "/project",
  //     exact: true,
  //     title: "Project",
  //     permission: [Roles.ADMIN, Roles.GUEST],
  //   },
  {
    component: ContactUs,
    path: "/setting/contactus",
    exact: true,
    title: "Contact Us",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: Profile,
    path: "/setting/profile",
    exact: true,
    title: "Profile",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: ProfileEdit,
    path: "/setting/profile/editprofile",
    exact: true,
    title: "Edit Profile",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: ChangePassword,
    path: "/setting/changepassword",
    exact: true,
    title: "Change Password",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TechSupport,
    path: "/setting/support/tech",
    exact: true,
    title: "Tech support",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TicketSupport,
    path: "/setting/support/ticket",
    exact: true,
    title: "Ticket support",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TicketCreate,
    path: "/setting/support/ticket/create",
    exact: true,
    title: "Tech support",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: Feedback,
    path: "/s-booking/info/studentfeedback",
    exact: true,
    title: "Feedback of Student",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: BookedTable,
    path: "/t-slot/bookedTable",
    exact: true,
    title: "Booked Table",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: AvailableTable,
    path: "/t-slot/availableTable",
    exact: true,
    title: "Available Table",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: StudentInfo,
    path: "/s-booking/info/student",
    exact: true,
    title: "Converted Info",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
  {
    component: TableContent,
    path: "/Payment",
    exact: true,
    title: "payment",
    permission: [Roles.ADMIN, Roles.GUEST],
  },
];

export default PublicRouteArray;

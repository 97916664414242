import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Base from "../../Common/Components/Base";
import TkButton from "../../Common/Components/TkButton";
import "./Profile.css";
import { MdLocationOn } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import certificate from "../../Assests/Certificate/certificate.png";
import { FiDownload } from "react-icons/fi";
import ProfileItem from "./ProfileItem";
import BackNavigation from "../../Common/Components/BackNavigation";
import { _arrayBufferToBase64 } from "../../Utils/Helper";
import { base_route } from "../../backend";
import {
  getProfilePic,
  updateProfile,
  updateProfilePic,
} from "./ProfileAPIcalls";
import { result } from "lodash";
const Profile = () => {
  //   const { userData, token } = useContext(UserContext)
  const [userData, setUserData] = useState({});

  const history = useHistory();
  const defaultPic =
    "https://cet.ecu.edu/construction/wp-content/pv-uploads/sites/270/2019/02/man-user_150.jpg";
  const [showModal, setshowModal] = useState(false);
  //const { userData, token, loading, setLoading } = useContext(UserContext);
  const [imageSrc, setimageSrc] = useState(null);
  const [mylang, setLang] = useState([]);
  const onModalBtnClick = () => {
    setshowModal(!showModal);
  };

  useEffect(() => {
    async function fetchUserData() {
      const data = await fetch(
        `${base_route}/adminMain/settings/profile/getProfile`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bearer-token": JSON.parse(sessionStorage.getItem("tk_jwt")).token,
          },
        }
      )
        .then((result) => result.json())
        .catch((err) => console.log(err));
      setUserData(data);
      console.log(userData);
    }
    fetchUserData();
  }, [userData]);

  useEffect(async () => {
    //console.log("mycourse", myCourses);
    //console.log("mu languages", mylang);
    if (userData != undefined || userData != null) {
      await setLang(userData.languages);
    }
  }, [userData]);

  useEffect(async () => {
    if (userData.profilePic != undefined) {
      await getProfilePic(userData.profilePic).then((binaryImg) => {
        let base64String =
          binaryImg != undefined && _arrayBufferToBase64(binaryImg.data);
        if (base64String) {
          const binary = `data:image/gif;base64,${base64String}`;
          setimageSrc(binary);
        } else {
          setimageSrc(defaultPic);
        }
      });
    }
  }, [userData.profilePic]);

  const userName = (name) => {
    if (name) {
      var words = name.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(" ");
    } else {
      return "";
    }
  };

  return (
    <Base>
      <BackNavigation />
      <div className="ml-3 mt-3">
        <div className="profile d-flex flex-sm-row flex-column-reverse w_90 mx-auto">
          <div className="profile_info col-lg-8 col-sm-7  col-12">
            <ProfileItem
              title={"About Me"}
              content={<p className="profile_text mt-2">{userData.about}</p>}
            />
            <ProfileItem
              title={"Hobbies"}
              content={<p className="profile_text mt-2">{userData.hobbies}</p>}
            />

            <ProfileItem
              title={"Languages Spoken"}
              content={
                <div className="d-flex flex-wrap">
                  {Array.from(new Set(mylang)).map((item) => {
                    return <span className="badge_item">{item}</span>;
                  })}
                </div>
              }
            />

            <ProfileItem
              title={"Certificate Earn"}
              content={
                <div className="certificate">
                  <img src={certificate} alt="" />
                  <span className="mt-3 "> Game Developer Certificate</span>
                  <TkButton
                    name={"Download"}
                    style={{
                      backgroundColor: "#00B23B",
                      width: 120,
                      height: 30,
                    }}
                    _class={"sm mt-2"}
                    // _onBtnClick={action.handle}
                    Ricon={<FiDownload />}
                  />
                </div>
              }
            />
          </div>
          <div className="profile_info_box d-flex flex-column col-lg-4 col-sm-4 col-12  align-items-center mx-auto">
            <img
              className="profile_pic"
              src={
                imageSrc === undefined || imageSrc === null
                  ? defaultPic
                  : imageSrc
              }
              alt="profile_img"
            />
            <TkButton
              name={"Edit Profile"}
              style={{ backgroundColor: "#00B23B" }}
              _onBtnClick={() =>
                history.push("/dashboard/setting/profile/editprofile")
              }
            />
            <h5 className="theme_color mt-4">
              <b>{userName(userData.name)}</b>
            </h5>
            <span className="theme_color">{userData.empcode}</span>
            <span className="theme_color mb-5">{userData.designation}</span>
            <div className="d-flex flex-column">
              <span>
                <MdLocationOn size={20} />
                <b className="px-1">{userData.city}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Profile;

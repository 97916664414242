import React, { useState, useEffect } from "react";
import "../styles/searchDetails.css";
import CloseIcon from "@material-ui/icons/Close";
import { base_route } from "../backend";

const ManagerInfo = ({ setIsClicked, setReportingTo }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${base_route}/adminMain/settings/user/allManagers`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log("resp", resp);
        setData(resp.reverse());
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="search_table_container">
      <h1>Managers</h1>
      <span className="feedback_close_icon">
        <CloseIcon fontSize="large" onClick={() => setIsClicked(false)} />
      </span>
      <table className="search_table">
        <tr>
          <th>Manager Name</th>
        </tr>
        {data.map((item) => {
          return (
            <tr
              onClick={() => {
                setReportingTo(item.email);
                setIsClicked(false);
              }}
            >
              <td>{item.name}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default ManagerInfo;

import React, { useEffect, useState } from "react";
import "../styles/User.css";
import { useHistory } from "react-router-dom";
import { searchAlgo, findCountry } from "../Utils/Helper.js";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import TkDropDown from "../Common/Components/DropDown";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Feedback from "../components/student/StudentFeedback";
import Base from "../Common/Components/Base";
import BackNavigation from "../Common/Components/BackNavigation";
import { base_route } from "../backend";

const User = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [searchFeild, setSearchFeild] = useState(false);
	// const [clickedIndex, setclickedIndex] = useState(null)
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(25);
	const [currentRecords, setcurrentRecords] = useState([]);
	const [isFeedbackClicked, setIsFeedbackClicked] = useState(false);
	const [sortBy, setSortBy] = useState("All");
	const history = useHistory();

	let indexOfLastRecord = currentPage * recordsPerPage;
	let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

	useEffect(() => {
		setcurrentRecords(users.slice(indexOfFirstRecord, indexOfLastRecord));
	}, [currentPage, recordsPerPage, users]);

	useEffect(() => {
		searchFeild && search !== ""
			? setcurrentRecords(searchAlgo(search, users))
			: setcurrentRecords(users.slice(indexOfFirstRecord, indexOfLastRecord));
	}, [search, searchFeild]);

	useEffect(() => {
		sortBy !== ""
			? setcurrentRecords(searchAlgo(sortBy, users))
			: setcurrentRecords(users.slice(indexOfFirstRecord, indexOfLastRecord));
	}, [sortBy]);

	useEffect(() => {
		loadUsers();
	}, []);

	const onRecordPerPageClick = (numberOfRecord) => {
		setRecordsPerPage(numberOfRecord);
	};

	const handleClick = (type, item) => {
		if (type == "add") {
			history.push({
				pathname: "/dashboard/user/info",
				state: { type: type },
			});
		} else {
			history.push({
				pathname: "/dashboard/user/info",
				state: { type: "edit", data: item },
			});
		}
	};
	const loadUsers = () => {
		setLoading(true);
		fetch(`${base_route}/admin/settings/user/allUsers`, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setUsers(resp.reverse());
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};
	const handleOnRowClick = (index) => {
		history.push(`/dashboard/user/${index}`);
	};

	return (
		<div>
			<Base>
				<BackNavigation />
				<div className="registration_actions">
					<div
						className="registration_action_item"
						onClick={() => handleClick("add", {})}
					>
						<AddIcon style={{ color: "#36ff6b" }} />
						Add
					</div>
					{/* <div className="registration_action_item">
            <CloseIcon style={{ color: "#e82525" }} />
            Delete
          </div> */}
					<div
						className="registration_action_item"
						onClick={() => setSearchFeild(!searchFeild)}
					>
						<SearchIcon style={{ color: "#25a1e8" }} />
						Search
					</div>
					<div className="registration_action_item">
						<ArrowDropDownIcon />
						More Action
					</div>
				</div>
				{searchFeild && (
					<div className="d-flex align-items-center justify-content-center mt-3">
						<TextField
							label="Search by Anything"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment>
										<IconButton>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
				)}
				<div className="registration_tabel_action">
					<div className="d-flex">
						<span>Records per page</span>{" "}
						<TkDropDown
							style={{ width: "80px", height: "30px", marginLeft: "10px" }}
							_onClick={onRecordPerPageClick}
							value={recordsPerPage}
							items={["25", "50", "100", "150", "200"]}
						/>
					</div>
					<div className="d-flex ">
						<NavigateBeforeIcon
							onClick={() =>
								currentPage === 1 ? null : setCurrentPage(currentPage - 1)
							}
						/>
						{indexOfFirstRecord + 1} - {indexOfLastRecord} / {users.length}
						<NavigateNextIcon
							onClick={() =>
								indexOfLastRecord > users.length
									? null
									: setCurrentPage(currentPage + 1)
							}
						/>
						<div className="registration_action_item" onClick={loadUsers}>
							<RefreshIcon style={{ color: "#25a1e8" }} />
							Refresh
						</div>
					</div>
					<div className="registration_action_item ">
						<TkDropDown
							style={{ width: "120px", height: "30px", marginLeft: "10px" }}
							_onClick={setSortBy}
							value={sortBy}
							items={["All", "Active", "Inactive"]}
						/>
					</div>
				</div>
				{/* <div>
        <Navbar bg="light" expand="lg" className="mb-3">
        <Navbar.Brand href="#home">{title}</Navbar.Brand>
          </Navbar>
        </div> */}
				<table id="users">
					<thead>
						<tr>
							<th>Sr.No</th>
							<th>Emp.code</th>
							<th>Name</th>
							<th>Profile</th>
							<th>Phone No.</th>
							<th>Email Id</th>
							<th>DOJ</th>
							<th>Reporting To</th>
							<th>User Status</th>
						</tr>
					</thead>

					{loading || users.length < 1 ? (
						<div className="loader">
							<CircularProgress />
						</div>
					) : (
						currentRecords.map((item, index) => (
							<tr
								key={item.uid}
								onClick={() => handleClick("edit", item)}
								// className={index === clickedIndex && `selected_row`}
							>
								{/* <td>{indexOfFirstRecord + index + 1}</td> */}
								{/* {console.log('item', item)} */}
								<td>{index + 1}</td>
								<td>{item.empcode}</td>
								<td>{item.name}</td>
								<td>{item.profile}</td>
								<td>{item.phone.number}</td>

								<td>{item.email}</td>
								<td>{item.doj}</td>

								<td>{item.reportingTo}</td>
								{item.isActive == "Active" ? (
									<td>
										<button className="btn btn-success">{item.isActive}</button>
									</td>
								) : (
									<td>
										<button className="btn btn-danger">In-active</button>
									</td>
								)}
							</tr>
						))
					)}
				</table>
			</Base>
		</div>
	);
};

export default User;

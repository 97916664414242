import React from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";

const BackNavigation = ({ title, color, url, status }) => {
  const history = useHistory();
  const style = {
    height: 30,
    width: 30,
    marginTop: 30,
    cursor: "pointer",
  };
  return (
    <span className="mt-4">
      {status == "tableBack" ? (
        <div
          className="btn btn-dark m-2"
          onClick={() => {
            url ? history.push(url) : history.goBack();
          }}
        >
          Back
        </div>
      ) : (
        <ArrowBackIcon
          style={style}
          onClick={() => {
            url ? history.push(url) : history.goBack();
          }}
        />
      )}

      <span className="back_title" style={{ color: color }}>
        {title}
      </span>
    </span>
  );
};

export default BackNavigation;

import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Base from "../../Common/Components/Base";
import "../../styles/referEarn.css";
import { useHistory } from "react-router-dom";
import { base_route } from "../../backend";

const ReferEarn = () => {
  const [data, setData] = useState([{ emp: "", name: "", noOfReferral: "" }]);
  const [displaydata, setdisplaydata] = useState({
    studentCount: 0,
    teacherCount: 0,
  });

  const onRowClick = (query, info, name, field) => {
    history.push({
      pathname: "/dashboard/referearn/individual",
      state: { query: query, info: info, name: name, field },
    });
  };
  const [field, setField] = useState("EmpCode");
  const history = useHistory();
  const getData = async (which) => {
    setData([{ emp: "", name: "", noOfReferral: "" }]);
    //alert("Clciked");
    if (which == "studentRef") {
      setField("Email");
    } else {
      setField("EmpCode");
    }
    await fetch(`${base_route}/invite/${which}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log(resp);
        setData(resp);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetch(`${base_route}/invite/statics`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log("resp", resp);
        setdisplaydata(resp);
      })
      .catch((err) => console.log(err));
    setField("EmpCode");
    fetch(`${base_route}/invite/teacherRef`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log(resp);
        setData(resp);
      })
      .catch((err) => console.log(err));

    //s result;
  }, []);

  return (
    <Base>
      <div className="container mt-5">
        <h2>Referral Program</h2>
        <div className="referral_count">
          <div style={{ backgroundColor: "#3f51b5" }}>
            <p>Employee</p>
            <h1>0</h1>
          </div>
          <div
            onClick={() => getData("teacherRef")}
            style={{ backgroundColor: "#81007f" }}
          >
            <p>Teacher</p>
            <h1>{displaydata.teacherCount}</h1>
          </div>
          <div
            onClick={() => getData("studentRef")}
            style={{ backgroundColor: "#43dcff" }}
          >
            <p>Student</p>

            <h1>{displaydata.studentCount}</h1>
          </div>
          <div style={{ backgroundColor: "#09db31" }}>
            <p>Affiliate</p>
            <h1>0</h1>
          </div>
        </div>
        <table className="referral_table">
          <tr>
            <th>Sr.no</th>
            <th>{field}</th>
            <th>Name</th>
            <th>No. of Referral</th>
          </tr>
          {data.map((item, index) => {
            return (
              <tr
                onClick={() => {
                  onRowClick(
                    item._id,

                    item.employeeCode,
                    item.name,
                    field
                  );
                }}
              >
                <td>{index + 1}</td>
                <td>{item.employeeCode}</td>
                <td>{item.name}</td>
                <td>{item["no of referrals"]}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </Base>
  );
};

export default ReferEarn;

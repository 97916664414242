import React, { useState, useEffect } from "react";
import Base from "../../Common/Components/Base";
import "../../styles/referralIndividual.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { base_route } from "../../backend";

const ReferralIndividual = () => {
  const [data, setData] = useState([
    {
      name: "Loading..",
      booktrial: false,
      trialcomplete: false,
      enrolled: false,
    },
  ]);
  const [myref, setmyRef] = useState([]);
  const location = useLocation();
  var userID = location.state.query;

  const reject = async (RUID) => {
    var profile = location.state.field;
    console.log(RUID, location.state.field, userID);
    return fetch(`${base_route}/invite/reject`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ RUID, profile, userID }),
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => console.log(err));
  };
  const accept = (RUID) => {
    console.log(RUID, location.state.field, userID);

    var profile = location.state.field;
    console.log(RUID, location.state.field, userID);
    return fetch(`${base_route}/invite/accept`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ RUID, profile, userID }),
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => console.log(err));
  };
  const referStatus = (item) => {
    var ruid = item.uid;
    if (item.booktrial && item.trialcomplete && item.enrolled) {
      var found;
      for (var i = 0; i < myref.length; i++) {
        if (myref[i].uid == ruid) {
          found = myref[i];
          break;
        }
      }
      if (found.requestSent == false) {
        return (
          <RemoveCircleIcon style={{ color: "#FF9A00" }}></RemoveCircleIcon>
        );
      } else if (
        found.requestSent == true &&
        found.requestReject == false &&
        found.reuestAccept == false
      ) {
        return (
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              class="btn btn-danger"
              onClick={() => reject(ruid)}
            >
              Reject
            </button>
            <button
              type="button"
              class="btn btn-success"
              onClick={() => accept(ruid)}
            >
              Accept
            </button>
          </div>
        );
      } else if (found.requestSent == true && found.requestReject == true) {
        return <h6>Rejected</h6>;
      } else {
        return <h6>Accepted</h6>;
      }
    } else {
      return <RemoveCircleIcon style={{ color: "#FF9A00" }}></RemoveCircleIcon>;
    }
  };
  const history = useHistory();
  useEffect(async () => {
    loadreferral();
  }, []);

  const loadreferral = async () => {
    await fetch(`${base_route}/invite/Ref/${userID}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        setData(resp.result);
        setmyRef(resp.myreferral);
        console.log(resp);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Base>
      <div className="container mt-5">
        <span
          className="back_icon"
          onClick={() => history.push("/dashboard/referearn/")}
        >
          <ArrowBackIcon></ArrowBackIcon>
        </span>

        <div className="referral_individual_details">
          <h2>{location.state.name}</h2>
          <p>
            {location.state.field}: {location.state.info}
          </p>
        </div>
        <table className="referral_table_individual">
          <tr>
            <th>Sr.no</th>
            <th>Referred Name</th>
            <th>Book trial</th>
            <th>Trial Done</th>
            <th>Enrolled</th>
            <th>Redeem</th>
          </tr>
          {data.map((item, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  {item.booktrial ? (
                    <CheckCircleIcon
                      style={{ color: "#09db31" }}
                    ></CheckCircleIcon>
                  ) : (
                    <RemoveCircleIcon
                      style={{ color: "#FF9A00" }}
                    ></RemoveCircleIcon>
                  )}
                </td>
                <td>
                  {item.trialcomplete ? (
                    <CheckCircleIcon
                      style={{ color: "#09db31" }}
                    ></CheckCircleIcon>
                  ) : (
                    <RemoveCircleIcon
                      style={{ color: "#FF9A00" }}
                    ></RemoveCircleIcon>
                  )}
                </td>
                <td>
                  {item.enrolled ? (
                    <CheckCircleIcon
                      style={{ color: "#09db31" }}
                    ></CheckCircleIcon>
                  ) : (
                    <RemoveCircleIcon
                      style={{ color: "#FF9A00" }}
                    ></RemoveCircleIcon>
                  )}
                </td>
                <td>{referStatus(item)}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </Base>
  );
};

export default ReferralIndividual;

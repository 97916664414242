import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import "./alertBox.css";
const AlertBox = ({ message, type }) => {
  const [status, setStatus] = useState(true);
  let color = "rgb(74, 250, 127)";
  if (type == "error") {
    color = "rgb(250, 74, 74)";
  }
  if (message == "") {
    message = "Default message";
  }
  if (type == "") {
    type = "success";
  }
  return (
    <>
      {status && (
        <div className="alertBoxNew" style={{ background: color }}>
          {" "}
          <div className="">{message}</div>
          <span
            className="new_booking_add_user_close_btn"
            onClick={() => setStatus(false)}
          >
            <CloseIcon fontSize="large"></CloseIcon>
          </span>
        </div>
      )}
    </>
  );
};
export default AlertBox;

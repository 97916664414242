import React from "react";
import BackNavigation from "../../Common/Components/BackNavigation";
import Base from "../../Common/Components/Base";
import "../../styles/setting.css";
import email from "../../Assests/Settings/email.png";
import phone from "../../Assests/Settings/phone.png";
import address from "../../Assests/Settings/address.png";
import ContactInfoItem from "./ContactInfoItem";

const ContactUs = () => {
  return (
    <Base>
      <div className="ml-3 mt-3">
        <div className="w_85 mx-auto">
          <BackNavigation
            title={"Reach out to us for any assistance"}
            color={"#000"}
          />
          <div className="ml-4">
            <div className="tech_support w_86 mx-auto">
              <ul>
                <li>
                  <div className="contact_info_item">
                    <span className="contact_info_icon">
                      <img src={address} alt="" />
                    </span>

                    <div className="contact_info_info">
                      <span className="contact_info_title">
                        <b>Address</b>
                      </span>
                      <span className="contact_info_text mt-3">
                        {" "}
                        <b> Asian region Headquarter: </b> Siddharth Complex,
                        9th-911,912, RC Dutt Rd, Alkapuri, Vadodara, Gujarat,
                        India 390007{" "}
                      </span>
                      <span className="contact_info_text">
                        {" "}
                        <b> USA Headquarter:</b> 2554 Ashmore Circle, Thousand
                        Oaks CA USA 91362
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact_info_item">
                    <span className="contact_info_icon">
                      <img src={phone} alt="" />
                    </span>

                    <div className="contact_info_info">
                      <span className="contact_info_title">
                        <b>Phone</b>
                      </span>
                      <span className="contact_info_text">+91 265-3561742</span>
                      <span className="contact_info_text">
                        +91 88 569 18896
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <ContactInfoItem
                    img={email}
                    title={"Email"}
                    text={"teacher@techokids.com"}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default ContactUs;

import React, { Fragment, useEffect, useState } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import PrivateRouteArray from "../config/PrivateRoutesConfig";
import PublicRouteArray from "../config/PublicRoutesConfig";
import MapAllowedRoutes from "./MapAllowedRoutes";
import { isAuthenticated } from "../components/Login/LoginAPICalls";
import Sidebar from "../components/AdaptiveSidebar";
import { intersection } from "lodash";
import { isArrayWithLength } from "../Utils/Helper";
import { base_route } from "../backend";
import Base from "./../Common/Components/Base";
const PrivateRoutes = () => {
  const match = useRouteMatch("/dashboard");
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  let role;
  if (JSON.parse(sessionStorage.getItem("tk_jwt")) === null) role = "";
  else role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
  useEffect(() => {
    async function updateRoutes() {
      const response = await fetch(`${base_route}/admin/${role}`);
      const data = await response.json();
      PrivateRouteArray.map((item) => {
        return data.access.map((element) => {
          const regex = new RegExp(element, "i");
          if (item.path.match(regex)) {
            if (!item.permission.includes(data.profile)) {
              item.permission.push(data.profile);
              return item.permission;
            }
          }
        });
      });
      if (isAuthenticated()) {
        let accessList = JSON.parse(
          sessionStorage.getItem("tk_jwt")
        ).accessList;
        let newAllowedRoutes = [];
        PublicRouteArray.forEach((c) => {
          newAllowedRoutes.push(c);
        });
        PrivateRouteArray.forEach((c) => {
          if (accessList.includes(c.title)) {
            newAllowedRoutes.push(c);
          }
        });
        setAllowedRoutes(newAllowedRoutes);
        // setAllowedRoutes(
        //   PrivateRouteArray.filter(({ permission }) => {
        //     if (!permission) return true;
        //     else if (!isArrayWithLength(permission)) return true;
        //     else return intersection(permission, [role]).length;
        //   })
        // );
      } else {
        return <Redirect to="/login" />;
      }
    }
    updateRoutes();
  }, []);
  console.log(allowedRoutes);
  if (!isAuthenticated()) return <Redirect to="/login" />;
  return <Sidebar routes={allowedRoutes} path={match.path}></Sidebar>;
};
export default PrivateRoutes;

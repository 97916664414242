import React, { Component } from "react";
import Base from "../Common/Components/Base";
const ReminderComponent = () => {
  return (
    <Base>
      <div>reminder works!!</div>
    </Base>
  );
};

export default ReminderComponent;

import React, { useState } from "react";
import BackNavigation from "../../Common/Components/BackNavigation";
import Base from "../../Common/Components/Base";
import "../../styles/setting.css";
import phone from "../../Assests/Settings/phone.png";
import email from "../../Assests/Settings/email.png";
import pcsetup from "../../Assests/Settings/pcsetup.png";
import time from "../../Assests/Settings/time.png";
import chrome from "../../Assests/Settings/chrome.png";
import ContactInfoItem from "../Contact/ContactInfoItem";
import { FiDownload } from "react-icons/fi";
import { openInNewTab } from "../../Utils/Helper";
import CheckPcModal from "./CheckPcModal";

const TechSupport = () => {
  const [showModal, setshowModal] = useState(false);

  const handleCheckPc = () => {
    setshowModal(true);
  };
  const onModalBtnClick = () => {
    setshowModal(!showModal);
  };
  return (
    <Base>
      <div className="ml-3 mt-3">
        <div className=" w_85 mx-auto">
          <BackNavigation
            title={"Reach out to us for any assistance"}
            color={"#000"}
          />

          <div className="ml-5">
            <div className="tech_support w_86 mx-auto">
              <ul>
                <li>
                  <ContactInfoItem
                    img={phone}
                    title={"Phone"}
                    text={"+91 88 569 18896"}
                  />
                </li>
                <li>
                  <ContactInfoItem
                    img={email}
                    title={"Email"}
                    text={"techsupport@techokids.com"}
                  />
                </li>
                <li>
                  <ContactInfoItem
                    img={pcsetup}
                    title={"Set up your PC"}
                    text={
                      "Check your network connection and computer set up to have best in class experience.@techokids.com"
                    }
                    action={{
                      title: "Check Pc",
                      handle: () => handleCheckPc(),
                    }}
                  />
                </li>
                <li>
                  <ContactInfoItem
                    img={time}
                    title={"Download AnyDesk Here"}
                    action={{
                      title: "Download",
                      handle: () => openInNewTab("https://anydesk.com/en"),
                      icon: <FiDownload />,
                    }}
                  />
                </li>
                <li>
                  <ContactInfoItem
                    img={chrome}
                    title={"Download the latest version of chrome"}
                    action={{
                      title: "Download",
                      handle: () =>
                        openInNewTab(
                          "https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAiA9vOABhBfEiwATCi7GM7k7e4RuMtyG53A4WpzO-Cl-VHG745bYZhcbrVOoZHFV-rjaeiS8RoC1XYQAvD_BwE&gclsrc=aw.ds"
                        ),
                      icon: <FiDownload />,
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
          <CheckPcModal showModal={showModal} closeModal={onModalBtnClick} />
        </div>
      </div>
    </Base>
  );
};

export default TechSupport;

import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import Login from "../components/Login/Login";
import LoginWithOTP from "../components/Login/LoginWithOTP";
import ResetPassword from "../components/Login/ResetPassword";

const PublicRoutes = () => {
  return (
    <Fragment>
      <Route path="/login" exact component={Login} />
      <Route path="/login-with-otp" exact component={LoginWithOTP} />
      <Route path="/reset-password" exact component={ResetPassword} />
    </Fragment>
  );
};

export default PublicRoutes;

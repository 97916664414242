import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { InputFeild } from './Components/InputFeild'
import LoginBtn from './Components/LoginBtn'
import {
  authenticate,
  loginWithOTP,
  verifyOTP,
  resetPassword,
} from './LoginAPICalls'
import LoginBase from './LoginBase'
import StatusModal from './StatusModal'

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [OTP, setOTP] = useState('')
  const [OTPerror, setOTPerror] = useState('')
  const [emailError, setEmailError] = useState('')
  const [OTPToken, setOTPToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [itemIndex, setitemIndex] = useState(0)
  const [newPassword, setnewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setpasswordError] = useState('')

  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: '',
    statusText: '',
  })
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  let history = useHistory()
  const onEmailSubmit = (event) => {
    event.preventDefault()

    if (!validateEmail(email)) {
      setEmailError('Email not valid')
    } else {
      loginWithOTP(email)
        .then((response) => {
          if (response.status === 200) {
            setOTPToken(response.data.token)
            setitemIndex(1)
          } else {
            alert(response)
          }
        })
        .catch((err) => {
          setEmailError('We cannot find your email')
        })
        .finally(() => {
          setLoading(false)
          setEmailError('')
        })
    }
  }

  const onOTPSubmit = (event) => {
    event.preventDefault()

    setLoading(false)
    OTP === ''
      ? setOTPerror('Enter OTP first')
      : verifyOTP(email, OTP, OTPToken)
          .then((response) => {
            if (response.status === 200) {
              setLoading(false)

              authenticate(
                {
                  email: email,
                  token: response.data.token,
                  profile: response.data.profile.toUpperCase(),
                },
                () => {
                  setitemIndex(2)
                }
              )
            } else {
              alert('otp', response.data.message)
            }
          })
          .catch((err) => {
            setOTPerror('Wrong OTP')
          })
          .finally(() => {
            setLoading(false)
            // setValues({...values, loading: false});
          })
  }

  const onResetPassword = (event) => {
    event.preventDefault()
    if (newPassword != confirmPassword) {
      setpasswordError("Password don't match")
    } else {
      resetPassword(newPassword, OTPToken, OTP).then((data) => {
        history.push('/login')
        setStatusModal({
          ...statusModal,
          showStatusModal: true,
          status: 'success',
          statusText: data.message,
        })
      })
    }
  }

  const handleCloseModal = () => {
    setStatusModal({ ...statusModal, showStatusModal: false })
    history.push('/dashboard')
  }

  const resetPasswordView = () => {
    {
      console.log(newPassword)
    }
    return (
      <LoginBase
        topsection={
          <>
            <h1 className='login_h1'>Reset Password</h1>
          </>
        }
        children={
          <>
            <InputFeild
              type='password'
              placeholder={'New Password'}
              value={newPassword}
              onChange={(e) => setnewPassword(e.target.value)}
            />
            <InputFeild
              type='password'
              placeholder={'Confirm Password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <h6 className='text-danger'> {passwordError}</h6>

            <LoginBtn
              type={'button'}
              btnName={'SUBMIT'}
              className={'mt-3'}
              onClick={onResetPassword}
            />
          </>
        }
      />
    )
  }
  const AskOTPView = () => {
    return (
      <LoginBase
        topsection={
          <>
            <h1 className='login_h1'>Enter OTP</h1>
            <p className='login_p'>
              OTP has been sent to your Phone or Email id
            </p>
          </>
        }
        children={
          <>
            <InputFeild
              type='number'
              placeholder={'OTP'}
              value={OTP}
              onChange={(e) => setOTP(e.target.value)}
            />
            <h6 className='text-danger'> {OTPerror}</h6>

            <LoginBtn
              type='button'
              btnName='SUBMIT'
              className={'mt-3'}
              onClick={(e) => onOTPSubmit(e)}
            />
          </>
        }
      />
    )
  }
  const AskEmailView = () => {
    return (
      <LoginBase
        topsection={
          <>
            <h1 className='login_h1'>Reset Password</h1>
            <p className='login_p'>
              Enter your email and we'll send you a OTP to your Phone or Email
              Id
            </p>
          </>
        }
        children={
          <>
            <InputFeild
              type='email'
              placeholder={'Email'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <h6 className='text-danger'> {emailError}</h6>

            <LoginBtn
              type={'button'}
              btnName={'NEXT'}
              className={'mt-3'}
              onClick={(e) => onEmailSubmit(e)}
            />
          </>
        }
        bottomsection={
          <h4 onClick={() => history.push('/login')}>
            <b> {'< Back to Login'}</b>
          </h4>
        }
      />
    )
  }
  let views = [AskEmailView(), AskOTPView(), resetPasswordView()]

  return (
    <>
      {views[itemIndex]}
      <StatusModal
        className='full_view'
        showModal={statusModal.showStatusModal}
        text={statusModal.statusText}
        status={statusModal.status}
        closeModal={handleCloseModal}
      />
    </>
  )
}

export default ResetPassword

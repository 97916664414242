import React, { memo, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Dashboard from "../Assests/sidebar/dashboard.svg";
import StudentBooking from "../Assests/sidebar/booking.svg";
import StudentRegistration from "../Assests/sidebar/registration.svg";
import StudentCurriculum from "../Assests/sidebar/curriculum.svg";
import StudentFreeVideo from "../Assests/sidebar/video.svg";
import TeacherSlot from "../Assests/sidebar/booking.svg";
import PaidSlot from "../Assests/sidebar/booking.svg";
import TeacherRegistration from "../Assests/sidebar/registration.svg";
import TeacherCurriculum from "../Assests/sidebar/t-curriculum.svg";
import TeacherEarning from "../Assests/sidebar/earning.svg";
import TeacherLeave from "../Assests/sidebar/leave.svg";
import ReferEarn from "../Assests/sidebar/refer.svg";
import Coupon from "../Assests/sidebar/coupon.svg";
import Ticket from "../Assests/sidebar/ticket.svg";
import Notification from "../Assests/sidebar/notification.svg";
import Setting from "../Assests/sidebar/setting.svg";
import MapAllowedRoutes from "./../routes/MapAllowedRoutes";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import Logo from "../Assests/logo.png";
import "../styles/sidebar.css";
import PrivateRouteArray from "../config/PrivateRoutesConfig";
import PublicRouteArray from "../config/PublicRoutesConfig";

const Sidebar = ({ routes, path, prefix, children }) => {
  // const items = routes.slice(
  //   0,
  //   routes.findIndex(({ title }) => title === "Setting") + 1
  // );
  let accessList = JSON.parse(sessionStorage.getItem("tk_jwt")).accessList;
  let items = [];
  routes.forEach((c) => {
    if (c.path.split("/").length == 2 && c.title != "User") items.push(c);
  });
  let history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const logout = () => {
    sessionStorage.removeItem("tk_jwt");
    window.location.reload();
  };
  const currentTab = (history, path) => {
    if (history.location.pathname === "/") return "/";
    let currentPath = "/" + history.location.pathname.split("/")[2];

    if (currentPath === path) {
      return currentPath;
    } else {
      return currentPath;
    }
  };
  const [display, setDisplay] = useState("flex");
  // if (sidebar) {
  //   setDisplay("flex");
  // }
  //  else {
  //   setDisplay("none");
  // }

  return (
    <>
      <div className="navBar">
        <div className="menu-Bars" onClick={() => showSidebar()}>
          {sidebar ? <AiIcons.AiOutlineClose /> : <FaIcons.FaBars />}
        </div>
        <img src={Logo} alt="" className="img-fluid" />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-md-4 col-lg-2  sidebar ${
              sidebar ? "nav-menu active" : "nav-menu"
            }`}
          >
            <ul className="nav-menu-items" onClick={() => showSidebar()}>
              <li className="nav-text web_logo">
                <img style={{ width: "80%" }} src={Logo} alt="Techokids" />
              </li>
              {items.map(({ path, title }) => (
                <li key={path} className={`p-2 ${currentTab(history, path)}`}>
                  {/* <img
                    style={{ width: "16%" }}
                    src={
                      require(`../Assests/AdaptiveSidebar/${title}.svg`).default
                    }
                    alt="img"
                  /> */}
                  <Link
                    to={`${[prefix]}${path}`}
                    className="p-0 m-0"
                    style={{
                      fontSize: "120%",
                      color: "white",
                      textDecoration: "none",
                    }}
                  >
                    <span className="sidebar-title">{title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <main className="col mx-auto">
            <MapAllowedRoutes routes={routes} basePath={path} isAddNotFound />
          </main>
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  prefix: PropTypes.string,
};
Sidebar.defaultProps = {
  prefix: "/dashboard",
};
export default memo(Sidebar);

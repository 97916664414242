import { Checkbox, Paper } from "@material-ui/core";
import axios from "axios";
import React, { Component, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/curriculum.css";
import "./styles/s-curriculum.css";
import Base from "../../Common/Components/Base";

import { base_route } from "../../backend";
const baseURL = base_route;

const CurriculumComponent = () => {
  const [_package, setPackage] = useState("Beginner");
  const [level, setLevel] = useState(1);
  const [packageDetail, setPackageDetail] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [newtitle, setNewtitle] = useState("");
  const [newDes, setNewdesc] = useState("");
  const [lessonID, setlessonID] = useState(0);
  const [dbID, setdbID] = useState("");
  const [isActivity, setActivity] = useState(false);
  const [classCount, setClassCount] = useState([]);
  const [lessonNo, setLessonNo] = useState(0);
  const [render, setRender] = useState(0);

  useEffect(() => {
    setClassCount(classCount);
    setTitle("");
    setActivity(false);
    setDescription("");

    getPackage();
  }, [_package, level, classCount, render]);
  for (let i = 0; i <= 300; i++) {
    classCount.push(i);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title === "") {
      return toast("Title cannot be empty");
    }
    if (description === "") {
      return toast("Descriptin cannot be empty");
    }
    if (lessonNo === 0) {
      return toast("Cannot have 0 lesson No");
    }
    updatePackage();
  };

  const updatePackage = async (e) => {
    const res = await axios.put(`${baseURL}/curriculum/updatePackage`, {
      package: _package,
      level: level,
      lesson: {
        id: lessonNo,
        title: title,
        description: description,
        isActivity: isActivity,
      },
    });
    if (res.status === 200) {
      setTitle("");
      setDescription("");

      toast(res.data.message);
      setRender((prev) => prev + 1);
    } else {
      toast("Error adding new lesson");
    }
    //console.log(res);
  };

  const onUpdate = async (des, title, _package, level, ID) => {
    await fetch(`${baseURL}/curriculum/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ des, title, _package, level, ID }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.error) {
          toast(resp.error);
        } else {
          setRender((prev) => prev + 1);
          toast("Edited Succesfully");
        }
      })
      .catch((err) => console.log(err));
  };

  const onEdit = (pack) => {
    setNewtitle(pack.title);
    setNewdesc(pack.description);
    setlessonID(pack.id);
    setdbID(pack._id);
  };
  const onDelete = async (pack) => {
    console.log(pack);
    await fetch(`${baseURL}/curriculum/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pack, level, _package }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.error) {
          toast(resp.error);
        } else {
          toast(resp.message);
          setRender((prev) => prev + 1);
        }
      })
      .catch((err) => console.log(err));
  };
  const getPackage = async () => {
    console.log("get package called");
    const response = await axios(
      `${baseURL}/curriculum/level/${level}/package/${_package}`
    );
    console.log(response.data[0].lessons);
    var data = response.data[0].lessons;
    var data2 = data.sort((a, b) => {
      return a.id - b.id;
    });
    setPackageDetail(data2);
  };

  return (
    <Base>
      <div
        className="container s-curriculum"
        style={{
          padding: "5%",
          width: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <form style={{ width: "50%" }}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label for="package">Package Name</label>
                <select
                  name="package"
                  id="package"
                  value={_package}
                  onChange={(e) => {
                    setPackage(e.target.value);
                  }}
                  className="form-control"
                >
                  <option value="Beginner">Beginner</option>
                  <option value="Advance">Advance</option>
                  <option value="Master">Master</option>
                  <option value="Ultimate">Ultimate</option>
                </select>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label for="level">Choose Level</label>
                <select
                  id="level"
                  name="level"
                  onChange={(e) => {
                    setLevel(e.target.value);
                  }}
                  className="form-control"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label for="lesson">Lesson No.</label>
                <select
                  type="number"
                  id="package"
                  name="lesson"
                  value={lessonNo}
                  onChange={(e) => {
                    setLessonNo(e.target.value);
                  }}
                  className="form-control"
                >
                  {classCount.map((_class) => (
                    <option value={_class}>{_class}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="package">Lesson Title</label>
                <input
                  required
                  type="text"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="package">Lesson Description</label>
                <textarea
                  required
                  type="text"
                  rows="10"
                  className="form-control"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <input
            style={{ margin: "5px" }}
            type="checkbox"
            checked={isActivity}
            onChange={() => {
              setActivity(!isActivity);
            }}
          />
          Mark if this lesson is activity
          <div style={{ width: "100%" }}>
            <button
              className="btn btn-primary"
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
              onClick={handleSubmit}
            >
              Add new Lesson
            </button>
          </div>
        </form>

        <ToastContainer />
      </div>
      <div className="row" style={{ display: "flex", flexDirection: "row" }}>
        <p>Uploaded Curriculum</p>
        <div className="s-curriculumTable">
          <table id="customers" striped>
            <thead class="thead-dark">
              <tr>
                <th>Lesson No.</th>
                <th>Lesson Title</th>
                <th>Lesson Description</th>
                <th>Lesson Level</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            {packageDetail.map((pack, index) => {
              return (
                <tr>
                  <th>{pack.id}</th>
                  <th>{pack.title}</th>
                  <th>{pack.description}</th>
                  <th>{level}</th>
                  <th>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      onClick={() => onEdit(pack)}
                    >
                      Edit
                    </button>
                  </th>
                  <th>
                    <button
                      className="btn btn-danger"
                      onClick={() => onDelete(pack)}
                    >
                      Delete
                    </button>
                  </th>
                </tr>
              );
            })}
          </table>
        </div>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  <span>Package:{_package}</span>
                  <span>Level:{level}</span>
                  <span>
                    LessonNo:
                    {lessonID}
                  </span>
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="package">Lesson Title</label>
                      <input
                        required
                        type="text"
                        value={newtitle}
                        onChange={(e) => {
                          setNewtitle(e.target.value);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="package">Lesson Description</label>
                      <textarea
                        required
                        type="text"
                        rows="10"
                        className="form-control"
                        value={newDes}
                        onChange={(e) => {
                          setNewdesc(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() =>
                    onUpdate(newDes, newtitle, _package, level, dbID)
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default CurriculumComponent;

import React, { useEffect, useState, useRef } from "react";
import { base_route } from "../../../backend";
import { searchAlgo, findCountry } from "../../../Utils/Helper.js";
import BackNavigation from "../../../Common/Components/BackNavigation";
import Base from "../../../Common/Components/Base";
import { CircularProgress } from "@material-ui/core";
import { Header } from "./../components/Header";
import "./../../../styles/common/table.css";

export const NoLaptop = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [currentRecords, setCurrentRecords] = useState([]);
  const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  useEffect(() => {
    // to show limited records
    setCurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage, students]);
  useEffect(() => {
    fetchNoLaptop();
  }, []);

  const fetchNoLaptop = async () => {
    setLoading(true);
    let URL;
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    URL = `${base_route}/admin/s-booking/noLaptop?role=${role}&personID=${personID}`;
    const response = await fetch(URL, {
      method: "GET",
    });
    const res = await response.json();
    setStudents(res.reverse());
    setLoading(false);
  };
  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };
  const handleDelete = async (type, ID) => {
    fetch(`${base_route}/admin/s-booking/deleteData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type, ID }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          alert(res.error);
          return;
        } else {
          window.location.reload();
          return;
        }
      });
  };
  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    return new Date(secondsSinceEpoch * 1000).toDateString().substring(4, 15);
  };
  return (
    <Base>
      <div className="s-bookingNoLaptop">
        <BackNavigation />
        <Header
          setSearchFeild={setSearchFeild}
          searchFeild={searchFeild}
          search={search}
          setSearch={setSearch}
          recordsPerPage={recordsPerPage}
          onRecordPerPageClick={onRecordPerPageClick}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          indexOfFirstRecord={indexOfFirstRecord}
          indexOfLastRecord={indexOfLastRecord}
          data={students}
          title={"No Laptop"}
        />
        <div className="mainTable">
          <table id="customers">
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Lead Date</th>
                <th>Parent's Name</th>
                <th>Student's Name</th>
                <th>Grade</th>
                <th>Country</th>
                <th>Phone No.</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>

            {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              currentRecords.map((item, index) => {
                return (
                  <tr
                    key={item.uid}
                    className={index === clickedIndex && `selected_row`}
                  >
                    <td>{indexOfFirstRecord + index + 1}</td>
                    <td>{getRegDate(item._id)}</td>
                    <td>{item.parentName}</td>
                    <td>{item.name}</td>

                    <td>{item.grade}</td>
                    <td>{findCountry(`+${item?.parentPhone?.countryCode}`)}</td>
                    <td>{item.parentPhone.number}</td>

                    <td>{item.email}</td>
                    <td>
                      <button
                        className="delete_buttton"
                        onClick={() => handleDelete("Nolaptop", item._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </table>
        </div>
      </div>
    </Base>
  );
};

import React, { Component, useEffect, useState } from "react";
import Base from "../Common/Components/Base";
const Dashboard = () => {
  return (
    <Base>
      <div className="dashboard mr-5 mt-5">Dashboard</div>
    </Base>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Base from "../../../Common/Components/Base";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 400,
    background: "#cb017b",
    color: "white",
  },
  button: {
    color: "white",
    justifyContent: "center",
    display: "flex",
  },
});

const StudentTrialDone = ({ data }) => {
  const history = useHistory();

  const onClick = (query) => {
    history.push({
      pathname: "/dashboard/s-booking/reschedule",
      state: { query: query },
    });
  };
  const classes = useStyles();

  return (
    <div onClick={() => onClick("reschedule")} style={{ cursor: "pointer" }}>
      <Card variant="contained" className={classes.card}>
        <CardContent>
          <>
            <Typography>Rescheduled</Typography>
            <Typography variant="h2" align="center">
              {data.rescheduledBookings}
            </Typography>
          </>
        </CardContent>
      </Card>
    </div>
  );
};

export default StudentTrialDone;

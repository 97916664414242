import axios from "axios";
import React, { Component, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Base from "../../Common/Components/Base";
import "../../styles/addvideo.css";
import "./styles/s-freeVideo.css";
import { base_route } from "../../backend";
const baseURL = base_route;

const AddVideo = () => {
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [lessonNo, setLessonNo] = useState("");
  const [videos, setVideo] = useState([]);
  const [id, setID] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (link == "" || description == "" || title == "" || lessonNo == "") {
      toast("Enter all the fields");
      return;
    }
    saveToDb();
  };

  const saveToDb = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/curriculum/addFreeLecture`,
        {
          link: link,
          title: title,
          description: description,
          videoNo: lessonNo,
          ID: id,
        }
      );
      if (response.status === 200) {
        toast("Video Added to library");
        setLink("");
        setLessonNo("");
        setTitle("");
        setDescription("");
        setID("");
      }
    } catch (e) {
      toast("Something went wrong");
    }
  };

  const resetValues = (e) => {
    e.preventDefault();
    setTitle("");
    setLink("");
    setDescription("");
    setLessonNo("");
    setID("");
  };

  const onRowClick = (item) => {
    console.log(item);
    setTitle(item.title);
    setLink(item.link);
    setDescription(item.description);
    setLessonNo(item.videoNo);
    setID(item._id);
  };
  const handleDelete = async (ID) => {
    console.log(ID);

    await fetch(`${baseURL}/curriculum/delfreevideo`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ID }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          toast(res.error);
        } else {
          toast(res.message);
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(async () => {
    await fetch(`${baseURL}/curriculum/getFreeLectures`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setVideo(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Base>
      <div className="s-freeVideo">
        <div className="container">
          <form>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="">Video No</label>
                  <input
                    type="text"
                    name="videoNo"
                    value={lessonNo}
                    onChange={(e) => {
                      setLessonNo(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="title"> Title</label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    name="description"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="link">Link</label>
                  <input
                    type="text"
                    name="link"
                    value={link}
                    id="link"
                    onChange={(e) => {
                      setLink(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
            <button
              className="btn btn-success reset_button"
              onClick={(e) => resetValues(e)}
            >
              Reset
            </button>
          </form>
          <ToastContainer />
        </div>
        <hr></hr>
        <table id="customers">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Video No</th>
              <th>Title</th>
              <th>Description</th>
              <th>Link</th>
              <th>Action</th>
            </tr>
          </thead>

          {videos.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td onClick={() => onRowClick(item)}>{item.videoNo}</td>
              <td onClick={() => onRowClick(item)}>{item.title}</td>
              <td>{item.description}</td>
              <td>{item.link}</td>
              <td>
                <button
                  onClick={() => handleDelete(item._id)}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </Base>
  );
};

export default AddVideo;

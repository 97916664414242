import React, { useEffect, useState, useRef } from "react";
import { base_route } from "../../../backend";
import { searchAlgo, findCountry } from "../../../Utils/Helper.js";
import BackNavigation from "../../../Common/Components/BackNavigation";
import Base from "../../../Common/Components/Base";
import { CircularProgress } from "@material-ui/core";
import { Header } from "./../components/Header";
import { useHistory } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import "./../../../styles/common/table.css";
import "./../styles/s-converted.css";
import TkDropDown from "../../../Common/Components/DropDown";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { SettingsRemoteRounded } from "@material-ui/icons";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

export const Converted = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [stopped, setStopped] = useState(false);
  const [action, setAction] = useState(false);
  const [cancelledClass, setCancelledClass] = useState("");
  const [cancelledClasses, setCancelledClasses] = useState([]);
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [student, setStudent] = useState();
  const [statusPopup, setStatusPopup] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [mode, setMode] = useState();
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [stopToDate, setStopToDate] = useState();
  const [stopFromDate, setStopFromDate] = useState();
  const [currentRecords, setCurrentRecords] = useState([]);
  const history = useHistory();
  const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const handleOnRowClick = (i, student, mode) => {
    console.log("Student at handle", i, student);
    history.push({
      pathname: "/dashboard/os-registration",
      state: { student: student, mode: mode },
    });
    setclickedIndex(i);
  };
  useEffect(() => {
    // to show limited records
    setCurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage, students]);
  useEffect(() => {
    fetchNoLaptop();
  }, []);

  const fetchNoLaptop = async () => {
    setLoading(true);
    let URL;
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    URL = `${base_route}/admin/s-booking/converted?role=${role}&personID=${personID}`;
    const response = await fetch(URL, {
      method: "GET",
    });
    const res = await response.json();
    setStudents(res.reverse());
    setLoading(false);
  };
  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };
  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    return new Date(secondsSinceEpoch * 1000).toDateString().substring(4, 15);
  };
  const handleAction = async (item, mode) => {
    await fetch(
      `${base_route}/admin/s-booking/converted/getStatus/${item.email}`,
      {
        method: "GET",
      }
    ).then((res) => {
      res.json().then((result) => {
        setStatus(result.status);
      });
    });
    console.log(item.email);
    setStudent(item);
    setMode(mode);
    setAction(true);
    await fetch(
      `${base_route}/admin/s-booking/converted/getCancelledClass/${item.email}`,
      {
        method: "GET",
      }
    ).then((res) => {
      res.json().then((result) => {
        if (result.classes == []) {
          setCancelledClasses(cancelledClasses.unshift("none"));
        } else setCancelledClasses(result.classes);
      });
    });
  };
  const handleStatus = (stat) => {
    console.log(stat);
    if (stat == "Stopped") setStopped(true);
    setStatus(stat);
  };
  const handleStatusPopup = () => {
    setStatusPopup(true);
    console.log("status");
  };
  const handleCancelPopup = () => {
    setCancelPopup(true);
    console.log("cancel");
  };
  const handleStop = () => {
    fetch(`${base_route}/admin/s-booking/converted/stopClass`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        studentEmail: student.email,
        stopFromDate: stopFromDate,
        stopToDate: stopToDate,
      }),
    }).then((res) => {
      res.json().then((resp) => {
        if (resp.error) {
          alert(resp.error);
        } else {
          alert(resp.message);
        }
      });
    });
  };

  return (
    <Base>
      <div className="s-bookingConverted">
        <BackNavigation />
        <Header
          setSearchFeild={setSearchFeild}
          searchFeild={searchFeild}
          search={search}
          setSearch={setSearch}
          recordsPerPage={recordsPerPage}
          onRecordPerPageClick={onRecordPerPageClick}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          indexOfFirstRecord={indexOfFirstRecord}
          indexOfLastRecord={indexOfLastRecord}
          data={students}
          title={"Converted"}
        />
      </div>

      <div className="mainTable">
        <table id="customers">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Lead Date</th>
              <th>Parent's Name</th>
              <th>Student's Name</th>
              <th>Grade</th>
              <th>Country</th>
              <th>Phone No.</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>

          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            currentRecords.map((item, index) => {
              return (
                <tr
                  key={item.uid}
                  className={index === clickedIndex && `selected_row`}
                >
                  <td>{indexOfFirstRecord + index + 1}</td>
                  <td>{getRegDate(item._id)}</td>
                  <td>{item.parentName}</td>
                  <td>{item.name}</td>

                  <td>{item.grade}</td>
                  <td>{findCountry(`+${item.parentPhone.countryCode}`)}</td>
                  <td>{item.parentPhone.number}</td>

                  <td>{item.email}</td>
                  <td>
                    {/* <button
                      className="s_converted_edit_button"
                      onClick={() => handleOnRowClick(index, item, "edit")}
                    >
                      Edit
                    </button>
                    <button
                      className="s_converted_feedback_button"
                      onClick={() =>
                        history.push({
                          pathname:
                            "/dashboard/s-booking/info/student/feedback",
                          state: { student: item },
                        })
                      }
                      style={{ backgroundColor: "#43dcff" }}
                    >
                      View Feedback
                    </button> */}
                    {/* <button
                      className="btn btn-info  m-0"
                      onClick={() => handleAction(item, "edit")}
                    >
                      More
                    </button> */}
                    {/* {action && (
                      <div className="actionBox">
                        {" "}
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div className="buttons d-flex ">
                            <button
                              className="btn btn-secondary m-2"
                              onClick={() => {
                                handleOnRowClick(index, studentEmail, mode);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-success m-2"
                              onClick={() => {
                                history.push({
                                  pathname:
                                    "/dashboard/s-booking/info/student/feedback",
                                  state: { student: studentEmail },
                                });
                              }}
                            >
                              View Feedback
                            </button>
                          </div>
                          <div className="buttons d-flex justify-content-center">
                            <div className="m-2">
                              <TkDropDown
                                style={{ position: "relative", left: "50%" }}
                                variant="warning"
                                label="Status"
                                _onClick={handleStatus}
                                value={status}
                                items={["Active", "Stopped", "Completed"]}
                              />
                            </div>
                            <div className="m-2 mt-1">
                              <label
                                for="exampleFormControlSelect1"
                                class="col col-form-label"
                              >
                                Cancelled Class
                              </label>
                              <select
                                class="form-control  t_earning_input"
                                id="exampleFormControlSelect1"
                                value={cancelledClass}
                                onChange={(e) =>
                                  setCancelledClass(e.target.value)
                                }
                              >
                                <option value="" disabled selected>
                                  Select Cancelled Class
                                </option>
                                {trial.map((item, index) => {
                                  return <option value={item}>{item}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <span
                          className="new_booking_add_user_close_btn"
                          onClick={() => setAction(false)}
                        >
                        <CloseIcon fontSize="large"></CloseIcon>
                        </span>
                        </div>
                    )} */}
                    {statusPopup && (
                      <div className="statusBox">
                        <TkDropDown
                          style={{ position: "relative", left: "50%" }}
                          variant="warning"
                          label="Status"
                          _onClick={handleStatus}
                          value={status}
                          items={["Active", "Stopped", "Completed"]}
                        />
                        <span
                          className="new_booking_add_user_close_btn"
                          onClick={() => setStatusPopup(false)}
                        >
                          <CloseIcon fontSize="large"></CloseIcon>
                        </span>
                      </div>
                    )}
                    {cancelPopup && (
                      <div className="statusBox">
                        <label
                          for="exampleFormControlSelect1"
                          class="col col-form-label"
                        >
                          Cancelled Class
                        </label>
                        <select
                          class="form-control  t_earning_input"
                          id="exampleFormControlSelect1"
                          value={cancelledClass}
                          onChange={(e) => setCancelledClass(e.target.value)}
                        >
                          <option value="" disabled selected>
                            Select Cancelled Class
                          </option>
                          {cancelledClasses.map((item, index) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                        <span
                          className="new_booking_add_user_close_btn"
                          onClick={() => setCancelPopup(false)}
                        >
                          <CloseIcon fontSize="large"></CloseIcon>
                        </span>
                      </div>
                    )}
                    {stopped && (
                      <div className="actionBox">
                        <label>From </label>
                        <input
                          type="date"
                          name="classCompleted"
                          class="form-control"
                          value={stopFromDate}
                          onChange={(event) => {
                            console.log(event.target.value);
                            setStopFromDate(event.target.value);
                          }}
                          id="inputEmail4"
                          readonly
                        />
                        <label>To </label>
                        <input
                          type="date"
                          name="classCompleted"
                          class="form-control"
                          value={stopToDate}
                          onChange={(event) => {
                            console.log(event.target.value);
                            setStopToDate(event.target.value);
                          }}
                          id="inputEmail4"
                          readonly
                        />
                        <button
                          className="btn btn-danger mt-5"
                          onClick={() => handleStop()}
                        >
                          Submit
                        </button>
                        <span
                          className="new_booking_add_user_close_btn"
                          onClick={() => setStopped(false)}
                        >
                          <CloseIcon fontSize="large"></CloseIcon>
                        </span>
                      </div>
                    )}
                    <div>
                      <DropdownButton
                        title="MORE"
                        variant="info"
                        onClick={() => handleAction(item, "edit")}
                      >
                        <DropdownItem>
                          <button
                            className="btn btn-secondary m-2"
                            onClick={() => {
                              handleOnRowClick(index, student, mode);
                            }}
                          >
                            Edit
                          </button>
                        </DropdownItem>
                        <DropdownItem>
                          <button
                            className="btn btn-success m-2"
                            onClick={() => {
                              history.push({
                                pathname:
                                  "/dashboard/s-booking/info/student/feedback",
                                state: { student: student },
                              });
                            }}
                          >
                            View Feedback
                          </button>
                        </DropdownItem>
                        <DropdownItem onClick={() => handleStatusPopup()}>
                          <button className="btn btn-warning m-2">
                            Status
                          </button>
                        </DropdownItem>
                        <DropdownItem onClick={() => handleCancelPopup()}>
                          <button className="btn btn-danger m-2">
                            Cancelled
                          </button>
                        </DropdownItem>
                      </DropdownButton>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </table>
      </div>
    </Base>
  );
};

import React from "react";
import "./component.css";

const LoginBtn = ({btnName , type, className, onClick, loading}) => {
  return (

  <button
  type={type}
  className={`btn btn_signin theme_bg_color  text-white ${className}`}
  onClick={onClick}
  disabled={loading}
>
  {btnName}
</button>
  );
};

export default LoginBtn;

import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Search from "./search";
import axios from "axios";
import { Table } from "react-bootstrap";
import { useMemo, useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import ReactHtmlParser from "react-html-parser";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { makeStyles } from "@material-ui/core/styles";
import "./display.css";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { base_route } from "../../backend";

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 0,
		paddingTop: "56.25%", // 16:9
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
}));
const Display = (props) => {
	const classes = useStyles();
	const [mainData, setMainData] = useState([]);
	// const [data, setData] = useState(mainData);
	const [search, setSearch] = useState("");
	const [searchClick, setSearchClick] = useState(false);
	useEffect(() => {
		axios
			.get(`${base_route}/blog/allBlog`)
			.then((res) => {
				console.log(res.data);

				setMainData(res.data);

				// setData(res.data);
				console.log("inmain dta", res.data);
			})
			.catch((err) => console.log(err));
	}, []);

	const onDelete = (e) => {
		console.log("u clicked me ");
		const id = e.target.id;
		console.log(e.target.id, "eve");
		// console.lof(e.currentTarget.id)

		const confirm = window.confirm("Are you sure?");
		if (confirm) {
			axios
				.delete(`blog/deleteBlog/?id=${id}`)
				.then((res) => {
					console.log(res.data, "delete res");
					window.location.reload();
					// props.history.push('/dashboard/blog')
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
	const onSearchClick = (e) => {
		console.log(e.target.value, "search value");
		if (e.key === "Enter" || e.type === "click") {
			setSearchClick(!searchClick);
			axios
				.get(`/blog/search?word=${search}`)
				.then((res) => {
					setMainData(res.data);
				})
				.catch((err) => console.log(err));
		}
	};

	const handleEdit = (data) => {
		axios
			.get(`/blog/viewById?id=${data}`)
			.then((res) => console.log(res.data))
			.catch((err) => console.log(err));
		// console.log(data);
	};

	return (
		<div className="w_85 mx-auto">
			<div
				className="ticket_section "
				style={{ margin: "40px 10px 10px 280px" }}
			>
				<div className="ticket_info">
					{console.log("u here ")}

					<Row>
						<Col className="container mb-2">
							<span className="h3" style={{ textalign: "center" }}>
								Blogs
							</span>
							<Link to="/dashboard/blog/createblog">
								<button className="blogbutton" style={{ marginLeft: "30px" }}>
									Add New
								</button>
							</Link>
						</Col>
					</Row>
					<Row>
						<Col
							className="inputflex"
							style={{ width: "70%" }}
							// style={{
							//   marginRight: "10px",
							//   display: "flex",
							//   justifyContent: "flex-end",
							// }}
						>
							<Search
								onSearch={(value) => {
									setSearch(value);
									setSearchClick(false);
								}}
								onKeyPress={onSearchClick}
							/>
							<button className="blogbutton mx-2" onClick={onSearchClick}>
								Search Posts
							</button>
						</Col>
					</Row>
				</div>
			</div>
			<div
				className="ticket_section "
				style={{ margin: "40px 10px 10px 280px" }}
			>
				<Grid container style={{ width: "100%" }} spacing={3}>
					{/* <div
					style={{
						display: "flex",
						flexDirection: "row",
						marginRight: "5px",
					}}
					> */}
					{mainData.length > 0 &&
						mainData.map((data) => {
							return (
								<Grid item md={3}>
									<div style={{ marginRight: "15px" }}>
										{console.log(data.attachment, "props")}
										<Card
											onClick={() => handleEdit(data._id)}
											key={data._id}
											// value={data._id}
											className={classes.root}
										>
											{/* {console.log(author,'auth')} */}
											{console.log(data.attachment, "props")}
											<Link to={`/dashboard/blog/editblog/${data._id}`}>
												<CardHeader
													avatar={
														<Avatar
															aria-label="recipe"
															// src={`http://localhost:8000/${data.authorImage}`}
															src={data.authorImage}
															className={classes.avatar}
														></Avatar>
													}
													action={
														<IconButton aria-label="settings"></IconButton>
													}
													title={data.author}
													// subheader = `${(moment(new Date(data.createdAt)).format('DD-MM-YYYY'))}`
													subheader={moment(new Date(data.createdAt)).format(
														"DD-MM-YYYY"
													)}
													// title={(data.readTime)}
												/>
											</Link>
											<Typography>{data.readTime}</Typography>
											<Typography variant="h5">{data.title}</Typography>
											<Typography>
												{/* <Avatar
													aria-label="recipe"
													src={`${data.attachment}`}
													className={classes.avatar}
													width="500"
													height="500"
												>
												</Avatar> */}
												<img
													src={data.attachment}
													// alr="glr"
													// width="500"
													// height="500"
													className="blogThumbNail"
												></img>
											</Typography>
											<CardMedia
												className={classes.media}
												image="/static/images/cards/paella.jpg"
												title={data.title}
											/>
											<CardContent>
												<Typography
													variant="body2"
													color="textSecondary"
													component="p"
													className="displayContent"
												>
													{ReactHtmlParser(data.short_discription)}
												</Typography>
											</CardContent>
											<CardContent>
												<Typography>
													<button onClick={onDelete} id={data._id}>
														Delete
													</button>
												</Typography>
											</CardContent>
											<CardActions disableSpacing></CardActions>
											<Collapse timeout="auto" unmountOnExit>
												<CardContent>
													<Typography
														variant="body2"
														color="textSecondary"
														component="p"
													>
														This impressive paella is a perfect party dish and a
														fun meal to cook together with your guests. Add 1
														cup of frozen peas along with the mussels, if you
														like.
													</Typography>
												</CardContent>
											</Collapse>
											<CardActions disableSpacing></CardActions>
											<Collapse timeout="auto" unmountOnExit>
												<CardContent>
													<Typography paragraph>Method:</Typography>
													<Typography paragraph>
														Heat 1/2 cup of the broth in a pot until simmering,
														add saffron and set aside for 10 minutes.
													</Typography>
													<Typography paragraph>
														Heat oil in a (14- to 16-inch) paella pan or a
														large, deep skillet over medium-high heat. Add
														chicken, shrimp and chorizo, and cook, stirring
														occasionally until lightly browned, 6 to 8 minutes.
														Transfer shrimp to a large plate and set aside,
														leaving chicken and chorizo in the pan. Add
														pimentón, bay leaves, garlic, tomatoes, onion, salt
														and pepper, and cook, stirring often until thickened
														and fragrant, about 10 minutes. Add saffron broth
														and remaining 4 1/2 cups chicken broth; bring to a
														boil.
													</Typography>
													<Typography paragraph>
														Add rice and stir very gently to distribute. Top
														with artichokes and peppers, and cook without
														stirring, until most of the liquid is absorbed, 15
														to 18 minutes. Reduce heat to medium-low, add
														reserved shrimp and mussels, tucking them down into
														the rice, and cook again without stirring, until
														mussels have opened and rice is just tender, 5 to 7
														minutes more. (Discard any mussels that don’t open.)
													</Typography>
													<Typography>
														Set aside off of the heat to let rest for 10
														minutes, and then serve.
													</Typography>
												</CardContent>
											</Collapse>
										</Card>
									</div>
								</Grid>
							);
						})}
					{/* </div> */}
				</Grid>
			</div>
		</div>
	);
};
export default withRouter(Display);

{
	/* {mainData.length>0 && mainData.map(data=>{
              return (
                <div>

              <h1>
                
                {ReactHtmlParser(data.description)}
                
               
               
            </h1>
                <h1>{data.title}</h1>
                </div>)
               
            })} */
}

import React, { Component, useEffect, useState } from "react";
import Base from "../../Common/Components/Base";
const TeacherCurriculum = () => {
  return (
    <Base>
      <div>Teacher Curriclum Working</div>
    </Base>
  );
};

export default TeacherCurriculum;

import { result } from "lodash";
import { base_route } from "../../backend";

export const updateProfile = async (updateUserInfo, token) => {
  console.log("updated user", updateUserInfo);
  return "Under construction";
  // return await fetch(`${base_route}/admin/settings/profile/updateProfile`, {
  //   method: "PUT",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //     "bearer-token": `${token}`,
  //   },
  //   body: JSON.stringify(updateUserInfo),
  // })
  //   .then((resp) => {
  //     return resp.json();
  //   })
  //   .catch((err) => console.log(err));
};

export const updateProfilePic = async (file) => {
  // console.log(file)
  const data = new FormData();
  data.append("image", file);
  data.append("name", file.name);
  data.append("desc", "desc");
  return await fetch(
    `${base_route}/admin/settings/profile/uploadProfilePicture`,
    {
      method: "POST",
      body: data,
    }
  )
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const getProfilePic = async (id) => {
  console.log("id", id);
  return await fetch(
    `${base_route}/admin/settings/profile/getProfilePicture/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import BackNavigation from "../../Common/Components/BackNavigation";
import { base_route } from "./../../backend";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TkDropDown from "../../Common/Components/DropDown";
import "./styles/bookedTable.css";
import "./../../Common/Components/Base.css";
import Base from "./../../Common/Components/Base";

export const BookedTable = () => {
  // const [date, setDate] = useState(new Date());
  const location = useLocation();
  const history = useHistory();
  const [teacherID, setTeacherID] = useState(location.state.teacherID);
  const [teacherName, setTeacherName] = useState(location.state.teacherName);
  const [teachers, setTeachers] = useState(location.state.teachers);
  const [teachersOBJ, setTeachersOBJ] = useState(location.state.teachersOBJ);
  const [rdate, setRdate] = useState(location.state.rdate);
  const [slotInfo, setSlotInfo] = useState([
    {
      teacherName: "Loading..",
      studentName: "Loading..",
      studentEmail: "Loading...",
      slot: "Loading..",
    },
  ]);
  useEffect(async () => {
    console.log(rdate);
    if (!rdate) {
      setRdate(new Date().toDateString());
    }
    if (!teacherID) {
      setTeacherID(0);
    }
    if (!teacherName) {
      setTeacherName("None");
    }
    const personID = JSON.parse(sessionStorage.getItem("tk_jwt")).personID;
    const role = JSON.parse(sessionStorage.getItem("tk_jwt")).profile;
    await fetch(`${base_route}/admin/t-slots/slotByDateBooked`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        role: role,
        personID: personID,
        rdate: new Date(rdate).toDateString(),
        teacherID: teacherID,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result", result);
        setSlotInfo(result);
        let newTeachers = [];
        result.forEach((c) => {
          if (!newTeachers.includes(c.teacherName))
            newTeachers.push(c.teacherName);
        });
        newTeachers.unshift("All");
        setTeachers(newTeachers);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [teacherID, rdate]);
  const handleTeacherClick = (teacher) => {
    setTeacherName(teacher);
    teachersOBJ.forEach((c) => {
      if (c.name == teacher) {
        setTeacherID(c.id);
      }
    });
  };
  return (
    <Base>
      <div className="mainBook m-0 d-flex flex-column justify-content-center align-items-center">
        <div className="threeFlex mb-2 d-flex justify-content-between w-100">
          <div className="d-flex align-items-end">
            <div
              className="btn btn-dark m-2"
              onClick={() => {
                history.push({
                  pathname: "/dashboard/t-slot",
                  state: {
                    rdate: rdate,
                    teacherName: teacherName,
                    teacherID: teacherID,
                    teachersOBJ: teachersOBJ,
                    teachers: teachers,
                  },
                });
              }}
            >
              Back
            </div>
          </div>
          <div className="h2 dateLeft d-flex align-items-end">
            <span className="mr-2">
              <ArrowBackIcon
                className="slot_date_controller"
                onClick={() => {
                  setRdate(new Date(new Date().setDate(rdate.getDate() - 1)));
                }}
              ></ArrowBackIcon>{" "}
            </span>
            {new Date(rdate).toDateString()}{" "}
            <span className="ml-2">
              <ArrowForwardIcon
                className="slot_date_controller "
                onClick={() => {
                  setRdate(new Date(new Date().setDate(rdate.getDate() + 1)));
                }}
              ></ArrowForwardIcon>
            </span>
          </div>
          <div className="twoFlex d-flex">
            <div className="teacher">
              <TkDropDown
                variant="info"
                label="Teachers"
                style={{ width: "100px" }}
                _onClick={handleTeacherClick}
                value={teacherName}
                items={teachers}
              />
            </div>
            <div className="slot_calendar mt-2">
              <label htmlFor="">Date</label>
              <input
                type="date"
                value={rdate}
                onChange={(e) => setRdate(e.target.value)}
              ></input>
            </div>
          </div>
        </div>

        <div className="mainTable h1">
          <table class="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">Teacher Name</th>
                <th scope="col">Student Name</th>
                <th scope="col">Student EmailID</th>
                <th scope="col">Slot Time</th>
              </tr>
            </thead>
            <tbody>
              {slotInfo.map((item, index) => (
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td>{item.teacherName}</td>
                  <td>{item.studentName}</td>
                  <td>{item.studentEmail}</td>
                  <td>{item.slot.slice(16)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Base>
  );
};

import React from "react";
import "./overlay.scss";

export default function Overlay() {
	return (
		<div className="overlay">
			<div className="loader"></div>
		</div>
	);
}

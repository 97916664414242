import React, { Component, useEffect, useState } from "react";
import "../styles/ticket.css";
import { searchAlgo, findCountry } from "../Utils/Helper.js";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TkDropDown from "../Common/Components/DropDown";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Base from "../Common/Components/Base";
import Modal from "@material-ui/core/Modal";
import { base_route } from "../backend";

const StudentComponent = () => {
  const [students, setStudent] = useState([]);
  const [loading, setLoadig] = useState(false);
  const [search, setSearch] = useState("");
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [currentRecords, setcurrentRecords] = useState([]);
  const [des, setDes] = useState("");
  const [render, setRender] = useState(0);

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(!open);
  };

  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  useEffect(() => {
    setcurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage]);

  useEffect(() => {
    searchFeild && search != ""
      ? setcurrentRecords(searchAlgo(search, students))
      : setcurrentRecords(
          students.slice(indexOfFirstRecord, indexOfLastRecord)
        );
  }, [search, searchFeild]);

  useEffect(() => {
    loadStudent();
  }, [render]);
  useEffect(() => {
    setcurrentRecords(students.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [students]);

  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };

  const loadStudent = async () => {
    setLoadig(true);

    const response = await fetch(`${base_route}/ticket/get`);
    const student = await response.json();
    //console.log("Student", student);
    setStudent(student.reverse());
    setLoadig(false);
  };

  const openDisable = (status) => {
    if (status == "Close") {
      return true;
    }
    if (status == "Inprog") {
      return true;
    }
    if (status == "Open") {
      return true;
    }
  };
  const inprogDisable = (status) => {
    if (status == "Close") {
      return true;
    }
    if (status == "Inprog") {
      return true;
    }
    if (status == "Open") {
      return false;
    }
  };
  const closeDisable = (status) => {
    if (status == "Close") {
      return true;
    }
    if (status == "Inprog") {
      return false;
    }
    if (status == "Open") {
      return false;
    }
  };
  const isOpen = (status) => {
    if (status == "Open") {
      return "open_button_enable";
    }
    return "";
  };
  const isInprog = (status) => {
    if (status == "Inprog") {
      return "inprog_button_enable";
    }
    return "";
  };
  const isClose = (status) => {
    if (status == "Close") {
      return "close_button_enable";
    }
    return "";
  };
  const handleUpdate = (ID, status) => {
    console.log(ID, status);
    fetch(`${base_route}/ticket/progress/${ID}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status }),
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.error) {
          toast(resp.error);
        } else {
          toast(resp.message);
          //window.location.reload();
          setRender((prev) => prev + 1);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (ID) => {
    fetch(`${base_route}/ticket/delete/${ID}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          toast(res.error);
          return;
        } else {
          toast(res.message);
          //window.location.reload();
          setRender((prev) => prev + 1);
          return;
        }
      });
  };

  const handleDownload = (link) => {
    if (link != "") {
      window.open(`${base_route}/${link}`, "_blank");
    }
  };
  const showDes = (Desc) => {
    if (Desc.length < 6) {
      return Desc;
    } else {
      return Desc.substring(0, 5) + "..";
    }
  };
  const formatDate = (date) => {
    console.log("date", date);
    if (date != "") {
      var day = new Date(date).getDate();
      var month = new Date(date).getMonth() + 1;
      var year = new Date(date).getFullYear();
      return day + "/" + month + "/" + year.toString().slice(2);
    }
    return "";
  };

  const handleOnRowClick = (i) => {
    setclickedIndex(i);
  };
  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    var date = new Date(secondsSinceEpoch * 1000)
      .toDateString()
      .substring(4, 15);
    var day = new Date(date).getDate();
    var month = new Date(date).getMonth() + 1;
    var year = new Date(date).getFullYear();
    return day + "/" + month + "/" + year.toString().slice(2);
  };

  return (
    <Base>
      <div className="registration_actions">
        <div className="registration_action_item">
          <AddIcon style={{ color: "#36ff6b" }} />
          Add
        </div>
        <div className="registration_action_item">
          <CloseIcon style={{ color: "#e82525" }} />
          Delete
        </div>
        <div
          className="registration_action_item"
          onClick={() => setSearchFeild(!searchFeild)}
        >
          <SearchIcon style={{ color: "#25a1e8" }} />
          Search
        </div>
        <div className="registration_action_item">
          <ArrowDropDownIcon />
          More Action
        </div>
      </div>
      {searchFeild && (
        <div className="d-flex align-items-center justify-content-center mt-3">
          <TextField
            label="Search by Anything"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}

      <div className="registration_tabel_action">
        <div className="d-flex">
          <span>Records per page</span>{" "}
          <TkDropDown
            style={{ width: "80px", height: "30px", marginLeft: "10px" }}
            _onClick={onRecordPerPageClick}
            value={recordsPerPage}
            items={["25", "50", "100", "150", "200"]}
          />
        </div>
        <div className="d-flex ">
          <NavigateBeforeIcon
            onClick={() =>
              currentPage === 1 ? null : setCurrentPage(currentPage - 1)
            }
          />
          {indexOfFirstRecord + 1} - {indexOfLastRecord} / {students.length}
          <NavigateNextIcon
            onClick={() =>
              indexOfLastRecord > students.length
                ? null
                : setCurrentPage(currentPage + 1)
            }
          />
          <div className="registration_action_item" onClick={loadStudent}>
            <RefreshIcon style={{ color: "#25a1e8" }} />
            Refresh
          </div>
        </div>
        <div
          className="registration_action_item "
          onClick={() => setSearchFeild(!searchFeild)}
        >
          <SearchIcon style={{ color: "#25a1e8" }} />
          Search
        </div>
      </div>

      <table id="customers">
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Open Date</th>
            <th>Name</th>
            <th>Profile</th>
            <th>Ticket no.</th>
            <th>Category</th>
            <th>Subject</th>

            <th>Description</th>
            <th>Attachement</th>
            <th>Status</th>
            <th>Close date</th>
            <th>Action</th>
          </tr>
        </thead>

        {loading || currentRecords.length < 1 ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : (
          currentRecords.map((item, index) => (
            <tr
              key={item._id}
              className={index === clickedIndex && `selected_row`}
            >
              <td>{indexOfFirstRecord + index + 1}</td>
              <td>{getRegDate(item._id)}</td>
              <td>{item.name}</td>
              <td>{item.profile}</td>
              <td>{item.ticketNumber.slice(5)}</td>
              <td>{item.category}</td>
              <td>{item.subject}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() => setDes(item.description)}
                >
                  {showDes(item.description)}
                </button>
              </td>

              <td
                className="file_item"
                onClick={() => handleDownload(item.ticketMedia)}
              >
                {item.ticketMedia == "" ? (
                  "-"
                ) : (
                  <>
                    <AttachFileIcon className="attach_file" />
                    <h6>file</h6>
                  </>
                )}
              </td>
              <td>
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    className={`open_button ${isOpen(item.progress)}`}
                    onClick={() => handleUpdate(item._id, "Open")}
                    disabled={openDisable(item.progress)}
                  >
                    Open
                  </button>
                  <button
                    type="button"
                    className={`inprog_button ${isInprog(item.progress)}`}
                    onClick={() => handleUpdate(item._id, "Inprog")}
                    disabled={inprogDisable(item.progress)}
                  >
                    Inprog
                  </button>
                  <button
                    type="button"
                    className={`close_button ${isClose(item.progress)}`}
                    onClick={() => handleUpdate(item._id, "Close")}
                    disabled={closeDisable(item.progress)}
                  >
                    Close
                  </button>
                </div>
              </td>
              <td>{formatDate(item.closeDate)}</td>

              <td>
                <button
                  className="delete_buttton_ticket"
                  onClick={() => handleDelete(item._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))
        )}
      </table>
      <ToastContainer />
      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <p>{des}</p>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default StudentComponent;

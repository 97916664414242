import React, { useEffect, useState } from "react";
import "./ProfileEdit.css";
import csc from "country-state-city";
import Base from "../../Common/Components/Base";
import PhoneInput from "react-phone-number-input";
import DayPicker from "./DayPicker";
import BackNavigation from "../../Common/Components/BackNavigation";
import { useHistory } from "react-router-dom";
import { base_route } from "../../backend";
import {
  getProfilePic,
  updateProfile,
  updateProfilePic,
} from "./ProfileAPIcalls";
import { _arrayBufferToBase64 } from "../../Utils/Helper";
import StatusModal from "../../Common/Components/StatusModal";

const ProfileEdit = () => {
  const [countryList, setCountryList] = useState(csc.getAllCountries());
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();
  const [countryCode, setCountryCode] = useState("");
  const [date, setDate] = useState("");
  const [profilePicUri, setProfilePicUri] = useState("");
  const [statusModal, setStatusModal] = useState({
    showStatusModal: false,
    status: "",
    statusText: "",
  });
  const [userData, setUserData] = useState({
    email: "",
    country: "",
    state: "",
    city: "",
    about: "",
    name: "",
    empcode: "",
    profile: "",
    phone: "",
    dob: "",
    gender: "",
    profilePic: "",
    profilePicInfo: "",
  });
  const defaultProfilePic =
    "https://cet.ecu.edu/construction/wp-content/pv-uploads/sites/270/2019/02/man-user_150.jpg";

  const history = useHistory();

  useEffect(() => {
    async function fetchUserData() {
      const data = await fetch(
        `${base_route}/adminMain/settings/profile/getProfile`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bearer-token": JSON.parse(sessionStorage.getItem("tk_jwt")).token,
          },
        }
      )
        .then((result) => result.json())
        .catch((err) => console.log(err));
      setUserData({
        email: data.email,
        country: data.country,
        state: data.state,
        city: data.city,
        about: data.about,
        name: data.name,
        empcode: data.empcode,
        profile: data.profile,
        phone: data.phone.countryCode + "" + data.phone.number,
        dob: data.dob,
        gender: data.gender,
        profilePic: data.profilePic,
        profilePicInfo: data.profilePicInfo,
      });
      setDate(data.dob);
    }
    fetchUserData();
  }, []);

  const {
    email,
    name,
    country,
    state,
    city,
    about,
    empcode,
    profile,
    phone,
    dob,
    gender,
    profilePic,
    profilePicInfo,
  } = userData;

  useEffect(() => {
    if (profilePic !== undefined) {
      if (profilePicUri.substring(0, 4) != "blob") {
        getProfilePic(profilePic).then((binaryImg) => {
          let base64String =
            binaryImg !== undefined && _arrayBufferToBase64(binaryImg.data);
          if (base64String) {
            const binary = `data:image/gif;base64,${base64String}`;
            setProfilePicUri(binary);
          } else {
            setProfilePicUri(defaultProfilePic);
          }
        });
      }
    }
  }, [profilePic, profilePicUri]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    setUserData((prev) => ({ ...prev, [`${name}`]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onPhotoUpload = () => {
    if (profilePicInfo != undefined) {
      updateProfilePic(profilePicInfo)
        .then((data) => {
          console.log("data", data.image);
          const { image } = data;
          console.log(typeof image);
          setUserData((prev) => ({ ...prev, profilePic: image }));
          console.log("userdata", userData.profilePic);
          return data;
        })
        .then((data) =>
          setStatusModal({
            ...statusModal,
            showStatusModal: true,
            status: "success",
            statusText: data.message,
          })
        )
        .catch((err) =>
          setStatusModal({
            ...statusModal,
            showStatusModal: true,
            status: "error",
            statusText: "Error occurred during upload",
          })
        );
    }
  };

  const handleOnPhotoChange = (field) => (event) => {
    const value =
      field === "photo" ? event.target.files[0] : event.target.value;
    setProfilePicUri(URL.createObjectURL(value));
    setUserData({
      ...userData,
      profilePicInfo: value,
    });
  };

  const handleSave = async () => {
    await fetch(`${base_route}/adminMain/settings/profile/updateProfile`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "bearer-token": JSON.parse(sessionStorage.getItem("tk_jwt")).token,
      },
      body: JSON.stringify({
        email,
        name,
        country,
        state,
        city,
        about,
        empcode,
        profile,
        phone,
        dob,
        gender,
        profilePic,
        profilePicInfo,
      }),
    })
      .then((res) => {
        if (res.status == 200) {
          res.text().then((message) => alert(message));
          history.goBack();
        } else {
          res.text().then((message) => alert(message));
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Error in Database! Please contact developer");
      });
  };

  return (
    <Base>
      <BackNavigation />
      <div className="edit_profile_container">
        <div className="edit_profile">
          <img
            className="profile_pic"
            src={
              profilePicUri === undefined || profilePicUri === ""
                ? defaultProfilePic
                : profilePicUri
            }
            alt="profilepic"
          />
          {/* <form action='/' onSubmit={handleSubmit}>
            <input
              type='file'
              name='photo'
              accept='image'
              className='ticket_attach'
              onChange={handleOnPhotoChange('photo')}
            />
            <input
              type='submit'
              value='Upload'
              className='upload_pic'
              onClick={onPhotoUpload}
            />
          </form> */}
          <div className="d-flex justify-content-between profile_pic_option">
            <div className="upload_pic">
              <label>
                Select
                <input
                  type="file"
                  className="ticket_attach"
                  onChange={() => handleOnPhotoChange("photo")}
                  name="photo"
                  accept="image"
                />
              </label>
            </div>

            <button className="upload_pic" onClick={() => onPhotoUpload()}>
              <b>Upload</b>
            </button>
          </div>
          <label htmlFor="email">Email</label>
          <div>{email}</div>
          <select
            class="custom-select"
            id="inputGroupSelect02"
            value={country}
            onChange={(event) => {
              let data = event.target.value.split(" ");
              let code = data.pop(-1);
              // setUserInfo({ ...userInfo, [field]: data.toString() });
              const list = csc.getStatesOfCountry(code);
              setCountryCode(code);
              handleChange(event);
              setStateList(list);
            }}
          >
            <option selected>Select Country</option>
            {countryList?.map((country) => (
              <option
                key={country.name}
                value={`${country.name} ${country.isoCode}`}
              >
                {country.name}
              </option>
            ))}
          </select>
          <select
            class="custom-select"
            id="inputGroupSelect03"
            onChange={(event) => {
              let data = event.target.value.split(" ");
              let code = data.pop(-1);
              // setUserInfo({ ...userInfo, [field]: data.toString() })
              const list = csc.getCitiesOfState(countryCode, code);
              handleChange(event);
              setCityList(list);
            }}
          >
            <option selected>select State </option>
            {stateList?.map((state) => (
              <option key={state.name} value={`${state.name} ${state.isoCode}`}>
                {state.name}
              </option>
            ))}
          </select>
          <select
            class="custom-select"
            id="inputGroupSelect04"
            onChange={(event) => {
              handleChange(event);
            }}
          >
            <option selected>Select City</option>
            {cityList?.map((state) => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
          <label htmlFor="about">About Me</label>
          <textarea
            name="about"
            id=""
            cols="30"
            rows="8"
            placeholder="Write about yourself"
            value={about}
            onChange={(e) => handleChange(e)}
          ></textarea>
        </div>
        <div className="edit_profile">
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="empcode">Employee Code</label>
          <input
            type="text"
            value={empcode}
            name="empcode"
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="profile">Designation</label>
          <input
            type="text"
            value={profile}
            name="profile"
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="phoneNO">Contact number</label>
          <PhoneInput
            value={`+${phone}`}
            onChange={(e) => handleChange(e)}
            defaultCountry="IN"
            name="phone"
            autoComplete="on"
            maxlength="15"
            readOnly
          />
          <DayPicker
            label="DOB"
            name="dob"
            date={date}
            setDate={() => setDate()}
          />
          <label htmlFor="gender">Gender</label>
          <ul>
            <li>
              <input
                type="radio"
                name="gender"
                value="male"
                onChange={(e) => handleChange(e)}
                checked={gender === "male"}
              />
              <span className="checkmark"></span>
              Male
            </li>
            <li>
              <input
                type="radio"
                name="gender"
                value="female"
                onChange={(e) => handleChange(e)}
                checked={gender === "female"}
              />
              <span className="checkmark"></span>
              Female
            </li>
            <li>
              <input
                type="radio"
                name="gender"
                value="others"
                onChange={(e) => handleChange(e)}
                checked={gender === "others"}
              />
              <span className="checkmark"></span>
              Others
            </li>
          </ul>
          <button className="edit_profile_btn" onClick={() => history.goBack()}>
            Back
          </button>
          <button className="edit_profile_btn" onClick={() => handleSave()}>
            Save
          </button>
        </div>
      </div>
    </Base>
  );
};

export default ProfileEdit;

import React, { useState, useReducer, useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import { render } from "@testing-library/react";
import "../../styles/student-manager.css";
import Base from "../../Common/Components/Base";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function StudentManagerComponent() {
  const classes = useStyles();
  const age = 5;
  const handleChange = (e) => {
    console.log("Options Changes ", e.target.value);
  };
  return (
    <Base>
      <form className={classes.root} noValidate autoComplete="off">
        <div>
          <PersonalDetails email="kushwaha.27@gmail.com" />
          <TextField
            required
            id="standard-required"
            label="Required"
            defaultValue="Hello World"
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={age}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
            <FormHelperText>Some important helper text</FormHelperText>
          </FormControl>
        </div>
      </form>
    </Base>
  );
}

const PersonalDetails = ({ email }) => {
  const [booking, setBooking] = useState({});
  useEffect(() => {
    fetchBookingDetail();
  }, []);

  const fetchBookingDetail = async () => {
    const response = await fetch(
      `https://techokids.herokuapp.com/booking/getBooking/email/${email}`
    );
    const result = await response.json();
    setBooking(result);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
    },
  }));
  const classes = useStyles();

  return (
    <Base>
      <div className="flex-container ">
        <Paper
          style={{
            width: "95%",
            height: "35vh",
            borderTop: "10px solid purple",
          }}
        >
          <div className="row personal-detail">
            <div className="col-sm-3">
              <Avatar
                className="avatar"
                style={{ width: "150px", height: "150px" }}
              >
                Avatar
              </Avatar>
            </div>
            <div className="col-sm-8">
              <strong>Student Name:</strong> {booking.studentName}
              <strong>Student Name:</strong> {booking.studentName}
              <strong>Student Name:</strong> {booking.studentName}
              <strong>Student Name:</strong> {booking.studentName}
            </div>
          </div>
        </Paper>
      </div>
    </Base>
  );
};

import React, { Component } from "react";
import Base from "../Common/Components/Base";
const ProjectComponent = () => {
  return (
    <Base>
      <div>project works!!!</div>
    </Base>
  );
};

export default ProjectComponent;

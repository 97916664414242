import React, { Component, useEffect, useState } from "react";
import "../../styles/ticket.css";
import { searchAlgo, findCountry } from "../../Utils/Helper.js";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TkDropDown from "../../Common/Components/DropDown";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Base from "../../Common/Components/Base";
import Modal from "@material-ui/core/Modal";
import { base_route } from "../../backend";

const Leave = () => {
  const [leaves, setLeaves] = useState([]);
  const [loading, setLoadig] = useState(false);
  const [search, setSearch] = useState("");
  const [searchFeild, setSearchFeild] = useState(false);
  const [clickedIndex, setclickedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [currentRecords, setcurrentRecords] = useState([]);
  const [des, setDes] = useState("");
  const [render, setRender] = useState(0);

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(!open);
  };

  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  useEffect(() => {
    setcurrentRecords(leaves.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage]);

  useEffect(() => {
    searchFeild && search != ""
      ? setcurrentRecords(searchAlgo(search, leaves))
      : setcurrentRecords(leaves.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [search, searchFeild]);

  useEffect(() => {
    loadLeave();
  }, [render]);
  useEffect(() => {
    setcurrentRecords(leaves.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [leaves]);

  const onRecordPerPageClick = (numberOfRecord) => {
    setRecordsPerPage(numberOfRecord);
  };

  const showDes = (Desc) => {
    if (Desc.length < 6) {
      return Desc;
    } else {
      return Desc.substring(0, 5) + "..";
    }
  };
  const getRegDate = (ID) => {
    var s = ID;
    var timehex = s.substring(0, 8);
    var secondsSinceEpoch = parseInt(timehex, 16);
    var date = new Date(secondsSinceEpoch * 1000)
      .toDateString()
      .substring(4, 15);
    var day = new Date(date).getDate();
    var month = new Date(date).getMonth() + 1;
    var year = new Date(date).getFullYear();
    return day + "/" + month + "/" + year.toString().slice(2);
  };

  const formatDate = (date) => {
    //console.log("date", date);
    if (date != "") {
      var day = new Date(date).getDate();
      var month = new Date(date).getMonth() + 1;
      var year = new Date(date).getFullYear();
      return day + "/" + month + "/" + year.toString().slice(2);
    }
    return "";
  };

  const isPending = (status) => {
    if (status == "Pending") {
      return true;
    }
    return false;
  };
  const isReject = (status) => {
    if (status == "Reject") {
      return true;
    }
    return false;
  };
  const isApproved = (status) => {
    if (status == "Approved") {
      return true;
    }
    return false;
  };
  const isAdmin = (approvedBy) => {
    if (approvedBy == "Admin") {
      return true;
    }
    return false;
  };
  const isNone = (approvedBy) => {
    if (approvedBy == "None");
    {
      return true;
    }
    return false;
  };
  const loadLeave = async () => {
    setLoadig(true);
    const response = await fetch(`${base_route}/leave/all`);
    const leave = await response.json();
    console.log("Leave", leave);
    setLeaves(leave.reverse());
    setLoadig(false);
  };
  const onDelete = (ID) => {
    fetch(`${base_route}/leave/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ID }),
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.message) {
          toast(resp.message);
          setRender((prev) => prev + 1);
        } else {
          toast(resp.error);
        }
      })
      .catch((err) => console.log(err));
  };

  const onUpdate = (ID, action, value) => {
    console.log(ID, action, value);
    fetch(`${base_route}/leave/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ID, action, value }),
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.message) {
          toast(resp.message);
          setRender((prev) => prev + 1);
        } else {
          toast(resp.error);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Base>
      <div className="registration_actions">
        <div className="registration_action_item">
          <AddIcon style={{ color: "#36ff6b" }} />
          Add
        </div>
        <div className="registration_action_item">
          <CloseIcon style={{ color: "#e82525" }} />
          Delete
        </div>
        <div
          className="registration_action_item"
          onClick={() => setSearchFeild(!searchFeild)}
        >
          <SearchIcon style={{ color: "#25a1e8" }} />
          Search
        </div>
        <div className="registration_action_item">
          <ArrowDropDownIcon />
          More Action
        </div>
      </div>
      {searchFeild && (
        <div className="d-flex align-items-center justify-content-center mt-3">
          <TextField
            label="Search by Anything"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}

      <div className="registration_tabel_action">
        <div className="d-flex">
          <span>Records per page</span>{" "}
          <TkDropDown
            style={{ width: "80px", height: "30px", marginLeft: "10px" }}
            _onClick={onRecordPerPageClick}
            value={recordsPerPage}
            items={["25", "50", "100", "150", "200"]}
          />
        </div>
        <div className="d-flex ">
          <NavigateBeforeIcon
            onClick={() =>
              currentPage === 1 ? null : setCurrentPage(currentPage - 1)
            }
          />
          {indexOfFirstRecord + 1} - {indexOfLastRecord} / {leaves.length}
          <NavigateNextIcon
            onClick={() =>
              indexOfLastRecord > leaves.length
                ? null
                : setCurrentPage(currentPage + 1)
            }
          />
          <div className="registration_action_item" onClick={loadLeave}>
            <RefreshIcon style={{ color: "#25a1e8" }} />
            Refresh
          </div>
        </div>
        <div
          className="registration_action_item "
          onClick={() => setSearchFeild(!searchFeild)}
        >
          <SearchIcon style={{ color: "#25a1e8" }} />
          Search
        </div>
      </div>

      <table id="customers">
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Open Date</th>
            <th>Name</th>
            <th>Category</th>
            <th>Leave From</th>
            <th>Leave To</th>
            <th>Description</th>
            <th>Status</th>
            <th>Approved By</th>
            <th>By whom</th>
            <th>Action</th>
          </tr>
        </thead>

        {loading || currentRecords.length < 1 ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : (
          currentRecords.map((item, index) => (
            <tr
              key={item._id}
              className={index === clickedIndex && `selected_row`}
            >
              <td>{indexOfFirstRecord + index + 1}</td>
              <td>{getRegDate(item._id)}</td>
              <td>{item.name}</td>
              <td>{item.leaveType}</td>
              <td>{formatDate(item.leaveFrom)}</td>
              <td>{formatDate(item.leaveTo)}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() => setDes(item.description)}
                >
                  {showDes(item.description)}
                </button>
              </td>
              <td>
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={isPending(item.leaveStatus)}
                    onClick={() => onUpdate(item._id, "status", "Pending")}
                  >
                    Pending
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    disabled={isReject(item.leaveStatus)}
                    onClick={() => onUpdate(item._id, "status", "Reject")}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    disabled={isApproved(item.leaveStatus)}
                    onClick={() => onUpdate(item._id, "status", "Approved")}
                  >
                    Approved
                  </button>
                </div>
              </td>
              <td>{item.approvedBy}</td>
              <td>
                <div class="form-group">
                  <select
                    class="form-control"
                    id="sel1"
                    onChange={(e) =>
                      onUpdate(item._id, "approvedBy", e.target.value)
                    }
                  >
                    <option value="Admin" selected={isAdmin(item.approvedBy)}>
                      Admin
                    </option>
                    <option value="none" selected={isNone(item.approvedBy)}>
                      None
                    </option>
                  </select>
                </div>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => onDelete(item._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))
        )}
      </table>
      <ToastContainer />
      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <p>{des}</p>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Leave;
